import React, { Component } from 'react';
import styled from 'styled-components';
import { getWomens } from '../../../../infra/api';
import mediaquery from '../../../../assets/styles/variable';
import WomanHeader from '../organisms/womanHeader';
import SearchForm from '../../presentational/molecules/searchFormWoman'
import SearchGroupField from '../../presentational/molecules/searchGroupField'
import SearchTimeForm from '../../presentational/molecules/searchTimeForm'
import BasicPagination from '../../presentational/molecules/pagination'
import WomenOrganisms from '../organisms/womenOrganisms'
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   font-size: 30px;
//   font-weight: bold;
//   font-family: YuMincho;
//   margin: auto;

//   ${mediaquery.desktop`
//     font-size: 38px;
//     width: 40%;
//     text-align: center;
//     margin: auto 0;
//   `}
// `
const GroupTitleSearch = styled.div`
  ${mediaquery.desktop`
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
  `}
`

class Woman extends Component {
  constructor(props) {
    super(props);
    this.state = { womanList: [], loading: true , paramSearch: '', showGroupSearch: false, showSearchTimeForm: false, total_pages: 1, current_page: 1 };
    this.setShowGroupSearch = this.setShowGroupSearch.bind(this)
    this.groupFieldSearch = this.groupFieldSearch.bind(this)
    this.setShowSearchTimeForm = this.setShowSearchTimeForm.bind(this)
    this.setCurrentPage = this.setCurrentPage.bind(this)
    this.paginateFC = this.paginateFC.bind(this)
  }

  componentWillMount() {
    const _this = this
    getWomens().then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })  
      _this.setState({ womanList: response.data.womans, loading: false })        
    }).catch(function (response){
      console.log(response.data)
    })
  }

  groupFieldSearch(params){
    const _this = this
    getWomens(params).then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })
      _this.setState({ womanList: response.data.womans, showGroupSearch: false })
      _this.setState({current_page: 1})
    })
  }

  paginateFC(params){
    const _this = this
    getWomens(params).then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })
      _this.setState({ womanList: response.data.womans })
    })
  }

  async setShowGroupSearch(){
    await this.setState({showGroupSearch: !this.state.showGroupSearch})
    if(this.state.showGroupSearch){
      this.setState({showSearchTimeForm: false})
      this.setState({current_page: 1})
    }
  }

  async setShowSearchTimeForm(){
    await this.setState({showSearchTimeForm: !this.state.showSearchTimeForm})
    if(this.state.showSearchTimeForm){
      this.setState({showGroupSearch: false})
    }
  }

  setCurrentPage(current_page){
    this.setState({current_page: current_page})
  }

  render() {
    if(this.state.loading || currentUser.sex === 'woman'){
      return(<div>Loading... </div>)
    }else{
      return (
        <div>
          <WomanHeader />
          <TopBackground>
            <GroupTitleSearch>
              {/* <Title>女の子一覧</Title> */}
              <SearchForm setShowGroupSearch={this.setShowGroupSearch} setShowSearchTimeForm={this.setShowSearchTimeForm} groupFieldSearch={this.groupFieldSearch} />
            </GroupTitleSearch>
            <SearchGroupField groupFieldSearch={this.groupFieldSearch} showGroupSearch={this.state.showGroupSearch}/>
            <SearchTimeForm showSearchTimeForm={this.state.showSearchTimeForm} groupFieldSearch={this.groupFieldSearch}/>
            <WomenOrganisms userList = {this.state.womanList}/>
            <BasicPagination currentPage={this.state.current_page} setCurrentPage={this.setCurrentPage} paginateFC={this.paginateFC} total_pages={this.state.total_pages}/>
          </TopBackground>
        </div>
      )
    }
  }
}

export default Woman;