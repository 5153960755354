import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import {myFirestore} from '../../../../infra/my_firebase';
import { currentUser } from '../../../../infra/current_user';
import { Link } from 'react-router-dom';

const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const MessageList = styled.dl`
  margin-top: 20px;
  > a{
    > dd {
      max-width: 375px;
      height: 65px;
      margin-bottom: 2px;
      background-color: #fff;
      display: flex;
      > img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 8px 17px;
        object-fit: cover;
      }
      > svg {
        display: block;
        margin-left: auto;
        margin-right: 23px;
        margin-top: 25px;
      }
    }
  }
`

const ContentListInbox = styled.div`
  width: 65%;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #252525;
    margin-top: 10px;
  }
  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    color: #252525;
    margin-top: 6px;
  }
`

const sortList = (arr) => {
  arr.sort(function(a, b){return b.last_message.created_at-a.last_message.created_at})
  return arr
}

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_chat: [],
      isLoading: true,
    }
    this.listRender = this.listRender.bind(this)
  }

  componentWillMount() {
    myFirestore
      .collection("users")
      .doc(currentUser.firebase_id)
      .collection('list_chat')
      .onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              this.setState({list_chat: [...this.state.list_chat, change.doc.data()]})
            } else if (change.type === "modified") {
              const arrTemp = this.state.list_chat
              const index = arrTemp.findIndex(x => x.chat_id === change.doc.data().chat_id)
              arrTemp[index] = change.doc.data()
              this.setState({list_chat: arrTemp})
            }
          })
          this.setState({isLoading: false})
        },
        err => {
          alert(err)
        }
      );
  }

  listRender = () => (
    <MessageList>
      {sortList(this.state.list_chat).map(user => {
        const lastMessage = user.last_message.type === 0 ? user.last_message.content : "image"
        return(
          <React.Fragment>
            <Link  to={{
            pathname: "/messageIndivisual",
            state: {
              user: user
            }}}>
              <dt></dt>
              <dd style={ user.read === 0 ? {background: "papayawhip"} : {background: "#fff"} }>
                <img src={user.main_image} alt={user.nickname} />
                <ContentListInbox>
                  <div>{user.nickname}</div>
                  <p>{ lastMessage}</p>
                </ContentListInbox>
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L1 13" stroke="#D7D7D7" stroke-width="2"/>
                </svg>
              </dd>
            </Link>
          </React.Fragment>
        )
      })}
    </MessageList>
  )
  render() {
    if(this.state.isLoading){
      return (<div>loading...</div>)
    } else {
      return (
        <div>
          <WomanHeader />
          <TopBackground>
            {/* <Title>メッセージ一覧</Title> */}
            {this.listRender()}
          </TopBackground>
        </div>
      )
    }
  }
}

export default Message;