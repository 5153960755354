import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import BottomAppBarMan from '../organisms/bottomAppBarMan';
import WomanSlider from '../../presentational/molecules/womanSlider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import mediaquery from '../../../../assets/styles/variable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { withStyles } from '@material-ui/core/styles';
import { postFavorites, deleteFavorite, getMen } from '../../../../infra/api';
import { currentUser } from '../../../../infra/current_user';

const WomanDetailsBody = styled.div`
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  `}
`
const StyledFormControlLabel= withStyles({
  label: {
    fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.04em',
    color: '#FF444F'
  },
})(FormControlLabel);

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  overflow-x: hidden;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 20px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const FavoriteBT = styled.div`
  margin-left: auto;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  width: 58%;
  justify-content: flex-end;
  color: red;

  & > p {
    margin-left: 3px;
  }
`
const HeadBackground = styled.div`
  display: flex;
  margin-bottom: 20px;
`

class ManDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, loading: true , isFavorite: false};
    this.handleFavorites = this.handleFavorites.bind(this)
    this.favoriteDisplay = this.favoriteDisplay.bind(this)
  }

  componentWillMount() {
    const _this = this
    getMen(this.props.location.state.id).then(async function (response) {
      await _this.setState({ user: response.data, loading: false, isFavorite: response.data.isFavorite })        
    }).catch(function (response){
      alert(response.message)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/woman');
  };

  handleFavorites = (e) => {
    if(e.target.checked === true){
      postFavorites({reciever_id: this.state.user.id}).then(() => {
        this.setState({isFavorite: true})
      })
    } else {
      deleteFavorite({reciever_id: this.state.user.id}).then(() => {
        this.setState({isFavorite: false})
      })
    }
  };

  favoriteDisplay = () =>{
    let body = (<FavoriteBT>
      <StyledFormControlLabel
        control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />}
        label="お気に入り"
        onClick={this.handleFavorites}
        checked={this.state.isFavorite ? true : false}
      />
    </FavoriteBT>)
    if(currentUser.sex === "store"){
      body = ""
    }

    return body
  }

  render() {
    if(this.state.loading){
      return(<div>Loading... </div>)
    }else{
      return (
        <div>
          <WomanHeader />
          <WomanDetailsBody>
            <TopBackground>
              <HeadBackground>
                <BackButtonCover>
                  <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
                </BackButtonCover>
                {/* <Title>女の子詳細</Title> */}
                {this.favoriteDisplay()}
              </HeadBackground>
              <WomanSlider images={this.state.user.sorted_images}/>
            </TopBackground>
            <BottomAppBarMan user={this.state.user}/>
          </WomanDetailsBody>
        </div>
      )
    }
  }
}

export default ManDetails;