import React, { Component } from 'react';
import styled from 'styled-components';
import { getStores, getWomens, getMens } from '../../../../infra/api';
import mediaquery from '../../../../assets/styles/variable';
import WomanHeader from '../organisms/womanHeader';
import SearchForm from '../../presentational/molecules/searchFormMan'
import SearchGroupField from '../../presentational/molecules/searchGroupField'
import BasicPagination from '../../presentational/molecules/pagination'
import StoreOrganisms from '../organisms/storeOrganisms'
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 100%;
  // 下記marginは、reset.cssでリセットする。
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  margin: auto;

  ${mediaquery.desktop`
    font-size: 38px;
    width: 40%;
    text-align: center;
    margin: auto 0;
  `}
`
const GroupTitleSearch = styled.div`
  ${mediaquery.desktop`
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
  `}
`
const BtSwichGroup = styled.div`
	display: flex;
	width: 100%;
	background: #FFFFFF;
	border: 1px solid #FF444F;
	border-radius: 4px;
	height: 45px;
	margin: auto;
	margin-top: 30px;
  margin-bottom: 10px;
	> button {
		font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 36px;
	}
	${mediaquery.desktop`
		width: 35%;
  `}
`
const BTStore = styled.button`
	border-radius: 4px 0px 0px 4px;
	height: 45px;
	width: 50%;
	border: none;
	&:focus {
		border: none;
	}
`

const BTWoman = styled.button`
	border-radius: 0px 4px 4px 0px;
	height: 45px;
	width: 50%;
	border: none;
	&:focus {
		border: none;
	}
`

const TitleChange = (props) => {
	let title = ''

	if(props.type  === 'store'){
		title = (<Title>お店一覧</Title>)
	} else {
		if(currentUser.sex === 'man'){
			title = (<Title>全女の子一覧</Title>)
		} else {
			title = (<Title>男性一覧</Title>)
		}
	}

	return title
}

class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = { dataList: [], loading: true , paramSearch: '', showGroupSearch: false, total_pages: 1, current_page: 1, default_list: 'store' };
    this.setShowGroupSearch = this.setShowGroupSearch.bind(this)
    this.groupFieldSearch = this.groupFieldSearch.bind(this)
    this.setCurrentPage = this.setCurrentPage.bind(this)
		this.paginateFC = this.paginateFC.bind(this)
		this.handleChangeList = this.handleChangeList.bind(this)
	}
	
	customAPI(params) {
		if(this.state.default_list === 'store'){
			return getStores(params)
		} else {
			if(currentUser.sex === 'man'){
				return getWomens(params)
			} else {
				return getMens(params)
			}
		}
	}

  componentWillMount() {
    const _this = this
    this.customAPI().then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })  
      _this.setState({ dataList: response.data.stores || response.data.womans || response.data.mans, loading: false })        
    }).catch(function (response){
      console.log(response.data)
    })
  }

  groupFieldSearch(params){
    const _this = this
    this.customAPI(params).then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })
      _this.setState({ dataList: response.data.stores || response.data.womans || response.data.mans, showGroupSearch: false })
      _this.setState({current_page: 1})
    })
  }

  paginateFC(params){
    const _this = this
    this.customAPI(params).then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })
      _this.setState({ dataList: response.data.stores || response.data.womans || response.data.mans})
    })
  }

  async setShowGroupSearch(){
    await this.setState({showGroupSearch: !this.state.showGroupSearch})
    if(this.state.showGroupSearch){
      this.setState({current_page: 1})
    }
	}

  setCurrentPage(current_page){
    this.setState({current_page: current_page})
	}
	
	async handleChangeList(data){
		await this.setState({default_list: data})
		const _this = this
    this.customAPI().then(async function (response) {
      await _this.setState({ total_pages: response.data.total_pages })  
      _this.setState({ dataList: response.data.stores || response.data.womans || response.data.mans, loading: false })        
    }).catch(function (response){
      console.log(response.data)
    })

	}

  render() {
		if(this.state.loading){
      return(<div>Loading... </div>)
    }else{ return (
				<div>
					<WomanHeader />
					<TopBackground>
						<GroupTitleSearch>
							<TitleChange type={this.state.default_list}/>
							<BtSwichGroup>
								<BTStore style={this.state.default_list === 'store' ? {color: '#fff', background: '#FF444F'} : {color: '#FF444F', background: '#fff'} } onClick={() => this.handleChangeList('store')}>お店</BTStore>
	<BTWoman style={this.state.default_list === 'woman' ? {color: '#fff', background: '#FF444F'} : {color: '#FF444F', background: '#fff'} } onClick={() => this.handleChangeList('woman')}>{currentUser.sex === 'man' ? '全女の子' : '男性'}</BTWoman>
							</BtSwichGroup>
							<SearchForm setShowGroupSearch={this.setShowGroupSearch} groupFieldSearch={this.groupFieldSearch} />
						</GroupTitleSearch>
						<SearchGroupField groupFieldSearch={this.groupFieldSearch} showGroupSearch={this.state.showGroupSearch}/>
						<StoreOrganisms userList = {this.state.dataList} type={this.state.default_list}/>
						<BasicPagination currentPage={this.state.current_page} setCurrentPage={this.setCurrentPage} paginateFC={this.paginateFC} total_pages={this.state.total_pages}/>
					</TopBackground>
				</div>
			)
		}	
  }
}

export default Stores;