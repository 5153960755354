import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { deleteDeleteCard } from '../../../../infra/api';

const CreditCard = styled.dl`
  width: 345px;
  height: 97px;
  margin: 0 auto;
  padding: 15px;
  background: #fff;
  border: 2px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 5px;
`

const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
`

const PopUp = styled.div`
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 236px;
  padding: 20px 22px;
  background:#fff;
  border-radius: 8px;
  box-sizing:border-box;
`

const PopUpButtonCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const PopUpButtonYes = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  background-color: #ff444f;
  border: none;
  border-radius: 4px;
`

const PopUpButtonNo = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  background-color: #bbb;
  border: none;
  border-radius: 4px;
`
const CreditCardAdd = styled.div`
  width: 345px;
  height: 51px;
  margin: 0 auto;
  padding: 15px;
  background: #FFFFFF;
  border: 2px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #252525;
  margin-top: 20px;

  & > span{
    float: right;
    color: #bbbbbb;
    font-size: 21px;
    line-height: 15px;
  }
`
const CardTitle = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #252525;
`
const CardInfo = styled.dd`
  float: left;
  line-height: 19px;
  margin-top: 7px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #252525;
`
const CardDelete = styled.p`
  float: right;
  height: 32px;
  width: 60px;
  background: #BBBBBB;
  border-radius: 4px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  letter-spacing: 0.04em;
`

class SettingCreditCardGroup extends Component {
  // Local State
  constructor(props) {
    super(props);
    this.state = { isOpen: false, default_card_delete: null, list_card: props.list_card };
    this.toggleContainer = React.createRef();

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
    this.handleCreditCardDataDelete = this.handleCreditCardDataDelete.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  // 「handleCreditCardClick」に変更予定。
  onClickHandler(card_id) {
    this.setState(currentState => ({
      default_card_delete: card_id,
      isOpen: !currentState.isOpen
    }));
  }

  onClickOutsideHandler(event) {
    if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  handleCloseDeleteCard = () => {
    this.setState({ isOpen: false });
  }

  handleCreditCardDataDelete = () => {
    deleteDeleteCard({card_id: this.state.default_card_delete}).then( async(response) => {
      this.setState({
        list_card: await this.state.list_card.filter(card => card.id !== response.data.card_id)
      });
      this.setState({ isOpen: false });
    }).catch((response) =>{
      console.log(response.data)
    })
  }

  handleNextCreditCardAdd = () => {
    // 以下、「/woman/[props.woman.name]というように値を渡さなければいけない。」
    this.props.history.push('/settingCreditCardAdd');
  }
  render() {
    return (
      <div ref={this.toggleContainer}>
        {this.state.list_card.map((card) => {
            return(
              <CreditCard>
                <CardTitle>{card.brand.toUpperCase()}****{card.last4}</CardTitle>
                <CardInfo>KITASINTI YOSHIO<br/>
                有効期限は{card.exp_month}/{card.exp_year}です</CardInfo>
                <CardDelete onClick={ () => this.onClickHandler(card.id)}>削除</CardDelete>
              </CreditCard>
            )
          })
        }
        {this.state.isOpen && (
          <PopUpBackground>
            <PopUp>
              <div>こちらのクレジットカード情報を削除します。よろしいですか？</div>
              <PopUpButtonCover>
                <PopUpButtonYes onClick={this.handleCreditCardDataDelete}>はい</PopUpButtonYes>
                <PopUpButtonNo onClick={this.handleCloseDeleteCard}>いいえ</PopUpButtonNo>
              </PopUpButtonCover>
            </PopUp>
          </PopUpBackground>
        )}
        <CreditCardAdd onClick={this.handleNextCreditCardAdd}>お支払い方法を追加<span>></span></CreditCardAdd>
      </div>
    )
  }
};

export default withRouter(SettingCreditCardGroup);