import React from 'react';
import styled from 'styled-components';
import getDayReservation from '.././atoms/getDayReservation';

const TableColumnLeft = styled.td`
  position: absolute;
  width: 47px;
  z-index: 10;
  background: #fff;
`
const reservationTime = [
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '23:59',
];

const ReservationInput = styled.input`
  &[type=checkbox] {
    display: none;
  }

  + span {
    position: relative;
  }

  + span::before {
    top: 50%;
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #FF444F;
    border-radius: 4px;
    box-sizing: border-box;
  }

  &:checked + span::before {
    background: #FF444F;
  }

  &:checked + span::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 4px;
    width: 6px;
    height: 9px;
    transform: rotate(40deg);
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
  }
`
function getMinuteEndSection(date){
  const time = new Date(date)
  time.setMinutes(time.getMinutes() - 1)

  return time
}

function checkAttendanceDisplay (time_attendances, date){
  const check = []
  const size = time_attendances.length
  for(let i = 0; i < size; i++){
    if(time_attendances[i].day !== date.toDateString()) continue;
    if (new Date(time_attendances[i].start_section) <= date && date <= new Date(time_attendances[i].end_section)){
      check.push(true)
    }
  }
  return (check.length === 0) ? false : ((check.includes(false)) ? false : true)
}


function checkDisable (time_attendances, date){
  const check = []
  const size = time_attendances.length
  for(let i = 0; i < size; i++){
    if(time_attendances[i].day !== date.toDateString()) continue;
    if (new Date(time_attendances[i].start_section) <= date && date <= new Date(time_attendances[i].end_section)){
      time_attendances[i].reservations.forEach(reservation => {
        if(new Date(reservation.start_section) <= date && new Date(reservation.end_section) >= getMinuteEndSection(date)){
          check.push(true)
        }
      });
    }
  }
  return check.includes(true) ? true : false
}

function checkDisableLast (time_attendances, date){
  const check = []
  const size = time_attendances.length
  for(let i = 0; i < size; i++){
    if(time_attendances[i].day !== date.toDateString()) continue;
    if (new Date(time_attendances[i].start_section) <= date && date <= new Date(time_attendances[i].end_section)){
      time_attendances[i].reservations.forEach(reservation => {
        if(new Date(reservation.start_section) <= date && new Date(reservation.end_section).toString() === getMinuteEndSection(date).toString()){
          check.push(true)
        }
      });
    }
  }
  return check.includes(true) ? true : false
}

function checkDisableReservationTime(sections, date) {
  let disable = false
  const size = sections.length
  for(let i = 0; i < size; i++){
    if(sections[i].day !== date.toDateString()) continue;
    if (sections[i].start_section < date && date < sections[i].end_section){
      disable = true
    }
  }

  return disable
}

function checkDisableReservationTimeLast(sections, date) {
  let disable = false
  const size = sections.length
  for(let i = 0; i < size; i++){
    if(sections[i].day !== date.toDateString()) continue;
    if (`${date}` === `${sections[i].end_section}`){
      disable = true
    }
  }

  return disable
}

function ReservationTimeListForStore (props){

  const checkChecked = (date) => {
    let checked = false
    if(props.sections.length === 0) return checked;
    if(`${props.sections[0].start_section}` === `${date}`) checked = true;
    return checked
  }

  return(
    reservationTime.map((time, index) => {
      return(
        <React.Fragment key={index}>
          <tr>
            <TableColumnLeft key={index}>{time}</TableColumnLeft>
            {getDayReservation().map((day, index) => {
              const date = new Date(`${day.toDateString()} ${time}`)
              const disable = checkDisableReservationTime(props.sections, date)
              const removecheckbox = checkDisableReservationTimeLast(props.sections, date)
              const checkDisplay = checkAttendanceDisplay(props.time_attendances, date)
              const _checkDisable = checkDisable(props.time_attendances, date)
              if(checkDisplay === true && !removecheckbox && !checkDisableLast(props.time_attendances, date)){
                if(disable || _checkDisable){
                  return(
                    <React.Fragment key={index}>
                      <td style={{opacity: '0.4'}}>
                        <label>
                          <ReservationInput checked disabled='disabled' value={date} type="checkbox"></ReservationInput>
                          <span></span>
                        </label>
                      </td>
                    </React.Fragment>
                  )
                } else {
                  return(
                    <React.Fragment key={index}>
                      <td>
                        <label>
                          <ReservationInput checked={checkChecked(date)} value={date} type="checkbox"></ReservationInput>
                          <span></span>
                        </label>
                      </td>
                    </React.Fragment>
                  )
                }
              } else {
                return(
                  <React.Fragment key={index}>
                    <td>
                      <label>
                      </label>
                    </td>
                  </React.Fragment>
                )
              }
            })}
          </tr>
        </React.Fragment>
      )
    })
  )
}

export default ReservationTimeListForStore;