import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import './assets/styles/reset.css';
import firebase from './infra/my_firebase';
import styled from 'styled-components';
import mediaquery from './assets/styles/variable';
import * as jwt_decode from 'jwt-decode';
import './App.css';
import { currentUser } from './infra/current_user';

// 認証前・サインイン・サインアップ
import Top from './towardsMan/components/containers/pages/top';
import Login from './towardsMan/components/containers/pages/login';
import mailRegistration from './towardsMan/components/containers/pages/mailRegistration';
import mailRegistrationDone from './towardsMan/components/containers/pages/mailRegistrationDone';
import Registration from './towardsMan/components/containers/pages/registration';
import NextRegistration from './towardsMan/components/containers/pages/nextRegistration';
// 女性予約・決済
import Woman from './towardsMan/components/containers/pages/woman';
import WomanDetails from './towardsMan/components/containers/pages/womanDetails';
import WomanReservationHistory from './towardsMan/components/containers/pages/womanReservationHistory';
import ReservationDrink from './towardsMan/components/containers/pages/reservationDrink';
import ReservationDrinkForStore from './towardsMan/components/containers/pages/reservationDrinkForStore';

import ReservationEat from './towardsMan/components/containers/pages/reservationEat';
import ReservationContents from './towardsMan/components/containers/pages/reservationContents';
import ReservationDone from './towardsMan/components/containers/pages/reservationDone';
import AttendanceSchedule from './towardsMan/components/containers/pages/attendanceSchedule';
//予約/ナムの支払い
import Man from './towardsMan/components/containers/pages/man';
import ManReservationHistory from './towardsMan/components/containers/pages/manReservationHistory';
import ManDetails from './towardsMan/components/containers/pages/manDetails';

import Stores from './towardsMan/components/containers/pages/stores';
import Users from './towardsMan/components/containers/pages/users';
import MyStore from './towardsMan/components/containers/pages/mystore';
import StoreDetails from './towardsMan/components/containers/pages/storeDetails';

// メッセージ
import Message from './towardsMan/components/containers/pages/message';
import MessageIndivisual from './towardsMan/components/containers/pages/messageIndivisual';
// 予約履歴
import ReservationHistory from './towardsMan/components/containers/pages/reservationHistory';
import Likes from './towardsMan/components/containers/pages/likes';
import Mypage from './towardsMan/components/containers/pages/mypage';
import SettingComment from './towardsMan/components/containers/pages/settingComment';
// 通知
import Notification from './towardsMan/components/containers/pages/notification';
import NotificationIndivisual from './towardsMan/components/containers/pages/notificationIndivisual';
// 設定
import Setting from './towardsMan/components/containers/pages/setting';
import SettingAccount from './towardsMan/components/containers/pages/settingAccount';
import SettingCreditCard from './towardsMan/components/containers/pages/settingCreditCard';
import SettingCreditCardAdd from './towardsMan/components/containers/pages/settingCreditCardAdd';
import SettingBankInfo from './towardsMan/components/containers/pages/settingBankInfo';
// ポイント購入
import PointBuy from './towardsMan/components/containers/pages/pointBuy';
import PointPay from './towardsMan/components/containers/pages/pointPay';
import PointBuyContents from './towardsMan/components/containers/pages/pointBuyContents';
import PointBuyDone from './towardsMan/components/containers/pages/pointBuyDone';
import CreditCardPayment from './towardsMan/components/containers/pages/creditCardPayment';

import StoreRegistration from './towardsMan/components/containers/pages/storeRegistration';

const Container = styled.div`
  margin: 0 auto;
  max-width: 450px;

  ${mediaquery.desktop`
      max-width: 1280px;
  `}
`

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false, currentUser: null, loading: true };
  }

  async componentWillMount() {
    if( localStorage.getItem('token') === '' || localStorage.getItem('token') === null || Date.now() >= jwt_decode(localStorage.getItem('token')).exp * 1000){
      await firebase.auth().signOut()
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  render() {
    const logged_in = this.state.authenticated  && currentUser !== ''
    if(this.state.loading){
      return(<div>Loading... </div>)
    } else {
      return (
        <Router>
          <Container>
            <Switch>
              {/* top・サインイン・サインアップ */}
              <Route exact path="/" component={Top} />
              <Route exact path="/top" component={Top} />
              <Route exact path="/login" component={ logged_in ? Top : Login } />
              <Route exact path="/mailRegistration" component={mailRegistration} />
              <Route exact path="/mailRegistrationDone" component={mailRegistrationDone} />
              <Route exact path="/registration" component={ logged_in ? Top : Registration } />
              <Route exact path="/nextRegistration" component={NextRegistration} />
              {/* 女性予約・決済 */}
              <Route exact path="/woman" component={ logged_in ? Woman : Login } />
              <Route exact path="/womanDetails" component={logged_in ? WomanDetails : Login} />
              <Route exact path="/womanReservationHistory" component={logged_in ? WomanReservationHistory : Login} />
              <Route exact path="/womanDetails/reservationDrink" component={ logged_in ? ReservationDrink : Login } />
              <Route exact path="/womanDetails/reservationDrinkForStore" component={ logged_in ? ReservationDrinkForStore : Login } />
              
              <Route exact path="/reservationEat" component={ReservationEat} />
              {/* <Route exact path="/pay" component={Pay} /> */}
              <Route exact path="/reservationContents" component={ReservationContents} />
              <Route exact path="/reservationDone" component={ReservationDone} />
              <Route exact path="/attendanceSchedule" component={ logged_in ? AttendanceSchedule : Login } />
              {/* //予約/ナムの支払い*/}
              <Route exact path="/man" component={ logged_in ? Man : Login } />
              <Route exact path="/manDetails" component={ logged_in ? ManDetails : Login } />
              <Route exact path="/manReservationHistory" component={logged_in ? ManReservationHistory : Login} />
              {/* ヘッダー */}
              <Route exact path="/message" component={logged_in ? Message : Login} />
              <Route exact path="/messageIndivisual" component={logged_in ? MessageIndivisual : Login} />
              <Route exact path="/reservationHistory" component={ logged_in ? ReservationHistory : Login} />
              <Route exact path="/likes" component={ logged_in ? Likes : Login } />
              <Route exact path="/mypage" component={ logged_in ? Mypage : Login } />
              <Route exact path="/mypage/settingComment" component={ logged_in ? SettingComment : Login } />
              {/* マイページ */}
              <Route exact path="/notification" component={Notification} />
              <Route exact path="/notificationIndivisual" component={NotificationIndivisual} />
              <Route exact path="/setting" component={Setting} />
              {/* 設定 */}
              <Route exact path="/settingAccount" component={ logged_in ? SettingAccount : Login } />
              <Route exact path="/settingCreditCard" component={ logged_in ? SettingCreditCard : Login } />
              <Route exact path="/settingCreditCardAdd" component={ logged_in ? SettingCreditCardAdd : Login } />
              <Route exact path="/settingBankInfo" component={SettingBankInfo} />
          
              {/* ポイント購入 */}
              <Route exact path="/pointBuy" component={ logged_in ? PointBuy : Login } />
              <Route exact path="/pointPay/:point" component={ logged_in ? PointPay : Login } />
              <Route exact path="/pointBuyContents" component={ logged_in ? PointBuyContents : Login } />
              <Route exact path="/pointBuyDone" component={ logged_in ? PointBuyDone : Login } />
              <Route exact path="/creditCardPayment" component={ logged_in ? CreditCardPayment : Login} />

              <Route exact path="/storeRegistration/:token" component={ logged_in ? Top : StoreRegistration } />
              <Route exact path="/stores" component={ logged_in ? Stores : Login } />
              <Route exact path="/users" component={ logged_in ? Users : Login } />
              <Route exact path="/myStore" component={ logged_in ? MyStore : Login } />
              <Route exact path="/storeDetails" component={ logged_in ? StoreDetails : Login } />
            </Switch>
          </Container>
        </Router>
      )
    }
  }
};

export default App;
