import React, {useEffect} from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { currentUser } from '../../../../infra/current_user';
import { getCurrentUser, putUpdateBankInfo } from '../../../../infra/api.js';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'
import Checkbox from '@material-ui/core/Checkbox';

const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
	background-color: #ff444f;
	margin: auto;
`
const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
	background-color: #f8f7f3;
	
	> textarea {
		width: 100%;
    min-height: 160px;
    margin: 60px 0px;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #252525;
	}
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
// 	font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 20px;
// 	line-height: 30px;
// 	text-align: center;
// 	color: #252525;

// `

const RegisrationFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`
const GroupCheckBoxBody = styled.div`
  display: flex;
  > p {
    line-height: 42px;
  }
`
const GroupCheckBox = styled.div`
  display: flex;
  justify-content: space-around;
  display: flex;
  margin-top: 25px;
`

const FormGroup = styled.div`
	max-width: 400px;
	display: block;
	margin: 0 auto;
	margin-top: 50px;
	margin-bottom: 60px;
	> td {
		display: block;
    margin: auto;
    text-align: center;
	}
`

export default function SettingBankInfo(props) {
	const [card_company, setCard_company] = React.useState('')
	const [card_name, setCard_name] = React.useState('')
	const [card_number, setCard_number] = React.useState('')
	const [card_type, setCard_type] = React.useState('')
	const [showalert, setShowalert] = React.useState(false)
	const [loading, setLoading] = React.useState(true) 

	useEffect(() => {
		if(currentUser.sex === "woman"){
			getCurrentUser().then(async function (response) {
				const woman = response.data.woman || response.data
				await setCard_company(woman.card_company)
				await setCard_name(woman.card_name)   
				await setCard_number(woman.card_number)   
				await setCard_type(woman.card_type)   
				await setLoading(false)   
			}).catch(function (response){
				alert(response.message)
			})
		}
	}, []);

	const handleShowAlert = (message) =>{
		setShowalert(true)
		window.setTimeout(()=>{
		setShowalert(false)
		},4000)
	}

	const handleBackButtonClick = () => {
		props.history.goBack('/setting');
	};

	const handleUpdate = () =>{
		const user = {card_company: card_company, card_name: card_name, card_number: card_number, card_type: card_type}
		putUpdateBankInfo({user: user}).then(async (response) =>{
			const woman = response.data.woman || response.data
				await setCard_company(woman.card_company)
				await setCard_name(woman.card_name)   
				await setCard_number(woman.card_number)   
				await setCard_type(woman.card_type)   
				handleShowAlert()  
		}).catch((error) =>{
			alert(error)
		})
	}


	if(currentUser.sex === "woman" && loading === false) {
		return (
			<div>
				<WomanHeader />
				<TopBackground>
					<BackButtonCover>
						<ArrowBackIosIcon onClick={handleBackButtonClick} />
					</BackButtonCover>
					{/* <Title>お客様へのコメント変更</Title> */}
					<FormGroup>
						<td><RegisrationFormInput value={card_company} name="card_company" type="text" placeholder="金融機関名" onChange={ (e) => setCard_company(e.target.value ) }></RegisrationFormInput>
							</td>
							<GroupCheckBox>
								<GroupCheckBoxBody>
									<Checkbox
										checked={card_type=== "ordinary" ? true : false}
										onChange={ () => setCard_type("ordinary")}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<p>普通</p>
								</GroupCheckBoxBody>
								<GroupCheckBoxBody>
									<Checkbox
										checked={card_type === "current" ? true : false}
										onChange={ () => setCard_type("current")}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<p>当座</p>
								</GroupCheckBoxBody>
							</GroupCheckBox>
							<td><RegisrationFormInput value={card_number} name="card_number" type="text" placeholder="口座番号" onChange={ (e) => setCard_number(e.target.value ) }></RegisrationFormInput>
							</td>
							<br/>
							<td><RegisrationFormInput value={card_name} name="card_name" type="text" placeholder="口座名義人（カナ）" onChange={ (e) => setCard_name(e.target.value) }></RegisrationFormInput>
							</td>
						</FormGroup>
					<ButtonStyle onClick={handleUpdate}>変更する</ButtonStyle>
				</TopBackground>
				<SimpleAlertSuccess message={"変更致しました。"} showalert={showalert}/>
			</div>
		)
	} else {
		return (<div>loading...</div>)
	}
}
