import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

import Badge from '@material-ui/core/Badge';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import WomanHeader from '../organisms/womanHeader';
import MyPageSliderSetting from '../../presentational/molecules/myPageSliderSetting';
import { currentUser } from '../../../../infra/current_user';
import { getCurrentUser, getStores, getFindNotificationsCheck } from '../../../../infra/api';
import UserContentInfo from '../organisms/userContentInfo';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'

const WomanDetailsBody = styled.div`
  padding-bottom: 50px;
  background-color: #f8f7f3;
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
  `}
`

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  margin-top: 2px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const TitleHeaderCover = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const HeaderMenuIconCover = styled(Link)`
  color: #bbb;
  margin-left: 16px;
`

const LikePointDisplayCover = styled.div`
  margin-bottom: 36px;
`
const FavoriteBT = styled.div`
  margin-left: auto;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  width: 58%;
  justify-content: flex-end;
`

const LikePointDisplay = styled.dl`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 14px 10px;
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;

  > dt {
    width: 100%;
    font-size: 15px;
  }

  > dd {
    width: 100%;
    text-align: right;
    font-size: 26px;
    font-weight: bold;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    color: #ff444f;
  }
`

const AttendanceButtonDisplay = styled.dl`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 14px 10px;
  background: #FFEAEB;
  border-radius: 6px;
  box-sizing: border-box;
  color: #FF444F;
  justify-content: center;

  > svg {
    margin: 0 10px 2px 0;
  }
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

function HeadMyPage(props){
  let head = (
    <LikePointDisplayCover>
      <Link to="/pointBuy">
        <LikePointDisplay>
          <Text>残ポイント</Text>
          <dd>{props.point + "  p   "}
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#FFEAEB"/>
            <line x1="4" y1="10" x2="16" y2="10" stroke="#FF444F" stroke-width="2"/>
            <line x1="10" y1="16" x2="10" y2="4" stroke="#FF444F" stroke-width="2"/>
          </svg>
          </dd>
        </LikePointDisplay>
      </Link>
    </LikePointDisplayCover>
  )
  if(currentUser.sex === 'woman'){
    head = (
    <LikePointDisplayCover>
      <Link to="/attendanceSchedule">
        <AttendanceButtonDisplay>
          <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1667 3H2.83333C1.82081 3 1 3.78736 1 4.75862V18.2414C1 19.2126 1.82081 20 2.83333 20H21.1667C22.1792 20 23 19.2126 23 18.2414V4.75862C23 3.78736 22.1792 3 21.1667 3Z" stroke="#FF444F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <line x1="1" y1="9" x2="23" y2="9" stroke="#FF444F" stroke-width="2"/>
            <line x1="17" y1="5" x2="17" y2="1" stroke="#FF444F" stroke-width="2" stroke-linecap="round"/>
            <line x1="7" y1="5" x2="7" y2="1" stroke="#FF444F" stroke-width="2" stroke-linecap="round"/>
          </svg>
          {"シフト"}
        </AttendanceButtonDisplay>
      </Link>
    </LikePointDisplayCover>
    )
  }

  return head
}

const B0CenterMyPage = styled.div`
  display: unset;
  margin-top: 22px;
  ${mediaquery.desktop`
    display: flex;
  `}
`

const B1CenterMyPage = styled.div`
  display: flex;
  margin-right: 5%;
  height: 18px;
  padding: 2em 13px 2em;
  justify-content: space-between;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  border-bottom: 2px solid #BBBBBB;
  ${mediaquery.desktop`
    width: 50%;
  `}

  & > p:nth-child(1) {
    line-height: 25px;
  }

  & > p:nth-child(2) {
    font-weight: bold;
    font-size: 26px;
    color: #252525;
  }
`

const B3CenterMyPage = styled.div`
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #FF444F;
  margin-top: 15px;
  cursor: pointer;
  > svg {
    margin: -4px 4px;
  }
`
const NameUserMyPage = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #252525;
  margin-top: 25px;
`

function CenterMyPage(_this){
  let body = (
    <div>
      <B0CenterMyPage>
        <B1CenterMyPage>
          <p>今月の売り上げ</p>
          <p>{_this.state.user.get_point_in_month}P</p>
        </B1CenterMyPage>
      </B0CenterMyPage>
      <B3CenterMyPage onClick={() => {_this.props.history.push({
                                        pathname: "/mypage/settingComment",
                                        state: { user: _this.state.user }
                                      })}
                              }>
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C13.7535 0 18 3.1695 18 7.50525C18 9.04275 17.4457 10.5525 16.4648 11.724L17.9595 16.5L12.75 14.25C11.4713 14.5793 10.2495 14.7308 9.102 14.7308C3.7215 14.7308 0 11.4015 0 7.50525C0 3.14625 4.27575 0 9 0Z" fill="#FF444F"/>
        </svg>
        お客様へのコメント変更
      </B3CenterMyPage>
    </div>
  )
  if(currentUser.sex === 'man'){
    body = (<div></div>)
  }

  return body
}

class Mypage extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, stores: [], loading: true, show_edit_profile: false, showalert: false, alert_message: "プロフィールが保存されました。", notifications_check: false, notic_ct: 0 }
    this.handleShowEditProfile = this.handleShowEditProfile.bind(this)
    this.handleHideEditProfile = this.handleHideEditProfile.bind(this)
    this.handleChangeUser = this.handleChangeUser.bind(this)
    this.handleShowAlert = this.handleShowAlert.bind(this)
  }


  handleShowAlert (message){
    this.setState({alert_message: message, showalert: true})
    window.setTimeout(()=>{
      this.setState({showalert: false})
    },4000)
  }

  handleShowEditProfile(){
    this.setState({show_edit_profile: true})
  }

  handleHideEditProfile(){
    this.setState({show_edit_profile: false})
  }

  handleChangeUser(user){
    this.setState({user: user})
  }

  async componentWillMount() {
    const _this = this
    if(currentUser.sex === 'woman'){
      await getStores().then(async function (response) {
        await _this.setState({ stores: response.data.stores })        
      })
    }
    getCurrentUser().then(async function (response) {
      await _this.setState({ user: response.data.woman || response.data, loading: false })        
    }).catch(function (response){
      alert(response.message)
    })

    await getFindNotificationsCheck({useable_id: currentUser.id, useable_type: currentUser.sex}).then(async function (response) {
      if(response.data.length !== 0){
        await _this.setState({ notifications_check: true, notic_ct: response.data.length })  
      } 
    })
  }

  render() {
    if(this.state.loading || currentUser.sex === "store") {
      return(<div>Loading... </div>)
    } else {
      return (
        <div style={{backgroundColor: '#f8f7f3'}}>
          <WomanHeader />
          <WomanDetailsBody>
            <TopBackground>
              <TitleHeaderCover>
                {/* <Title>マイページ</Title> */}
                <FavoriteBT>
                  <div>
                    {this.state.notifications_check ?
                      <Badge badgeContent={this.state.notic_ct} color="error">
                        <HeaderMenuIconCover to="/notification"><NotificationsNoneRoundedIcon /></HeaderMenuIconCover>
                      </Badge> :
                      <HeaderMenuIconCover to="/notification"><NotificationsNoneRoundedIcon /></HeaderMenuIconCover>
                    }
                  </div>
                  <HeaderMenuIconCover to="/setting"><SettingsOutlinedIcon /></HeaderMenuIconCover>
                </FavoriteBT>
              </TitleHeaderCover>
              <HeadMyPage point={this.state.user.point}/>
              <MyPageSliderSetting handleShowAlert={this.handleShowAlert} images={this.state.user.sorted_images} handleChangeUser={this.handleChangeUser}/>
              {CenterMyPage(this)}
              <NameUserMyPage>{currentUser.sex === "woman" ? `${this.state.user.nickname}` : `${this.state.user.nickname}`}</NameUserMyPage>
              <UserContentInfo handleShowAlert={this.handleShowAlert} handleChangeUser={this.handleChangeUser} handleHideEditProfile={this.handleHideEditProfile} handleShowEditProfile={this.handleShowEditProfile} show_edit_profile={this.state.show_edit_profile} user={this.state.user} stores={this.state.stores}/>
            </TopBackground>
          </WomanDetailsBody>
          <SimpleAlertSuccess message={this.state.alert_message} showalert={this.state.showalert}/>
        </div>
      )
    }
  }
}

export default Mypage;
