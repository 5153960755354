import React from 'react';
import DatePicker from 'react-datepicker';
import mediaquery from '../../../../assets/styles/variable';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";

const GroupSearch = styled.div`
  width: 95%;
  margin: auto;
  ${mediaquery.desktop`
    display: flex;
    max-width: 55%;
    margin: 18px auto;
  `}
`
const ChirlGroupSearch = styled.div`
  display: flex;
  margin-left: 6px;
  margin-top: 10px;
  & > p {
    line-height: 40px;
    text-align: left;
    margin-left: 13px;
    width: 44px;
  }
  ${mediaquery.desktop`
    & > p {
      line-height: 40px;
      width: 45px;
      text-align: right;
    }
  `}
`

export default function SearchTimeForm(props) {
  const [fromdate, setFromdate] = React.useState(new Date());
  const [todate, setTodate] = React.useState(new Date());

  const handleOnChangeTo = async (date) => {
    await setTodate(date)
    props.groupFieldSearch({ 'from_date': fromdate, 'to_date': date })
  }

  const handleOnChangeFrom = async (date) => {
    await setFromdate(date)
    props.groupFieldSearch({ 'from_date': date, 'to_date': todate })
  }

  if(props.showSearchTimeForm){
    return (
      <GroupSearch>
        <ChirlGroupSearch>
          <p>from: </p>
          <DatePicker selected={fromdate} maxDate={todate} onChange={date => handleOnChangeFrom(date)} />
        </ChirlGroupSearch>
        <ChirlGroupSearch>
          <p>to: </p>
          <DatePicker selected={todate} maxDate={new Date()} onChange={date => handleOnChangeTo(date)} />
        </ChirlGroupSearch>
      </GroupSearch>
    );
  }else{
    return (
      <div></div>
    );
  }
}
