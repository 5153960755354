import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { signUpStore } from '../../../../infra/api';
import firebase from '../../../../infra/my_firebase';
import NextRegistration from './nextRegistration'

const RegistrationBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  margin: 30px 0;
`

const RegistrationFormGroup = styled.dl`
  > dt {
    font-size: 28px;
    font-weight: bold;
    margin: 12px 0;
  }

  > dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`

const RegistrationForm = styled.form`
  width: 100%;
`

const RegistrationFormTable = styled.table`
  display: block;

  > td {
    display: block;

    &:not(:first-of-type) {
      margin-top: 26px;
    }
  }
  
  > p {
    text-align: left;
    margin-top: 33px;
    margin-bottom: -16px;
  }
`

const RegisrationFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`

const RegistrationFormGroupNote = styled.p`
  color: #ff444f;
  max-width: 345px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 12px;
  text-align: left;
`

const NextRegistrationButtonCover = styled.div`
  margin: 18px 0;
`

const OtherText = styled(Link)`
  color: #ff444f;

  &:last-of-type {
    margin-top: 12px;
  }
`
const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  cursor: pointer;
`

const ErrorValid = styled.p`
  margin: auto;
  text-align: left;
  width: 345px;
  color: red;
  font-size: 10px;
`

export default class StoreRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberPhoneInput: '',
      nameInput: '',
      nicknameInput: '',
      store_nameInput: '',
      addressInput: '',
      fundsInput: '',
      income_in_yearInput: '',
      years_operationInput: '',
      member_countInput: '',
      tel: '',
      name: '',
      nickname: '',
      store_name: '',
      address: '',
      confirmationResult: {},
      user_id: null,
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.convertPhoneNumber = this.convertPhoneNumber.bind(this);
    this.handleChangeCardType = this.handleChangeCardType.bind(this)
  }

  handleSignup(e) {
    const _this = this
    e.preventDefault();
    const { numberPhoneInput, nameInput, nicknameInput, store_nameInput, addressInput, fundsInput, income_in_yearInput, years_operationInput, member_countInput } = this.state;
    const  store = { 'tel': numberPhoneInput, 'name': nameInput, 'nickname': nicknameInput, 'store_name': store_nameInput, 'address': addressInput, 'funds': fundsInput, 'income_in_year': income_in_yearInput, 'years_operation': years_operationInput, 'member_count': member_countInput }
    const token = this.props.match.params.token
    signUpStore(token, { store: store }).then(async function (response) {
      _this.handleLogin()
    }).catch(function (error) {
      const errors = error.response.data.error_not_found
      if(errors) return alert(errors)
      let { tel, name, nickname, store_name, address } = error.response.data.error
      tel = tel === undefined ? '' : tel.join(',')
      name = name === undefined ? '' : name.join(',')
      nickname = nickname === undefined ? '' : nickname.join(',')
      store_name = store_name === undefined ? '' : store_name.join(',')
      address = address === undefined ? '' : address.join(',')

      _this.setState({ tel: tel, name: name, nickname: nickname, store_name: store_name, address: address })
    });
  }

  handleLogin() {
    const { numberPhoneInput } = this.state;
    try {
      this.signInPhoneNumber(numberPhoneInput)
    } catch (error) {
      alert(error);
    }
  }

  async signInPhoneNumber(numberPhoneInput){
    const _this = this
    if (document.getElementById('recaptcha-container') !== null){
      window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible'
      });
    }
    firebase.auth().signInWithPhoneNumber(numberPhoneInput, window.recaptchaVerifier).then(function (confirmationResult) {
      _this.setState({ confirmationResult: confirmationResult })
    }).catch(function (error) {
      alert(error)
      window.location.reload();
    });
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  handleChangeCardType(type){
    this.setState({card_typeInPut: type})
  }

  convertPhoneNumber(phone_number) {
    phone_number = phone_number.split(' ').join('').toString().replace(/^0/, '+81');
    return phone_number;
  }

  render() {
    if(this.isEmpty(this.state.confirmationResult)){
      return (
        <div className="App">
          <RegistrationBackground>
              <Title>北新地モバイル</Title>
              {/* loginForm.js */}
              <RegistrationFormGroup>
              <dt>基本情報入力</dt>
              <dd>
                <RegistrationForm>
                    <RegistrationFormTable>
                      <td><RegisrationFormInput name="nickname" type="text" placeholder="ニックネーム" onChange={ (e) => this.setState({ nicknameInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.nickname}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="name" type="text" placeholder="お名前" onChange={ (e) => this.setState({ nameInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.name}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="store_name" type="text" placeholder="店舗名" onChange={ (e) => this.setState({ store_nameInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.store_name}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="address" type="text" placeholder="実店舗、もしくは自身の住所" onChange={ (e) => this.setState({ addressInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.address}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="funds" type="number" min="1" placeholder="資本金" onChange={ (e) => this.setState({ fundsInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.funds}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput type="number" min="1" name="income_in_year" placeholder="年間売上" onChange={ (e) => this.setState({ income_in_yearInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.income_in_year}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="member_count" type="number" min="1" placeholder="在籍者数" onChange={ (e) => this.setState({ member_countInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.member_count}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="years_operation" type="number" min="1" placeholder="年数" onChange={ (e) => this.setState({ years_operationInput: e.target.value }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.years_operation}</ErrorValid>
                      </td>
                      <td><RegisrationFormInput name="tel" type="text" placeholder="電話番号" onChange={ (e) => this.setState({ numberPhoneInput: this.convertPhoneNumber(e.target.value) }) }></RegisrationFormInput>
                        <ErrorValid>{this.state.tel}</ErrorValid>
                      </td>
                    </RegistrationFormTable>
                    <div id="recaptcha-container"></div>
                </RegistrationForm>
                <div><RegistrationFormGroupNote>※お名前、会社名、役職は運営側にしか表示されませんので、ご安心下さい。</RegistrationFormGroupNote></div>
              </dd>
              </RegistrationFormGroup>
              {/* 男性、女性チェックリストコンポーネント.js */}
              {/* nextRegistration.js */}
              <NextRegistrationButtonCover>
                <ButtonStyle onClick={this.handleSignup} > 次へ </ButtonStyle>
              </NextRegistrationButtonCover>
              {/* login.js */}
              <NextRegistrationButtonCover>
                <OtherText to="/login">アカウントをお持ちの方はこちらからログイン</OtherText>
              </NextRegistrationButtonCover>
          </RegistrationBackground>
        </div>
      )
    }else {
      return (
        <NextRegistration phoneNumber={this.state.numberPhoneInput} confirmationResult={this.state.confirmationResult}/>
      )
    }
  }
};
