import React, { Component } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import RegisterButton from '../atoms/registerButton';

const TopButtonGroupCover = styled.div`
  margin: 20px;
`

const TopButtonGroupTextCover =styled.div`
  margin-top: 10px;
`

const TopButtonGroupText = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;

  ${mediaquery.desktop`
    font-size: 18px;
    line-height: 27px;
  `}
`

class TopButtonGroup extends Component {
  render() {
    return (
      <TopButtonGroupCover>
        <RegisterButton text="まずは会員登録" />
        <TopButtonGroupTextCover>
          <TopButtonGroupText>※男性も女性もまずは会員登録</TopButtonGroupText>
        </TopButtonGroupTextCover>
      </TopButtonGroupCover>
    )
  }
}

export default TopButtonGroup