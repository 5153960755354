import React, { Component } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

import Header from '../organisms/header';
import Footer from '../organisms/footer';
import TopButtonGroup from '../../presentational/molecules/topButtonGroup';
import DefaultLoginButton from '../../presentational/atoms/defaultLoginButton';
import RegisterButton from '../../presentational/atoms/registerButton';
// sp用画像
import TopImage from '../../../../assets/images/towardsMan/sp/top.png';
import StepImage_01 from '../../../../assets/images/towardsMan/sp/step01.png';
import StepImage_02 from '../../../../assets/images/towardsMan/sp/step02.png';
import StepImage_03 from '../../../../assets/images/towardsMan/sp/step03.png';
import SecurityIcon from '../../../../assets/images/towardsMan/sp/securityIcon.png';
// pc用画像
import TopImagePc from '../../../../assets/images/towardsMan/pc/top_pc.png';

const TopBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 460px;
  text-align: center;
  background-image: url(${TopImage});
  background-repeat:  no-repeat;
  background-position: center center;

  ${mediaquery.desktop`
    max-width: 1280px;
    height: 700px;
    background-image: url(${TopImagePc});
  `}
`

const Title = styled.h2`
  width: 288px;
  color: #fff;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 36px;
  font-weight: 600;
  font-family: serif;
  line-height: 130.2%;

  ${mediaquery.desktop`
    width: 100%;
    font-size: 46px;
    line-height: 69px;
    margin-top: 13rem;
  `}
`

const IntroductionCover = styled.div`
  max-width: 375px;
  margin: 60px auto 0;

  ${mediaquery.desktop`
    max-width: 1280px;
  `}
`

const IntroductionTitle =styled.h3`
  color: #FF444F;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;

  ${mediaquery.desktop`
    font-size: 22px;
    line-height: 33px;
  `}
`

const BrDesktop =styled.br`
  display: none;

  ${mediaquery.desktop`
    display: block;
  `}
`

const BrPhone =styled.br`
  display: block;

  ${mediaquery.desktop`
  display: none;
  `}
`

const IntroductionDescriptionCover = styled.dl`
  text-align: center;

  > dt {
    font-size: 30px;
    font-weight: 600;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    line-height: 45px;

    ${mediaquery.desktop`
      font-size: 46px;
      line-height: 69px;
    `}
  }

  > dd {
    margin: 20px auto;
    line-height: 24px;
    font-size: 16px;
    text-align: center;

    ${mediaquery.desktop`
      font-weight: 500;
      font-size: 18px;
      line-height: 2;
      text-align: center;
    `}

    &:not(:first-of-type){
      margin: 40px auto;
    }

    > p {
      text-align: center;

      ${mediaquery.desktop`
        font-size: 18px;
        line-height: 2;
        text-align: center;
      `}
    }
  }
`

const AboutStepCover = styled.dd`
  ${mediaquery.desktop`
    display: flex;
    justify-content: space-between;
    width: 804px;
    margin: 0 auto;
  `}
`

const AboutStep = styled.div`
  margin: 20px 0;

  > figcaption {
    margin: 10px 0;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    line-height: 24px;
  }
`

const NoDeaikeiCover = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto;
`

const NoDeaikei = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 345px;
  height: 176px;
  padding: 26px 30px;
  background: #ff7d85;
  box-shadow: 4px 4px 0px #FF444F;
  border-radius: 12px;
  box-sizing: border-box;

  ${mediaquery.desktop`
    width: 644px;
    height: 180px;
  `}

  > dt {
    color: #fff;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 33px;

    ${mediaquery.desktop`
      font-size: 32px;
      line-height: 48px;
      text-align: center;
    `}
  }

  > dd {
    margin-top: 14px;
    color: #fff;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    ${mediaquery.desktop`
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    `}
  }

  > p {
    margin-bottom: 20px;
  }
`

// const CurrentCover = styled.div`
//   position: relative;
//   top: -12px;
//   z-index: -1;
//   padding: 74px 15px;
//   background-image: url(${CurrentBackgroundImage});
  
//   &::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 0 0 45px 100vw;
//     border-color: transparent transparent #fff transparent;
//   }
// `

// const CurrentDescriptionCover = styled.dd`
//   ${mediaquery.desktop`
//     display: flex;
//     justify-content: space-around;
//   `}
// `

// const CurrentDescription = styled.div`
//   ${mediaquery.desktop`
//     width: 250px;
//   `}

//   &:not(:first-of-type) {
//     margin-top: 20px;

//     ${mediaquery.desktop`
//       margin-top: 0;
//     `}
//   }

//   > figure {
//     margin: 30px 0 13px;
//   }

//   > figcaption {
//     font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 18px;
//     line-height: 27px;
//     text-align: center;
//     color: #252525;
//   }

//   > p {
//     font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 21px;
//     text-align: left;
//   }
// `

const MissionDescription = styled.dd`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2;
  text-align: left;

  ${mediaquery.desktop`
    width: 800px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
    line-height: 2;
  `}
`

const MissionDescription2 = styled.dd`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 2;
  text-align: left;

  ${mediaquery.desktop`
    width: 800px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
    line-height: 2;
  `}
`

const MissionCover = styled.div`
  margin: 20px 18px;
`

const MissionStepDescription = styled.dl`
  ${mediaquery.desktop`
    display: flex;
    justify-content: space-between;
    margin: 0 125px;
  `}

  > div {
    position: relative;
    margin-top: 2rem;

    > dt {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-family: Poppins;
      font-weight: normal;
      font-size: 34px;
      line-height: 51px;
      color: #ff444f;
    }
  
    > dd {
      padding: 30px;
      font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      background: #ffeaeb;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;

      ${mediaquery.desktop`
        width: 330px;
        height: 170px;
      `}
  
      > span {
        font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ff444f;
      }
    }
  }
`

// const DummyImageCover = styled.div`
//   max-width: 345px;
//   height: 180px;
//   margin: 10px auto 30px;
//   background-image: url(${DummyImage});
//   background-repeat:  no-repeat;
// `

const MazuwaKaiintourokuButtonCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 345px;
  height: 186px;
  padding: 20px;
  box-sizing: border-box;
  background: #ff7d85;
  box-shadow: 4px 4px 0px #ff444f;
  border-radius: 12px;
  

  ${mediaquery.desktop`
    max-width: 644px;
    height: 162px;
    margin: 0 auto;
    text-align: center;
  `}

  > p {
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
`

// const PromotionCover = styled.div`
//   height: 428px;
//   padding: 60px 15px;
//   background: #f8f7f3;
//   box-sizing: border-box;

//   ${mediaquery.desktop`
//     height: 672px;
//   `}
// `

const SecurityCover = styled.div`
  height: 272px;
  padding: 40px 15px;
  background: #ffeaeb;
  box-sizing: border-box;

  ${mediaquery.desktop`
    height: 300px;
  `}
`

const SecurityIconCover = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 12px;
`

const SecurityDescriptionCover = styled.dl`
  > dt {
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;

    ${mediaquery.desktop`
      font-size: 34px;
      line-height: 51px;
    `}
  }

  > dd {
    margin-top: 16px;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    ${mediaquery.desktop`
      font-size: 18px;
      line-height: 27px;
    `}
  }
`

const HeaderParent = styled.div`
  ${mediaquery.desktop`
    width: 100%;
    position: absolute;
    top: 15px;
    > div {
      color: #fff;
      width: 100%;
      > a {
        width: 40%;
        color: #fff;
        text-align: left;
      }
    }
  `}
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const Textdd = styled.dd`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const HeaderTop = () => {
  let head = ( <TopBackground>
    <HeaderParent>
      <Header />
    </HeaderParent>
    <Title>モバイルクラブで素敵な夜を…</Title>
    <AuthButton/>
  </TopBackground>)

  if(window.innerWidth <= 1280){
    head = (
      <React.Fragment>
        <Header />
        <TopBackground>
          <Title>モバイルクラブで素敵な夜を…</Title>
          <AuthButton/>
        </TopBackground>
      </React.Fragment>
    )
  }

  return head
}


const AuthButton = () => {
    return (<div>
      <TopButtonGroup />
      <DefaultLoginButton />
    </div>)
}

const TextTop1 = () => {
  let body = ( <Textdd>ホステスとお客様を繋ぎ上質な空間と時間をご提供するシステムです。<br/>
    お好みの女性、空間を選んで 素敵なひと時をご堪能ください。</Textdd>)

  if(window.innerWidth <= 1280){
    body = (
      <Textdd>ホステスとお客様を繋ぎ上質な空間と<br/>
    時間をご提供するシステムです。<br/>
    お好みの女性、飲食店を選んで、<br/>
    素敵なひと時をご堪能ください。</Textdd>
    )
  }

  return body
}

const TextTop2 = () => {
  let body = ( <Textdd>出会い系サイトではありません。<br/>
    いわばお店を持たず、ネット上で経営するクラブ&キャバクラです。</Textdd>)

  if(window.innerWidth <= 1280){
    body = (
      <Textdd>出会い系サイトではありません。<br/>
      いわばお店を持たず、ネット上で経営する<br/>
      クラブ&キャバクラです。</Textdd>
    )
  }

  return body
}

class Top extends Component {
  render() {
    return (
      <div>
        <HeaderTop/>
        <IntroductionCover>
          <div id="about">
            <IntroductionTitle>ABOUT</IntroductionTitle>
            <IntroductionDescriptionCover>
              <Text>モバイルクラブって？</Text>
              <TextTop1/>
              <AboutStepCover>
                <AboutStep>
                  <figure><img src={StepImage_01} alt=""></img></figure>
                  <figcaption>気になる女性を選択</figcaption>
                </AboutStep>

                <AboutStep>
                  <figure><img src={StepImage_02} alt=""></img></figure>
                  <figcaption>お好きな飲食店を指定</figcaption>
                </AboutStep>

                <AboutStep>
                  <figure><img src={StepImage_03} alt=""></img></figure>
                  <figcaption>指定した場所で待ち合わせ</figcaption>
                </AboutStep>
              </AboutStepCover>
              <NoDeaikeiCover>
                <NoDeaikei>
                  <Text>出会い系サイトじゃないの？</Text>
                  <TextTop2/>
                </NoDeaikei>
              </NoDeaikeiCover>
            </IntroductionDescriptionCover>
          </div>

          {/* <CurrentCover id="current">
            <IntroductionTitle>CURRENT</IntroductionTitle>
            <IntroductionDescriptionCover>
              <Text>コロナの影響でホステスさんが困っています</Text>
              <CurrentDescriptionCover>
                <CurrentDescription>
                  <figure><img src={women1} alt=""></img></figure>
                  <figcaption>某高級クラブ ホステス シングルマザー</figcaption><br/>
                  <p>20歳の時から銀座のクラブで働いています。10代で未婚で子供を産んで、子育てしながら働いてきました。この世界、シンママ率が高く子育てに関して助け合ったりする環境が整っているんです。売上を持っているような一部のホステスは別として、一般のホステスの稼ぎは世の中で思われてるほどよくありません。自粛明けも私たちのような売上のないホステスは出勤が規制されて、以前の収入の半分以下になり、違法な性風俗店に流れている同僚もいます。うちの店も性産業関係は御法度なんですが、食べていかなきゃいけないので、、、目を瞑っています。</p>
                </CurrentDescription>

                <CurrentDescription>
                  <figure><img src={women2} alt=""></img></figure>
                  <figcaption>某ラウンジのオーナー</figcaption><br/>
                  <p>オイルショックやバブルなど全部経験し何度も苦しい状況を乗り越えてきましたが、こんなにひどいのは初めてです。感染防止の対策を取っていますが、酷かったときは売り上げが以前の80％から90％ほど減少してしまって、今も半分以下です、感染が収まらないとこれ以上経営を続けられないです。</p>
                </CurrentDescription>

                <CurrentDescription>
                  <figure><img src={women3} alt=""></img></figure>
                  <figcaption>某高級クラブ　オーナーママ</figcaption><br/>
                  <p>私達は基本的に通常の融資も受けられません。初めて国から保証をいただき、初めて人として認められたような気がしました。本当に感謝しています。ですが、それでも尚厳しい状況は続いています｡景気が悪いときはこれまでもありましたが私たちが頑張ることを､誰かに止められはしなかったので･･利益がギリギリ出るという売り上げラインは、月に約7000万円ほど｡しかし､営業を自粛してからは売り上げが消滅｡家賃は約600万円｡従業員への給料や諸経費も約600万円かかり､月1200万円ほどの支出だけが2か月続いています｡今も月の売上は戻らず赤字が続いています。</p>
                </CurrentDescription>

              </CurrentDescriptionCover>
            </IntroductionDescriptionCover>
          </CurrentCover> */}

          <MissionCover id="mission">
            <IntroductionTitle>MISSION</IntroductionTitle>
            <IntroductionDescriptionCover>
              <Text>私たちの使命</Text>
              <MissionDescription2>Mobile Club は、「もてなし」の心をもった<BrPhone/>女性と<BrDesktop/>
              その人となりを知る人間味ある男性<BrPhone/>とのMeeting Placeです。<br/><BrPhone/>
              私達のサイトでは、コロナ禍で混迷する<BrPhone/>プロの「水商売」経験者の方も大歓迎です。<br/><BrPhone/>
              なぜなら、「水商売」のいわれは<BrPhone/>このサイトが求める「もてなし」の起源があるからです。<br/><BrPhone/>
              水商売とは、水に倣への喩われ通り</MissionDescription2>
              <dd>
                <MissionStepDescription>
                  <div>
                    <dt>01</dt>
                    <dd>人は、水の柔軟性に倣え。<BrPhone/>(Flexibility) <BrPhone/>水は、四角くも丸くも不満も言わず姿形を変えることができる。<br/>
                      <span>人であれば、万人と語らえること。</span>
                    </dd>
                  </div>

                  <div>
                    <dt>02</dt>
                    <dd>人は、水の強さに倣え。<BrPhone/>(Strength of mind) <BrPhone/>水は、滝に落とせば硬い石をも砕くことができる。<br/>
                      <span>人であれば、時に意思硬く頼もしいこと。</span>
                    </dd>
                  </div>

                  <div>
                    <dt>03</dt>
                    <dd>人は、水の包容力に倣え。<BrPhone/>(Tolerance) <BrPhone/>水は、清流から湖畔に辿りつけば清らかである。<br/>
                      <span>人であれば、他の人に癒しの心根をもつこと。</span>
                    </dd>
                  </div>
                </MissionStepDescription>
              </dd>
              <MissionDescription>
                {/* <DummyImageCover></DummyImageCover> */}
                <p>私達は、最良の心をもつ人達の巡り合いの場所としてMobile Club を創設いたしました。<br/><BrPhone/>
                この 3つの水、「もてなし」の起源を理解してくださる方ならどなたも大歓迎です。<br/><BrPhone/>
                現在、さまざまな良縁の場面(サイト)がありますが、<BrDesktop/>
                私たちはコロナ禍で混迷する社会に光をかざすことを目的としています。<br/><BrPhone/>
                そしてこの巡り合わせが皆さんの元気となり、更に全国各地区繁華街の活性化に繋がること。<br/><BrPhone/>
                「三水」と「三蜜」のマナーをもって、<BrPhone/>コロナ渦に耐え未来に繋がるサイトとなることを念じています。</p>
              </MissionDescription>
            </IntroductionDescriptionCover>

            <MazuwaKaiintourokuButtonCover>
              <p>女性の方も、まずは、会員登録して皆で <br/>
              「自分みがき」と「地域活性化」の未来に参加しましょう。</p>
              <br/>
              <RegisterButton text="まずは会員登録" />
            </MazuwaKaiintourokuButtonCover>
          </MissionCover><br/><br/>
          {/* <PromotionCover id="promotion">
            <IntroductionTitle>PROMOTION</IntroductionTitle>
            <IntroductionDescriptionCover>
              <Text>プロモーション映像</Text>
              <dd>
                <video></video>
              </dd>
            </IntroductionDescriptionCover>
          </PromotionCover> */}

          <SecurityCover>
            <SecurityIconCover><img src={SecurityIcon} alt=""></img></SecurityIconCover>
            <SecurityDescriptionCover>
              <Text>安心のセキュリティ対策</Text>
              <dd>安全にご利用いただくために、業界最先端のセキュリティ対策で、<br/>ユーザーの情報を保護します。</dd>
            </SecurityDescriptionCover>
          </SecurityCover>
        </IntroductionCover>

        <Footer />
      </div>
    )
  }
}

export default Top
