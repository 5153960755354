import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import WomenOrganisms from '../organisms/womenOrganisms'

import Badge from '@material-ui/core/Badge';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import WomanHeader from '../organisms/womanHeader';
import MyPageSliderSetting from '../../presentational/molecules/myPageSliderSetting';
import { getCurrentUser } from '../../../../infra/api';
import StoreContentInfo from '../organisms/storeContentInfo';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'
import { currentUser } from '../../../../infra/current_user';

const WomanDetailsBody = styled.div`
  padding-bottom: 50px;
  background-color: #f8f7f3;
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
  `}
`

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  margin-top: 2px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const TitleHeaderCover = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const HeaderMenuIconCover = styled(Link)`
  color: #bbb;
  margin-left: 16px;
`

const FavoriteBT = styled.div`
  margin-left: auto;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  width: 58%;
  justify-content: flex-end;
`

const B0CenterMyPage = styled.div`
  display: unset;
  margin-top: 22px;
  ${mediaquery.desktop`
    display: flex;
  `}
`

const B1CenterMyPage = styled.div`
  display: flex;
  margin-right: 5%;
  height: 18px;
  padding: 13px 0 13px 13px;
  justify-content: space-between;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  border-bottom: 2px solid #BBBBBB;
  ${mediaquery.desktop`
    width: 50%;
  `}

  & > p:nth-child(1) {
    line-height: 25px;
  }

  & > p:nth-child(2) {
    font-weight: bold;
    font-size: 26px;
    color: #252525;
  }
`

const B2CenterMyPage = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #252525;
  text-align: center;
  margin-top: 10px;
`

const B3CenterMyPage = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FF444F;
  width: 175px;
  height: 31px;
  background: #FFEAEB;
  border-radius: 8px;
  text-align: center;
  line-height: 31px;
  margin: auto;
  margin-bottom: 15px;
`
const NameUserMyPage = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #252525;
  margin-top: 25px;
`

const TitleList = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: #252525;
  margin-bottom: 60px;
`

const WML = styled.div`
  padding: 16px;
`

function CenterMyPage(props){
  let body = (
    <div>
      <B2CenterMyPage>{props.user.store_name}</B2CenterMyPage>
  <B3CenterMyPage>お店ID  A000{props.user.id}</B3CenterMyPage>
      <B0CenterMyPage>
        <B1CenterMyPage>
          <p>今月の売り上げ</p>
          <p>{props.user.income_in_year}P</p>
        </B1CenterMyPage>
        <B1CenterMyPage>
          <p>利益</p>
          <p>50,000万円</p>
        </B1CenterMyPage>
      </B0CenterMyPage>
    </div>
  )

  return body
}

class Mystore extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, women: [], loading: true, show_edit_profile: false, showalert: false, alert_message: "プロフィールが保存されました。" }
    this.handleShowEditProfile = this.handleShowEditProfile.bind(this)
    this.handleHideEditProfile = this.handleHideEditProfile.bind(this)
    this.handleChangeUser = this.handleChangeUser.bind(this)
    this.handleShowAlert = this.handleShowAlert.bind(this)
  }


  handleShowAlert (message){
    this.setState({alert_message: message, showalert: true})
    window.setTimeout(()=>{
      this.setState({showalert: false})
    },4000)
  }

  handleShowEditProfile(){
    this.setState({show_edit_profile: true})
  }

  handleHideEditProfile(){
    this.setState({show_edit_profile: false})
  }

  handleChangeUser(user){
    this.setState({user: user})
  }

  componentWillMount() {
    const _this = this
    getCurrentUser().then(async function (response) {
      await _this.setState({ user: response.data.store , women: response.data.women, loading: false })        
    }).catch(function (response){
      alert(response.message)
    })
  }

  render() {
    if(this.state.loading || currentUser.sex !== "store") {
      return(<div>Loading... </div>)
    } else {
      return (
        <div style={{backgroundColor: '#f8f7f3'}}>
          <WomanHeader />
          <WomanDetailsBody>
            <TopBackground>
              <TitleHeaderCover>
                {/* <Title>マイページ</Title> */}
                <FavoriteBT>
                  <div>
                    <Badge badgeContent={4} color="error">
                      <HeaderMenuIconCover to="/notification"><NotificationsNoneRoundedIcon /></HeaderMenuIconCover>
                    </Badge>
                  </div>
                  <HeaderMenuIconCover to="/setting"><SettingsOutlinedIcon /></HeaderMenuIconCover>
                </FavoriteBT>
              </TitleHeaderCover>
              <MyPageSliderSetting handleShowAlert={this.handleShowAlert} images={this.state.user.sorted_images} handleChangeUser={this.handleChangeUser}/>
              <CenterMyPage user={this.state.user}/>
              <NameUserMyPage>{this.state.user.nickname}</NameUserMyPage>
              <StoreContentInfo handleShowAlert={this.handleShowAlert} handleChangeUser={this.handleChangeUser} handleHideEditProfile={this.handleHideEditProfile} handleShowEditProfile={this.handleShowEditProfile} show_edit_profile={this.state.show_edit_profile} user={this.state.user}/>
            </TopBackground>
          </WomanDetailsBody>
          <WML>
            <TitleList>従業員一覧</TitleList>
            <WomenOrganisms userList = {this.state.women}/>
          </WML>
          <SimpleAlertSuccess message={this.state.alert_message} showalert={this.state.showalert}/>
        </div>
      )
    }
  }
}

export default Mystore;