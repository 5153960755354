import React from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { currentUser } from '../../../../infra/current_user';
import { putUpdateCommentWoman } from '../../../../infra/api.js';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'

const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
	background-color: #ff444f;
	margin: auto;
`
const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
	background-color: #f8f7f3;
	
	> textarea {
		width: 100%;
    min-height: 160px;
    margin: 60px 0px;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #252525;
	}
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
	font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	color: #252525;

`

export default function SettingComment(props) {
	const [comment, setComment] = React.useState(props.location.state.user.comment)
	const [showalert, setShowalert] = React.useState(false)

	const handleShowAlert = (message) =>{
    setShowalert(true)
    window.setTimeout(()=>{
      setShowalert(false)
    },4000)
  }

	const handleBackButtonClick = () => {
		props.history.goBack('/mypage');
	};

	const handleUpdateComment = () =>{
		putUpdateCommentWoman({comment: comment}).then((response) =>{
			setComment(response.data.comment)
			handleShowAlert()
		})
	}


	if(currentUser.sex === "woman") {
		return (
			<div>
				<WomanHeader />
				<TopBackground>
					<BackButtonCover>
						<ArrowBackIosIcon onClick={handleBackButtonClick} />
					</BackButtonCover>
					<Title>お客様へのコメント変更</Title>
					<textarea onChange={(e) =>{setComment(e.target.value)}} value={comment}></textarea>  
					<ButtonStyle onClick={handleUpdateComment}>変更する</ButtonStyle>
				</TopBackground>
				<SimpleAlertSuccess message={"変更に成功しました。"} showalert={showalert}/>
			</div>
		)
	} else {
		return (<div>loading...</div>)
	}
}
