import React from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  ${mediaquery.desktop`
    flex-flow: unset;
  `}
`
const UserDescription = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #252525;
  margin: 25px 0;
  letter-spacing: 0.75px;
  word-break: break-word;
`

const UserInfoBody = styled.div`
  width: 50%;
  font-weight: 500;
  font-size: 16px;
  width: 89%;
  &:first-child{
    margin-right: 10%;
  }
  ${mediaquery.desktop`
    width: 45%;
  `}

`

const UserInfoContent = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > p:first-child{
    color: #A6A6A6;
    line-height: 32px;
  }
  > p {
    width: 35%;
    float: left;
    line-height: 32px;
  }
`

export default function UserContentDetail(props) {
  return (
    <div>
      <UserDescription>{props.user.description}</UserDescription>
      <UserInfo>
        <UserInfoBody>
          <UserInfoContent>
            <p>年齢</p>
            <p>{props.user.age}歳</p>
          </UserInfoContent>
          <UserInfoContent>
            <p>居住地</p>
            <p>{props.user.area}</p>
          </UserInfoContent>
          <UserInfoContent>
            <p>身長</p>
            <p>{props.user.height}cm</p>
          </UserInfoContent>
        </UserInfoBody>
        <UserInfoBody>
          <UserInfoContent>
            <p>体型</p>
            <p>{props.user.figure}</p>
          </UserInfoContent>
          <UserInfoContent>
            <p>出身地</p>
            <p>{props.user.job}</p>
          </UserInfoContent>
        </UserInfoBody>
      </UserInfo>
    </div>
  ) 
}