import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const SettingList = styled.dl`
  > dt {
    margin-top: 2em;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.04em;
    padding: 0px 0px 2em;

    color: #BBBBBB;
  }
  > dd {
    max-width: 375px;
    height: 40px;
    margin-bottom: 2px;
    background-color: #fff;
    line-height: 40px;
    padding-left: 20px;
    
    > svg {
      display: inline-block;
      float: right;
      margin-right: 20px;
      margin-top: 13px;
    }
  }
`

const Text = styled.dd`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

class Setting extends Component {
  handleBackButtonClick = () => {
    this.props.history.goBack('/pointPay');
  };

  handleSettingAccountClick = () => {
    // :todo「/woman/[props.woman.name]というように値を渡さなければいけない。」
    this.props.history.push('/settingAccount');
  }

  handleSettingCreditCardClick = () => {
    // :todo「/woman/[props.woman.name]というように値を渡さなければいけない。」
    this.props.history.push('/settingCreditCard');
  }

  handleSettingBankInfo = () => {
    // :todo「/woman/[props.woman.name]というように値を渡さなければいけない。」
    this.props.history.push('/settingBankInfo');
  }


  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <BackButtonCover>
            <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
          </BackButtonCover>
          {/* <Title>設定</Title> */}
          <SettingList>
              <dt></dt>
              <Text onClick={this.handleSettingAccountClick}>アカウント情報変更
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L1 13" stroke="#D7D7D7" stroke-width="2"/>
                </svg>
              </Text> 
              {currentUser.sex === "man" && (<Text onClick={this.handleSettingCreditCardClick}>クレジットカード情報変更
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L1 13" stroke="#D7D7D7" stroke-width="2"/>
                </svg>
              </Text>)}
              {currentUser.sex === "woman" && (<Text onClick={this.handleSettingBankInfo}>口座情報変更
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L1 13" stroke="#D7D7D7" stroke-width="2"/>
                </svg>
              </Text>)}
          </SettingList>
        </TopBackground>
      </div>
    )
  }
}

export default Setting;