import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { getWomen, getCommentWoman } from '../../../../infra/api';
import WomanHeader from '../organisms/womanHeader';
import NextWomanButton from '../../presentational/atoms/nextWomanButton';
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 100%;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  text-align: center;
`

const NextWomanButtonCover = styled.div`
  margin: 18px 0;
`

const WithGirl = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  color: #252525;
  margin: 6px 0 26px 0;
  > span {
    color: #FF444F;
  }
`

const ImageWoman = styled.div`
  background-image: url(${props => props.url || ""});
  background-size: cover;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  margin: auto;
`
const PCodeText= styled.p`
  text-align: center;
  margin: 30px 0 5px 0;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.04em;
`
const PCodeNumber= styled.div`
  width: 260px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #252525;
  text-align: center;
  line-height: 48px;
`
const Pmessage= styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FF444F;
  margin: 66px 0 10px 0;
`
const BGmessage= styled.div`
  width: 100%;
  min-height: 69px;
  background: #FFEAEB;
  border-radius: 8px;
  & > p {
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    color: #252525;
    padding: 12px;
    text-align: center;
  }

`

function ReservationDone(props) {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [woman, setWoman] = useState({})
  const [comment, setComment] = useState({})
  
  useEffect(() => {
    getCommentWoman({id: location.state.woman_id}).then(async function (response) {
      await setComment(response.data)
    }).catch(function (response){
      console.log(response.data)
    });
  }, [location.state.woman_id]);

  useEffect(() => {
    getWomen(location.state.woman_id).then(async function (response) {
      await setWoman(response.data)
      setLoading(false)        
    }).catch(function (response){
      console.log(response.data)
    });
  }, [location.state.woman_id]);

  if(loading){
    return (
      <div></div>
    ) 
  }
  return (
    <div>
      <WomanHeader />
      <TopBackground>  
        <ImageWoman url={woman.main_image} />
        <WithGirl><span>{woman.nickname}</span>さんと飲む</WithGirl>
        <Title>予約が完了致しました</Title>
        <PCodeText>ご予約番号</PCodeText>
        <PCodeNumber>{location.state.reservation_code}</PCodeNumber>
        <Pmessage>{woman.nickname}さんからのコメント</Pmessage>
        <BGmessage>
          <p>{currentUser.nickname}様この度はご予約いただきありがとうございました。</p>
          <p>{comment}</p>
        </BGmessage>
        <NextWomanButtonCover>
          <NextWomanButton text="一覧へ戻る" />
        </NextWomanButtonCover>
      </TopBackground>
    </div>
  ) 
}

export default ReservationDone;