import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    maxWidth: '1250px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function SimpleAlertSuccess(props) {
  const classes = useStyles();

  if(props.showalert){
    return (
      <div className={classes.root}>
        <Alert severity='success'>{props.message}</Alert>
      </div>
    );
  } else {
    return (
      <div></div>
    );
  } 
}
