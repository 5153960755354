import React, { Component } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const GroupSearch = styled.div`
  margin: auto;
  max-width: 93%;
  ${mediaquery.desktop`
    max-width: 43%;
  `}
`
const GroupSearchComponent = styled.div`
  & > * {
    margin: 10px 3px !important;
    width: 41%;
  }
  & > :first-child {
    width: 42px;
  }
  ${mediaquery.desktop`
    display: flex;
    text-align: center
    & > p {
      line-height: 37px;
    }
  `}
`

const PGroupSearch = styled.div`
  ${mediaquery.desktop`
    line-height: 37px;
  `}
`

const TextFieldSearchGroup = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
})(TextField);

class SearchGroupField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromAge: '',
      toAge: '',
      fromHeight: '',
      toHeight: '',
    }
    this.handleChangeFromAge = this.handleChangeFromAge.bind(this)
    this.handleChangeToAge = this.handleChangeToAge.bind(this)
    this.handleChangeFromHeight = this.handleChangeFromHeight.bind(this)
    this.handleChangeToHeight = this.handleChangeToHeight.bind(this)
    this.keyPress = this.keyPress.bind(this)
  }

  handleChangeFromAge = event => this.setState({fromAge: event.target.value});
  handleChangeToAge = event => this.setState({toAge: event.target.value});
  handleChangeFromHeight = event => this.setState({fromHeight: event.target.value});
  handleChangeToHeight = event => this.setState({toHeight: event.target.value});

  keyPress = event => {
    if(event.key === 'Enter'){
      this.props.groupFieldSearch({ 'from_age': this.state.fromAge, 'to_age': this.state.toAge, 'from_height': this.state.fromHeight, 'to_height': this.state.toHeight })
    }
  };

  render() {
    if(this.props.showGroupSearch){
      return (
        <GroupSearch>
          <GroupSearchComponent>
            <PGroupSearch>年齢: </PGroupSearch>
            <TextFieldSearchGroup size="small" label="from" variant="outlined" onChange={this.handleChangeFromAge} onKeyPress={this.keyPress} value={this.state.fromAge}/>
            <TextFieldSearchGroup size="small" label="to" variant="outlined" onChange={this.handleChangeToAge} onKeyPress={this.keyPress} value={this.state.toAge}/>
          </GroupSearchComponent>
          <GroupSearchComponent>
            <PGroupSearch>身長: </PGroupSearch>
            <TextFieldSearchGroup size="small" label="from" variant="outlined" onChange={this.handleChangeFromHeight} onKeyPress={this.keyPress} value={this.state.fromHeight}/>
            <TextFieldSearchGroup size="small" label="to" variant="outlined" onChange={this.handleChangeToHeight} onKeyPress={this.keyPress} value={this.state.toHeight}/>
          </GroupSearchComponent>
        </GroupSearch>
      )
    }else{
      return (<div></div>)
    }
  }
};

export default SearchGroupField;
