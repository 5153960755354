import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ListCardPay from '../../presentational/molecules/listCardPay';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getListCard } from '../../../../infra/api';

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  height: 57px;
  left: 463px;
  top: 130px;

  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  text-align: center;
  letter-spacing: 0.04em;

  color: #252525;
`

class PointPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_card: [],
      loadding: true,
    };
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/pointBuy');
  };

  componentWillMount() {
    getListCard().then((response) => {
      this.setState({list_card: response.data, loadding: false})
    }).catch(function (response) {
      alert(response)
    })
  }

  render() {
    if(this.state.loadding) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <WomanHeader />
          <TopBackground>
            <BackButtonCover>
              <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
            </BackButtonCover>
            <Title>クレジットカード情報変更</Title>
            <ListCardPay list_card={this.state.list_card}/>
          </TopBackground>
        </div>
      )
    }
  }
}

export default PointPay;