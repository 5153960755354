import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { currentUser } from '../../../../infra/current_user';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';

const HeaderCover = styled.div`
  display: flex;
  height: 50px;
  padding: 12px 15px;
  box-sizing: border-box;
  box-shadow: 1px 2px 0px rgb(179 177 177 / 20%);
  background: #fff;
`

const Logo = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  color: #ff444f;
  text-decoraiton: none;
`

const HeaderMenuGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

const HeaderMenuIconCover = styled(Link)`
  color: #bbb;

  &:not(:first-of-type) {
    margin-left: 16px;
  }
`

// styleの記述を行う
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  listCenter: {
    textAlign: 'center',
  },
  iconButton: {
    padding: 0,
    marginLeft: 16,
    color: '#bbb',
  },
});

// 下記、ヘッダーのLink用にhandle関数を用意したが、Linkのラッピンだけで済んだためコメントアウト。
// const handleMessageClick = () => {
//   // 以下、「/woman/[props.woman.name]というように値を渡さなければいけない。」
//   this.props.history.push('/message');
// };

const HeaderDefault = () => {
  let head = (
    <React.Fragment>
      <HeaderMenuIconCover to="/message"><EmailOutlinedIcon /></HeaderMenuIconCover>
      <HeaderMenuIconCover to="/reservationHistory"><HistoryRoundedIcon /></HeaderMenuIconCover>
      <HeaderMenuIconCover to="/likes"><FavoriteBorderRoundedIcon /></HeaderMenuIconCover>
      <HeaderMenuIconCover to="/mypage"><AccountCircleTwoToneIcon /></HeaderMenuIconCover>
    </React.Fragment>
  )

  if(currentUser.sex === "store"){
    head = (
    <React.Fragment>
      <HeaderMenuIconCover to="/message"><EmailOutlinedIcon /></HeaderMenuIconCover>
      <HeaderMenuIconCover to="/reservationHistory"><HistoryRoundedIcon /></HeaderMenuIconCover>
      <HeaderMenuIconCover to="/myStore"><AccountCircleTwoToneIcon /></HeaderMenuIconCover>
    </React.Fragment>
    )
  }

  return head
}

const LogoLink = () => {
  if(currentUser.sex === "man"){
    return <Logo to="/woman">北新地モバイル</Logo>
  } else if(currentUser.sex === "woman"){
    return <Logo to="/man">北新地モバイル</Logo>
  } else if(currentUser.sex === "store"){
    return <Logo to="/users">北新地モバイル</Logo>
  }
  return ""
}

export default function SwipeableTemporaryDrawer() {
  // useStyles() を呼ぶと上記スタイリングが適応されるユニークなクラスネームが取得できる
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['北新地モバイル', '祇園モバイル', '神戸モバイル', '東京モバイル'].map((text, index) => (
          // 各コンポーネントにスタイルをあてる
          <ListItem button key={text} className={clsx(classes.listCenter)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <HeaderCover>
      <LogoLink />
      {/* 下記のように、ヘッダーのRoutingでは、handle関数を作るのではなく、Linkでラッピングするのが良いのだろうか？？ */}
      {/* <MenuIcon onClick={handleMessageClick} /> */}
      {/* 下記は、Linkによるスタイル変化のため、Linkでラッピングしない方が良いかもしれない。 */}
      <HeaderMenuGroup>
        <HeaderDefault/>
        {/* 下記topという記述は、Drawerを画面丈夫から引き出すために必要な文言。bottomの場合、画面下からDrawerが引き出される。 */}
        {['top'].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* 下記<MenuIcon />の記述を他のIconに変更すれば、Iconが変更される。 */}
            <IconButton onClick={toggleDrawer(anchor, true)} className={classes.iconButton}><MenuIcon /></IconButton>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </HeaderMenuGroup>
    </HeaderCover>
  );
}