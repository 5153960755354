import React, { useState } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import TextField from '@material-ui/core/TextField';
import { putUpdateCurrentUser } from '../../../../infra/api';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  ${mediaquery.desktop`
    flex-flow: unset;
  `}
`
const UserDescription = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #252525;
  margin: 25px 0;
  letter-spacing: 0.75px;
  word-break: break-word;
`

const UserInfoBody = styled.div`
  width: 50%;
  font-weight: 500;
  font-size: 16px;
  width: 89%;
  &:first-child{
    margin-right: 10%;
  }
  ${mediaquery.desktop`
    width: 45%;
  `}

`

const UserInfoContent = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > p:first-child{
    color: #A6A6A6;
    line-height: 32px;
  }
  > p {
    width: 35%;
    float: left;
    line-height: 32px;
  }
`
const EditProfile = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  cursor: pointer;
  color: #BBBBBB;
`

export default function StoreContentInfo(props) {
  const [inputname, setInputname] = useState(props.user.name);
  const [inputnickname, setInputnickname] = useState(props.user.nickname);
  const [inputstore_name, setInputstore_name] = useState(props.user.store_name);
  const [inputaddress, setInputaddress] = useState(props.user.address);
  const [inputdescription, setInputdescription] = useState(props.user.description);

  const [errorname, setErrorname] = useState('');
  const [errornickname, setErrornickname] = useState('');
  const [errorstore_name, setErrorstore_name] = useState('');
  const [erroraddress, setErroraddress] = useState('');
  const [errordescription, setErrordescription] = useState('');

  const handleUpdate = async() => {
    const store = { 'name': inputname, 'nickname': inputnickname, 'store_name': inputstore_name, 'address': inputaddress, 'description': inputdescription}
    await putUpdateCurrentUser({ store: store }).then( (response) => {
      props.handleChangeUser(response.data)
      props.handleHideEditProfile()
      props.handleShowAlert("プロフィールが保存されました。")
      setErrorname('')
      setErrornickname('')
      setErrorstore_name('')
      setErroraddress('')
      setErrordescription('')
    }).catch( (error) => {
      let { name, nickname, store_name, address, description } = error.response.data.error
      name = name === undefined ? '' : name.join(',')
      nickname = nickname === undefined ? '' : nickname.join(',')
      store_name = store_name === undefined ? '' : store_name.join(',')
      address = address === undefined ? '' : address.join(',')
      description = description === undefined ? '' : description.join(',')
      setErrorname(name)
      setErrornickname(nickname)
      setErrorstore_name(store_name)
      setErroraddress(address)
      setErrordescription(description)
    })
  }

  if(props.show_edit_profile){
    return (
      <div>
        <UserDescription>
          <TextField onChange={ (e) => {setInputdescription(e.target.value)}} fullWidth={true} multiline rows={4} defaultValue={inputdescription} label="解説"/>
          <FormHelperText error>{errordescription}</FormHelperText>
        </UserDescription>
        <UserInfo>
          <UserInfoBody>
            <UserInfoContent>
              <p>年齢</p>
              <FormControl >
                <Input
                  value={inputname}
                  onChange={ (e) => {setInputname(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorname}</FormHelperText>
              </FormControl>
            </UserInfoContent>
            <UserInfoContent>
              <p>居住地</p>
              <FormControl >
                <Input
                  value={inputnickname}
                  onChange={ (e) => {setInputnickname(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errornickname}</FormHelperText>
              </FormControl>
            </UserInfoContent>
            <UserInfoContent>
              <p>身長</p>
              <FormControl >
                <Input
                  value={inputstore_name}
                  onChange={ (e) => {setInputstore_name(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorstore_name}</FormHelperText>
              </FormControl>
            </UserInfoContent>
          </UserInfoBody>
          <UserInfoBody>
            <UserInfoContent>
              <p>体型</p>
              <FormControl >
                <Input
                  value={inputaddress}
                  onChange={ (e) => {setInputaddress(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{erroraddress}</FormHelperText>
              </FormControl>
            </UserInfoContent>
          </UserInfoBody>
        </UserInfo>
        <EditProfile onClick={handleUpdate}> 情報を保存する </EditProfile>
      </div>
    )
  } else {
    return (
      <div>
        <UserDescription>{props.user.description}</UserDescription>
        <UserInfo>
          <UserInfoBody>
            <UserInfoContent>
              <p>年齢</p>
              <p>{props.user.name}</p>
            </UserInfoContent>
            <UserInfoContent>
              <p>居住地</p>
              <p>{props.user.nickname}</p>
            </UserInfoContent>
            <UserInfoContent>
              <p>身長</p>
              <p>{props.user.store_name}</p>
            </UserInfoContent>
          </UserInfoBody>
          <UserInfoBody>
            <UserInfoContent>
              <p>体型</p>
              <p>{props.user.address}</p>
            </UserInfoContent>
          </UserInfoBody>
        </UserInfo>
        <EditProfile onClick={props.handleShowEditProfile}> プロフィール編集 </EditProfile>
      </div>
    )
  }  
}