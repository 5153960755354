import React, { Component } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import WomanHeader from '../organisms/womanHeader';
import { getAttendances, postAttendances } from '../../../../infra/api';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import getDayReservation from '../../presentational/atoms/getDayReservation';


const NextAttendanceButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  margin: auto;
  cursor: pointer
`

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const ReservationTable = styled.table`
  margin-top: 2em;
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  scroll-x: auto;

  > thead {
    > tr {
      display: flex;

      > th {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        ${mediaquery.desktop`
            width: 100%;
        `}
      }
    }
  }

  > tbody {
    > tr {
      display: flex;

      > td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        font-size: 13px;
        background: #FFFFFF;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        ${mediaquery.desktop`
            width: 100%;
            height: 100px;
        `}
        > select {
          border: none;
          height: 24px;
          ${mediaquery.desktop`
            height: 30px;
            width: 35%;
          `}
          &:focus{
            border: none;
            outline: unset;
          }
        }
      }
    }
  }
`

const NextPayButtonCover = styled.div`
  margin: 18px 0;
`

const TableParent = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const PAttendance = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #FF444F;
  margin: 28px 0 75px 0;
`

const Datefont = styled.th`
  fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif'
`

const reservationTime = [
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '23:59',
];

// function getDaysInMonth(year, month, day) {
//   var date = new Date(year, month, day);
//   var days = [];
//   while (date.getMonth() === month) {
//     days.push(new Date(date));
//     date.setDate(date.getDate() + 1);
//   }
//   return days;
// }

// function getAllDays() {
//   const date = (new Date()).getDate()
//   const month = (new Date()).getMonth()
//   const year = (new Date()).getFullYear() 
//   let all_days = [];

//   var date = new Date(year, month, day);
//   var days = [];
//   while (date.getMonth() === month) {
//     days.push(new Date(date));
//     date.setDate(date.getDate() + 1);
//   }
//   return days;

//   all_days = getDaysInMonth(year, month, date).concat(getDaysInMonth(year, month + 1, 1))

//   return all_days;
// }

const reservationDayList = getDayReservation().map((day, index) => {
  return(
    <React.Fragment key={index}>
      <Datefont>{`${day.getMonth() + 1}/${day.getDate()}(月)`}</Datefont>
    </React.Fragment>
  )
});

function SectionStartTime(props) {
  let result = (
    <React.Fragment>
      <option></option>
    </React.Fragment>
  )
  const attendances = props.times || []
  const size = attendances.length
  for (let i = 0; i < size; i++) {
    if(props.day.toDateString() !== attendances[i].day) continue;
    let date = new Date(attendances[i].start_section)
    result = (
      <React.Fragment>
        <option value={date}>{`${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`}</option>
      </React.Fragment>
    )
    break;
  }

  return result
}

function SectionEndTime(props) {
  let result = (
    <React.Fragment>
      <option></option>
    </React.Fragment>
  )
  const attendances = props.times || []
  const size = attendances.length

  for (let i = 0; i < size; i++) {
    if(props.day.toDateString() !== attendances[i].day) continue;
    let date = new Date(attendances[i].end_section)
    result = (
      <React.Fragment>
        <option value={date}>{`${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`}</option>
      </React.Fragment>
    )
    break;
  }

  return result
}

function checkDisableStart(day, times) {
  let result = ''
  const attendances = times || []
  const size = attendances.length
  for (let i = 0; i < size; i++) {
    if(day.toDateString() !== attendances[i].day) continue;
    result = 'disabled'
    break;
  }

  return result
}

function checkDisableEnd(day, times) {
  let result = ''
  const attendances = times || []
  const size = attendances.length
  for (let i = 0; i < size; i++) {
    if(day.toDateString() !== attendances[i].day) continue;
    result = 'disabled'
    break;
  }

  return result
}

function checkDisableTime (time_attendances, day, time_select){
  let reslut = ''
  for (let i = 0; i < time_attendances.length; i++) {
    if(time_attendances[i].day !== day.toDateString()) continue
    if(time_attendances[i].start_section === null) continue
    let time_temp = time_attendances[i].start_section
    time_temp.setHours(time_temp.getHours() + 2)
    if(time_select >= time_temp){
      time_temp.setHours(time_temp.getHours() - 2)
      continue
    }
    reslut = 'disabled'
    time_temp.setHours(time_temp.getHours() - 2)
    break;
  }
  return reslut
}

function checkDisableSelect(time_attendances, day){
  let reslut = ''
  for (let i = 0; i < time_attendances.length; i++) {
    if(time_attendances[i].day !== day.toDateString()) continue
    if(time_attendances[i].start_section === null){
      reslut = 'disabled'
      break;
    }
  }
  return reslut
}

class AttendanceSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      times: [],
      time_attendances: [],
      loading: true,
      showalert: false
    };
    this.setTimeStartAttendances = this.setTimeStartAttendances.bind(this)
    this.setTimeEndAttendances = this.setTimeEndAttendances.bind(this)
    this.handleSubmidAttendance = this.handleSubmidAttendance.bind(this)
    this.handleShowAlert = this.handleShowAlert.bind(this)
  }

  handleShowAlert (){
    this.setState({showalert: true})
    window.setTimeout(()=>{
      this.setState({showalert: false})
    },3000)
  }

  componentWillMount() {
    const _this = this
    let default_time_attendances = getDayReservation().map(day => ({'day': day.toDateString(),'start_section': null, 'end_section': null}))
    let time_response = []
    getAttendances().then(async function (response) {
      time_response = await response.data.map(attendance => ({'day': (new Date(attendance.start_section)).toDateString(),'start_section': attendance.start_section, 'end_section': attendance.end_section}))
      _this.setState({ times: time_response, loading: false})
      let size_default_time_attendances = default_time_attendances.length
      let size_time_response = time_response.length
      for (let i = 0; i < size_time_response; i++) {
        for(let j = 0; j < size_default_time_attendances; j++){
          if (time_response[i].day === default_time_attendances[j].day){
            default_time_attendances[j].start_section = new Date(time_response[i].start_section)
            default_time_attendances[j].end_section = new Date(time_response[i].end_section)
          }
        }
      }
      _this.setState({ time_attendances: default_time_attendances })
    }).catch(function (response){
      console.log(response.data)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/womanDetails');
  };

  setTimeStartAttendances = (e) => {
    const date = new Date(e.target.value)
    const day = date.toDateString()
    let time_attendances = this.state.time_attendances
    const size_time_attendances = time_attendances.length
    for(let j = 0; j < size_time_attendances; j++){
      if (time_attendances[j].day === day){
        time_attendances[j].start_section = date
      }
    }

    this.setState({ time_attendances: time_attendances })
  }

  setTimeEndAttendances = (e) => {
    const date = new Date(e.target.value)
    const day = date.toDateString()
    let time_attendances = this.state.time_attendances
    const size_time_attendances = time_attendances.length
    for(let j = 0; j < size_time_attendances; j++){
      if (time_attendances[j].day === day){
        time_attendances[j].end_section = date
      }
    }

    this.setState({ time_attendances: time_attendances })
  }

  handleSubmidAttendance = () => {
    const _this = this
    let time_response = []
    postAttendances({attendances: this.state.time_attendances}).then(async (response) => {
      time_response = await response.data.map(attendance => ({'day': (new Date(attendance.start_section)).toDateString(),'start_section': attendance.start_section, 'end_section': attendance.end_section}))
      _this.setState({ times: time_response})
      _this.handleShowAlert()
    }).catch(function (response) {
      console.log(response.data)
    }) 
  }

  render() {
    if(this.state.loading){
      return (<div></div>)
    } else {
      return (
        <div>
          <WomanHeader />
          <TopBackground>
            <BackButtonCover>
              <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
            </BackButtonCover>
            {/* <Title>時間を決める</Title> */}
              <TableParent>
                <ReservationTable>
                  <thead>
                    <tr>
                      {reservationDayList}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {getDayReservation().map((day, index) => {
                        return(
                          <React.Fragment key={index}>
                            <td> 
                              <select disabled={checkDisableStart(day, this.state.times)} onChange={this.setTimeStartAttendances} >
                                <SectionStartTime day={day} times={this.state.times} />
                                {
                                  reservationTime.map((time) => {
                                    return(
                                      <React.Fragment>
                                        <option value={new Date(`${day.toDateString()} ${time}`)}>{time}</option>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </select>
                              <p>~</p>
                              <select disabled={checkDisableSelect(this.state.time_attendances, day) || checkDisableEnd(day, this.state.times)} onChange={this.setTimeEndAttendances}>
                                <SectionEndTime day={day} times={this.state.times} />
                                {
                                  reservationTime.map((time) => {
                                    return(
                                      <React.Fragment>
                                        <option disabled={checkDisableTime(this.state.time_attendances, day, new Date(`${day.toDateString()} ${time}`))} value={new Date(`${day.toDateString()} ${time}`)}>{time}</option>
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </select>
                            </td>
                          </React.Fragment>
                        )
                      })}
                    </tr>
                  </tbody>
                </ReservationTable>
              </TableParent>
            <PAttendance>※確定後は原則変更不可とさせていただきます。</PAttendance>
            <NextPayButtonCover>
              <NextAttendanceButton onClick={this.handleSubmidAttendance}>次へ</NextAttendanceButton>
            </NextPayButtonCover>
          </TopBackground>
          <SimpleAlertSuccess message={"出勤スケジュールを正常に登録しました！"} showalert={this.state.showalert}/>
        </div>
      )
    }
  }
}

export default AttendanceSchedule;