import React, { useState } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import TextField from '@material-ui/core/TextField';
import { putUpdateCurrentUser } from '../../../../infra/api';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { currentUser } from '../../../../infra/current_user';

const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  ${mediaquery.desktop`
    flex-flow: unset;
  `}
`
const UserDescription = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #252525;
  margin: 25px 0;
  letter-spacing: 0.75px;
  word-break: break-word;
`

const UserInfoBody = styled.div`
  width: 50%;
  font-weight: 500;
  font-size: 16px;
  width: 89%;
  &:first-child{
    margin-right: 10%;
  }
  ${mediaquery.desktop`
    width: 45%;
  `}

`

const UserInfoContent = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > p:first-child{
    color: #A6A6A6;
    line-height: 32px;
  }
  > p {
    width: 35%;
    float: left;
    line-height: 32px;
  }
`
const EditProfile = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  cursor: pointer;
  color: #BBBBBB;
`

export default function UserContentInfo(props) {
  const [inputage, setInputage] = useState(props.user.age);
  const [inputarea, setInputarea] = useState(props.user.area);
  const [inputheight, setInputheight] = useState(props.user.height);
  const [inputfigure, setInputfigure] = useState(props.user.figure);
  const [inputjob, setInputjob] = useState(props.user.job);
  const [inputdescription, setInputdescription] = useState(props.user.description);
  const [storeId, setStoreId] = useState(props.user.store_id);

  const [errorage, setErrorage] = useState('');
  const [errorarea, setErrorarea] = useState('');
  const [errorheight, setErrorheight] = useState('');
  const [errorfigure, setErrorfigure] = useState('');
  const [errorjob, setErrorjob] = useState('');
  const [errordescription, setErrordescription] = useState('');

  const handleUpdate = async() => {
    let user = { 'age': inputage, 'area': inputarea, 'height': inputheight, 'figure': inputfigure, 'job': inputjob, 'description': inputdescription }
    if(currentUser.sex === 'woman'){
      user = { 'age': inputage, 'area': inputarea, 'height': inputheight, 'figure': inputfigure, 'job': inputjob, 'description': inputdescription, 'store_id': storeId }
    }
    await putUpdateCurrentUser({ user: user }).then( (response) => {
      props.handleChangeUser(response.data)
      props.handleHideEditProfile()
      props.handleShowAlert("プロフィールが保存されました。")
      setErrorage('')
      setErrorarea('')
      setErrorheight('')
      setErrorfigure('')
      setErrorjob('')
      setErrordescription('')
    }).catch( (error) => {
      let { job, area, age, height, figure, description } = error.response.data.error
      job = job === undefined ? '' : job.join(',')
      area = area === undefined ? '' : area.join(',')
      age = age === undefined ? '' : age.join(',')
      height = height === undefined ? '' : height.join(',')
      figure = figure === undefined ? '' : figure.join(',')
      description = description === undefined ? '' : description.join(',')
      setErrorage(age)
      setErrorarea(area)
      setErrorheight(height)
      setErrorfigure(figure)
      setErrorjob(job)
      setErrordescription(description)
    })
  }

  const InputReservationFee = () => {
    if(currentUser.sex === 'woman'){
      return(
        <React.Fragment>
          <UserInfoContent>
            <p>店舗</p>
            <FormControl>
              <Select
                value={storeId}
                onChange={(e) => { setStoreId(e.target.value) }}
              >
                <MenuItem value={storeId}>{props.user.store_name}</MenuItem>
                {props.stores.filter(item => item.id !== props.user.store_id).map((store) => {
                  return (<MenuItem value={store.id}>{store.store_name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </UserInfoContent>
          <UserInfoContent>
              <p>出身地</p>
              <FormControl >
                <Input
                  value={inputjob}
                  onChange={ (e) => {setInputjob(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorjob}</FormHelperText>
              </FormControl>
            </UserInfoContent>
        </React.Fragment>
      )
    } else {
      return(<div></div>)
    }
  }

  const DisplayReservationFee = () => {
    if(currentUser.sex === 'woman'){
      return(
        <React.Fragment>
          <UserInfoContent>
            <p>予約料</p>
            <p>{props.user.reservation_fee}</p>
          </UserInfoContent>
          <UserInfoContent>
            <p>店舗</p>
            <p>{props.user.store_name}</p>
          </UserInfoContent>
          <UserInfoContent>
            <p>出身地</p>
            <p>{props.user.job}</p>
          </UserInfoContent>
        </React.Fragment>
      )
    } else {
      return(<div></div>)
    }
  }

  if(props.show_edit_profile){
    return (
      <div>
        <UserDescription>
          <TextField onChange={ (e) => {setInputdescription(e.target.value)}} fullWidth={true} multiline rows={4} defaultValue={inputdescription} label="解説"/>
          <FormHelperText error>{errordescription}</FormHelperText>
        </UserDescription>
        <UserInfo>
          <UserInfoBody>
            <UserInfoContent>
              <p>年齢</p>
              <FormControl >
                <Input
                  value={inputage}
                  type='number'
                  onChange={ (e) => {setInputage(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorage}</FormHelperText>
              </FormControl>
            </UserInfoContent>
            <UserInfoContent>
              <p>居住地</p>
              <FormControl >
                <Input
                  value={inputarea}
                  onChange={ (e) => {setInputarea(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorarea}</FormHelperText>
              </FormControl>
            </UserInfoContent>
            <UserInfoContent>
              <p>身長</p>
              <FormControl >
                <Input
                  value={inputheight}
                  type='number'
                  onChange={ (e) => {setInputheight(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorheight}</FormHelperText>
              </FormControl>
            </UserInfoContent>
            <InputReservationFee/>
          </UserInfoBody>
          <UserInfoBody>
            <UserInfoContent>
              <p>体型</p>
              <FormControl >
                <Input
                  value={inputfigure}
                  onChange={ (e) => {setInputfigure(e.target.value)}}
                  aria-describedby="component-error-text"
                />
                <FormHelperText error>{errorfigure}</FormHelperText>
              </FormControl>
            </UserInfoContent>
          </UserInfoBody>
        </UserInfo>
        <EditProfile onClick={handleUpdate}> 情報を保存する </EditProfile>
      </div>
    )
  } else {
    return (
      <div>
        <UserDescription>{props.user.description}</UserDescription>
        <UserInfo>
          <UserInfoBody>
            <UserInfoContent>
              <p>年齢</p>
              <p>{props.user.age}歳</p>
            </UserInfoContent>
            <UserInfoContent>
              <p>居住地</p>
              <p>{props.user.area}</p>
            </UserInfoContent>
            <UserInfoContent>
              <p>身長</p>
              <p>{props.user.height}cm</p>
            </UserInfoContent>
            <DisplayReservationFee/>
          </UserInfoBody>
          <UserInfoBody>
            <UserInfoContent>
              <p>体型</p>
              <p>{props.user.figure}</p>
            </UserInfoContent>
          </UserInfoBody>
        </UserInfo>
        <EditProfile onClick={props.handleShowEditProfile}> プロフィール編集 </EditProfile>
      </div>
    )
  }  
}