import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getCheckValidUserSetting, getCheckEmailOrTel, getCheckValidByEmail, getUserSettingByEmail, setTemporarilies, getTemporarilies } from '../../../../infra/api';
import ConfirmSettingUser from './confirmSettingUser'
import { currentUser } from '../../../../infra/current_user';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'
import firebase from '../../../../infra/my_firebase';

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   margin: 0;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
//   text-align: center;
// `

const AccountDataCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px auto;
`

const AccountData = styled.dl`
  margin-top: 1em;
  margin-bottom: 22px;

  > dt {
    margin-bottom: 6px;
  }
`

const AccountDataInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
`

const ButtonCover = styled.div`
  margin: 18px 0;
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
`
const Error = styled.p`
  margin: auto;
  text-align: left;
  width: 345px;
  color: red;
  font-size: 10px;
  margin-top: 5px;
`

const Text = styled.dd`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

class SettingAccount extends Component {
  constructor(props) {
    super(props);
    // リロードするとtel・mailの値が消えるので、消えないようにしっかりと保存する。
    this.state = {
      number_phone: this.convertPhoneNumberDisplay(currentUser.tel),
      mail_input: currentUser.email,
      email_error: '',
      tel_error: '',
      verificationId: '',
      showalert: false,
      disabled: false,
    };
    this.convertPhoneNumber = this.convertPhoneNumber.bind(this)
    this.handleSettiingAccountChange = this.handleSettiingAccountChange.bind(this)
    this.handleSettiingAccountChangeTel = this.handleSettiingAccountChangeTel.bind(this)
    this.handleSettiingAccountChangeEmail = this.handleSettiingAccountChangeEmail.bind(this)
    this.convertPhoneNumberDisplay = this.convertPhoneNumberDisplay.bind(this)
    this.handleShowAlert = this.handleShowAlert.bind(this)
    this.handleReSetVerificationId = this.handleReSetVerificationId.bind(this)
  }

   componentDidMount() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.setState({ disabled: true })
      var user = firebase.auth().currentUser;
      var oldEmail = this.state.mail_input
      var newEmail = ''
      var newPhone = ''
      getTemporarilies().then(async function (response) {
        newEmail = response.data.email
        newPhone = response.data.tel
      }).catch(function (error) { console.log(error) });
      var credential = firebase.auth.EmailAuthProvider.credentialWithLink(oldEmail, window.location.href);
      user.reauthenticateWithCredential(credential).then(function() {
        user.updateEmail(newEmail).then(async function() {
          getUserSettingByEmail({tel: newPhone, email: newEmail}).then(async function(response) {
            const { token, user } = response.data
            if (token) await localStorage.setItem('token', token);
            if (user) await localStorage.setItem('user', JSON.stringify(user));
            alert('アカウント情報が変更されました。再度変更した情報でログインしてください')
            await firebase.auth().signOut().then(async function() {
              localStorage.setItem('token', '');
              localStorage.setItem('user', '');
              window.location.reload();
            })            
          }).catch(async function(error) {
            alert('アカウント情報の変更に、失敗いたしました。')
            console.log(error)
          }); 
        }).catch(function(error) { console.log(error) });      
      }).catch(function(error) { console.log(error) });
    }
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  handleShowAlert (){
    this.setState({showalert: true})
    window.setTimeout(()=>{
      this.setState({showalert: false})
    },3000)
  }

  handleReSetVerificationId (){
    this.setState({verificationId: ''})
    this.setState({email_error: ''})
    this.setState({tel_error: ''})
  }

  convertPhoneNumber(phone_number) {
    phone_number = phone_number.split(' ').join('').toString().replace(/^0/, '+81');
    return phone_number;
  }

  convertPhoneNumberDisplay(phone_number) {
    if(`${phone_number[0]}${phone_number[1]}${phone_number[2]}` === '+81'){
      phone_number = phone_number.split(' ').join('').toString().replace(/^\+\d\d/, '0');
    }
    return phone_number;
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/setting');
  };

  handleTelInputChange = (e) => {
    e.preventDefault();
    this.setState({ 
        tel: e.target.value,
    });
  };

  handleMailInputChange = (e) => {
    e.preventDefault();
    this.setState({ 
        mail: e.target.value,
    });
  };

  handleSettiingAccountChange() {
    const _this = this
    getCheckEmailOrTel({ email: currentUser.email }).then(async () => {
      _this.handleSettiingAccountChangeEmail()
    }).catch((response) => {
      _this.handleSettiingAccountChangeTel()
      console.log(response)
    })
  }

  handleSettiingAccountChangeEmail = () => {
    const _this = this
    var newEmail = _this.state.mail_input;
    var newPhone = this.convertPhoneNumber(this.state.number_phone);
    getCheckValidByEmail({tel: newPhone, email: newEmail}).then(async () => {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true
      };
      firebase.auth().sendSignInLinkToEmail(newEmail, actionCodeSettings).then(function() {})
      var res = window.confirm('テストメールをお送りいたしました、メールは届きましたか？');
      if( res === false ) {
        alert('入力したメールアドレスをもう一度ご確認ください')
        window.location.reload();
      }
      firebase.auth().sendSignInLinkToEmail(currentUser.email, actionCodeSettings).then(function() {
        setTemporarilies({ tel: newPhone, email: newEmail }).then(function () {}).catch(function (error) { console.log(error) });
        _this.props.history.push({
          pathname: '/mailRegistrationDone',
          state: { mail_adress: currentUser.email }
        })
      }).catch(function(error) { console.log(error) });
    }).catch(function(response) {
      const {tel, email} = response.response.data.error
      _this.setState({tel_error: tel, email_error: email })    
    });
  };

  handleSettiingAccountChangeTel = () => {
    if(currentUser.tel === this.convertPhoneNumber(this.state.number_phone)) return;
    getCheckValidUserSetting({tel: this.convertPhoneNumber(this.state.number_phone), email: this.state.mail_input}).then(async () => {
      if (document.getElementById('recaptcha-container') !== null){
        window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible'
        });
      }
      new firebase.auth.PhoneAuthProvider().verifyPhoneNumber(this.convertPhoneNumber(this.state.number_phone), window.recaptchaVerifier).then((verificationId) => {
        this.setState({verificationId: verificationId})
      }).catch((error) => {
        alert(error)
      })
    }).catch((response) => {
      const {tel, email} = response.response.data.error
      this.setState({tel_error: tel, email_error: email })
    })
  };

  render() {
    if(this.isEmpty(this.state.verificationId)){
      return (
        <div>
          <WomanHeader />
          <TopBackground>
            <BackButtonCover>
              <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
            </BackButtonCover>
            {/* <Title>アカウント情報変更</Title> */}
            <AccountDataCover>
              <AccountData>
                <Text>電話番号</Text>
                <dd><AccountDataInput type="text" placeholder="電話番号" value={this.state.number_phone} onChange={ (e) => this.setState({ number_phone: this.convertPhoneNumber(e.target.value) })}></AccountDataInput></dd>
                <Error>{this.state.tel_error}</Error>
              </AccountData>
              <AccountData>
                <Text>メールアドレス</Text>
                <dd><AccountDataInput type="text" value={this.state.mail_input} onChange={ (e) => this.setState({ mail_input: e.target.value })}></AccountDataInput></dd>
                <Error>{this.state.email_error}</Error>
              </AccountData>
            </AccountDataCover>
            <ButtonCover>
              <Button onClick={this.handleSettiingAccountChange} disabled={this.state.disabled}>変更する</Button>
            </ButtonCover>
          </TopBackground>
          <div id="recaptcha-container"></div>
          <SimpleAlertSuccess message={"出勤スケジュールを正常に登録しました！"} showalert={this.state.showalert}/>
        </div>
      )
    } else{
      return (
        <ConfirmSettingUser handleReSetVerificationId={this.handleReSetVerificationId} handleShowAlert={this.handleShowAlert} email={this.state.mail_input} phoneNumber={this.state.number_phone} verificationId={this.state.verificationId} />
      )
    }
  }
}

export default SettingAccount;
