import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getAttendances, getWomen, getCurrentUser } from '../../../../infra/api';
import getDayReservation from '../../presentational/atoms/getDayReservation';
import ReservationTimeListForStore from '../.././presentational/molecules/reservationTimeListForStore'

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
  max-width: 605px;
  margin: auto;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  width: 100%;
  // 下記marginは、reset.cssでリセットする。
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  text-align: center
`

const ReservationTable = styled.table`
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  scroll-x: auto;

  > thead {
    > tr {
      display: flex;

      > th {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 47px;
        height: 40px;
        font-size: 15px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        &:nth-child(1) {
          position: absolute;
          z-index: 10;
          background: #f8f7f3;
        }
        &:nth-child(2){
          margin-left: 47px;
        }
        &:not(:first-of-type) {
          width: 80px;
          height: 40px;
        }
      }
    }
  }

  > tbody {
    > tr {
      display: flex;

      > td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 47px;
        height: 40px;
        font-size: 13px;
        background: #FFFFFF;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        &:nth-child(2){
          margin-left: 47px;
        }
        &:not(:first-of-type) {
          width: 80px;
          height: 40px;
        }
      }
    }
  }
`

const WithGirl = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  color: #252525;
  margin: 6px 0 26px 0;
  > span {
    color: #FF444F;
  }
`

const TableParent = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 25px;
`
const ImageWoman = styled.div`
  background-image: url(${props => props.url || ""});
  background-size: cover;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  margin: auto;
`

const reservationDayList = getDayReservation().map((day, index) => {
  return(
    <React.Fragment key={index}>
      <th>{`${day.getMonth() + 1}/${day.getDate()}(月)`}</th>
    </React.Fragment>
  )
});

class ReservationDrink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_attendances: [],
      loading: true,
      sections: [],
      woman: {},
      current_user: {},
      continue_pay: false,
      isOpen: false,
    };
    this.handleSetSections = this.handleSetSections.bind(this)
    this.handleClosePopUp = this.handleClosePopUp.bind(this)
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    this.toggleContainer = React.createRef();
  }

  handleSetSections = (sections) =>{
    this.setState({sections: sections})
  }

  onClickOutsideHandler(event) {
    if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }
  
  handleClosePopUp = () => {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  async componentWillMount() {
    const _this = this

    await getWomen(this.props.location.state.id).then(async function (response) {
      await _this.setState({ woman: response.data })        
    }).catch(function (response){
      console.log(response.data)
    })

    await getCurrentUser().then(async function (response) {
      await _this.setState({ current_user: response.data, loading: false })        
    }).catch(function (response){
      console.log(response.data)
    })

    let time_response = []
    await getAttendances(this.props.location.state.id).then(async function (response) {
      time_response = await response.data.map(attendance => ({'reservations': attendance.reservations, 'id': attendance.id,'day': (new Date(attendance.start_section)).toDateString(),'start_section': attendance.start_section, 'end_section': attendance.end_section}))     
      _this.setState({ time_attendances: time_response, loading: false })
    }).catch(function (response){
      console.log(response.data)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/womanDetails');
  };

  handleNextPay = () => {
    if(this.state.sections.length === 0) return;
    this.setState({continue_pay: true})
  }

  render() {
    if(this.state.loading){
      return (<div></div>)
    } else {
      return (
        <div>
          <WomanHeader />
          <TopBackground>  
            <ImageWoman url={this.state.woman.main_image} />
            <WithGirl><span>{this.state.woman.nickname}</span>さんと飲む</WithGirl>
            <BackButtonCover>
              <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
            </BackButtonCover>
            <Title>時間を決める</Title>
              <TableParent>
                <ReservationTable>
                  <thead>
                    <tr>
                      <th></th>
                      {reservationDayList}
                    </tr>
                  </thead>
                  <tbody>
                    <ReservationTimeListForStore sections={this.state.sections} handleSetSections={this.handleSetSections} time_attendances={this.state.time_attendances}/>
                  </tbody>
                </ReservationTable>
              </TableParent>
          </TopBackground>
        </div>
      )
    }
  }
}

export default ReservationDrink;