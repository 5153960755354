import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import { Link } from 'react-router-dom';

const NextPayButton = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  margin: auto;
  cursor: pointer;
`

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #252525;
  margin-bottom: 26px;
`

const NextPayButtonCover = styled.div`
  margin: 60px 0 18px 0;
`

const PointForm = styled.div`
  max-width: 400px;
  margin: auto;
`
const Ptitle = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #252525;
`
const PointDone = styled.div`
  width: 260px;
  height: 48px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 6px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 48px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #FF444F;
`

class PointBuyDone extends Component {

  handleBackButtonClick = () => {
    this.props.history.push("/myPage");
  };

  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <Title>決済が完了いたしました</Title>
          <PointForm>
            <Ptitle>現在の所有ポイント</Ptitle>
            <PointDone>{this.props.location.state.point} p</PointDone>
            <NextPayButtonCover>
              <NextPayButton to="/myPage">戻る</NextPayButton>
            </NextPayButtonCover>
          </PointForm>
        </TopBackground>
      </div>
    )
  }
}

export default PointBuyDone;