import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styled from 'styled-components';
import firebase from '../../../../infra/my_firebase';
import { historyReload } from '../../../.././HistoryReload';

import { makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const HeaderCover = styled.div`
  display: flex;
  height: 50px;
  padding: 12px 15px;
  box-sizing: border-box;
`

const Logo = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  color: #ff444f;
  text-decoraiton: none;
`

const LinkLogin = styled(Link)`
  width: 100%;
  text-align: center;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  width: 126px;
  height: 40px;
  left: 1104px;
  top: 44px;
  background: #FF444F;
  border-radius: 40px;
  line-height: 40px;
  display: block;
  margin: auto;
`

const HeaderMenuGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

// 下記「(theme) => ({~を追記することにより、useStylesの中でthemeが扱えるようになる。」
const useStyles = makeStyles((theme) => ({
  list_pc: {
    '@media (min-width: 1280px)': {
      display: 'flex',
    }
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
    color: '#fff',
    background: '#ff444f',
  },
  listCenter: {
    textAlign: 'center',
  },
  menuIconColor: {
    color: '#ff444f',
  },
}));


function logOut(){
  firebase.auth().signOut().then(async function() {
    localStorage.setItem('token', '');
    localStorage.setItem('user', '');
    historyReload.push("/");
  })
}

export default function SwipeableTemporaryDrawer() {
  // useStyles() を呼ぶと上記スタイリングが適応されるユニークなクラスネームが取得できる
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [isLoggedin, setIsLoggedin] = React.useState(false);

  firebase.auth().onAuthStateChanged(user =>  setIsLoggedin( user ? true : false ));

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const Authbutton = (props) => {
    let result =  (<List class={classes.list_pc}>
                    <ListItem button className={clsx(props.classes.listCenter)}>
                      <LinkLogin to="/login">ログイン</LinkLogin>
                    </ListItem>
                </List>)
    if (props.userLoggedin) {
        result = (<List>
                  <ListItem button key="Logout" className={clsx(props.classes.listCenter)}>
                    <LinkLogin to="/#" onClick={logOut}>ログアウト</LinkLogin>
                  </ListItem>
                </List>)
      }
    return result
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['ABOUT', 'CURRENT', 'MISSION', 'PROMOTION'].map((text, index) => (
          // 各コンポーネントにスタイルをあてる
          <ListItem button key={text} className={clsx(classes.listCenter)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Authbutton classes={classes} userLoggedin={isLoggedin}/>
    </div>
  );

  const HeaderResponsive = () => {
    if(window.innerWidth >= 1280){
      return <div style={{display: 'flex'}}>
        <List class={classes.list_pc}>
          {['ABOUT', 'CURRENT', 'MISSION', 'PROMOTION'].map((text, index) => (
            // 各コンポーネントにスタイルをあてる
            <a style={{display: 'inherit'}} href={"#"+text.toLowerCase()}>
              <ListItem button key={text} className={clsx(classes.listCenter)}>
                <ListItemText primary={text} />
              </ListItem>
            </a>
          ))}
        </List>
        <Divider />
        <Authbutton classes={classes} userLoggedin={isLoggedin}/>
      </div>
    } else {
      return (['top'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* 下記<MenuIcon />の記述を他のIconに変更すれば、Iconが変更される。 */}
          <IconButton onClick={toggleDrawer(anchor, true)}><MenuIcon className={clsx(classes.menuIconColor)} /></IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      )))
    }
  }

  return (
    <HeaderCover>
      <Logo to="/top">モバイルクラブ</Logo>
      <HeaderMenuGroup>
        {/* 下記topという記述は、Drawerを画面丈夫から引き出すために必要な文言。bottomの場合、画面下からDrawerが引き出される。 */}
        <HeaderResponsive/>
      </HeaderMenuGroup>
    </HeaderCover>
  );
}


// class Header extends Component {
//   render() {
//     return (
//       <header>
//         <a href="/">モバイルクラブ</a>
//       </header>
//     )
//   }
// }

// export default Header
