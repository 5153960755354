import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mediaquery from '../../../../assets/styles/variable';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import UserContentDetail from './userContentDetail';


const NextMessageLinkCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;

  > span {
    height: 23px;
  }

  ${mediaquery.desktop`
    height: 56px;
    border-radius: 30px;
    margin: 5px;
    background: #FFFFFF;
    border: 2px solid #FF444F;
    box-sizing: border-box;
  `}
`

const NextMessageLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;

  ${mediaquery.desktop`
    width: 50%;
  `}
`

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif'
  },
  paper: {
    padding: '35px 15px 90px',
    '@media (min-width: 451px)': {
      padding: '40px 15px 50px'
    }
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    border: '1px solid #ff444f',
    boxSizing: 'border-box',
    width: '100%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0',
    '@media (min-width: 451px)': {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  root: {
    minHeight: 0,
    height: '56px',
    width: '100%',
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    backgroundColor: "#ff444f",
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
    height: '50px',
    margin: 'auto 0',
      border: `1px solid #ff878e`,
    },
  },
  bottomAppBarIcon: {
    '& svg': {
      margin: 0,
    },
    '@media (min-width: 451px)': {
      flexFlow: 'row',
      width: '50%'
    }
  },
}));

const MenuBTGroup = styled.div`
  display: none;
  ${mediaquery.desktop`
    display: flex;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 3em;
  `}
`

export default function BottomAppBarMan(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          {props.user.nickname}
        </Typography>
        <MenuBTGroup>
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <p><EmailOutlinedIcon /></p>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </MenuBTGroup>
        <UserContentDetail show_edit_profile={false} user={props.user}/>
      </Paper>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <Divider orientation="vertical" flexItem />
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <span><EmailOutlinedIcon /></span>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}