import React, { Component } from 'react';
import styled from 'styled-components';
import firebase from '../../../../infra/my_firebase';
import { signUp, signIn, checkUserByEmail, setTemporarilies, getTemporarilies } from '../../../../infra/api';
import { Link, } from 'react-router-dom';

const LoginBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  text-align: center;
  background-color: #f8f7f3;
`

const OtherText = styled(Link)`
  color: #ff444f;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  &:last-of-type {
    margin-top: 12px;
  }
`

const Title = styled.h1`
  margin-bottom: 20px;
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const LoginFormGroup = styled.dl`
  > dt {
    font-size: 28px;
    font-weight: bold;
  }

  > dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`

const LoginForm = styled.form`
  width: 100%;
`

const LoginFormTable = styled.table`
  display: block;

  > td {
    display: block;

    &:last-of-type {
      margin-top: 26px;
    }
  }
`

const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  cursor: pointer;
`

const LoginFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`

const LoginButtonCover = styled.div`
  margin: 18px 0;
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

class mailRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail_adress: '',
      disabled: false,
      is_signup: false,
    };
    this.toggleSignInEmail = this.toggleSignInEmail.bind(this)
    this.handleMailLogin = this.handleMailLogin.bind(this)
  }

  componentDidMount() {
    const _this = this
  if(_this.props.location.state != null){
      _this.setState({ mail_adress: _this.props.location.state.user.email})
    }
  }

  async handleMailLogin(e) {
    e.preventDefault();
    const _this = this
    let signUpUser = {}
    let is_check = false

    if(_this.props.location.state != null){
      signUpUser = _this.props.location.state.user
      //メールアドレスからログインした人にはフラグを立てる
      signUpUser['email_flg'] = true
      _this.setState({is_signup: true})
      await setTemporarilies({ tel: signUpUser.tel, name: signUpUser.name, nickname: signUpUser.nickname, company: signUpUser.company, position: signUpUser.position, live: signUpUser.live, card_company: signUpUser.card_company, card_branch: signUpUser.card_branch, card_type: signUpUser.card_type, card_number: signUpUser.card_number, card_name: signUpUser.card_name, store_id: signUpUser.store_id, email: signUpUser.email, email_flg: signUpUser.email_flg, sex:  _this.props.location.state.sex}).then(function () {
      }).catch(function (error) {
        console.log(error)
      });
    }

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      _this.setState({ disabled: true })
      is_check = true 
      firebase.auth().signInWithEmailLink(_this.state.mail_adress, window.location.href).then(function(result) {
      }).catch(function(error) {
        console.log(error);
        alert('メールアドレスが間違っています')
        window.location.reload();
      });
    }
    
    firebase.auth().onAuthStateChanged(async function(loginUser) {
      if(loginUser){
        await getTemporarilies().then(async function (response) {
          await signUp(response.data.sex, { user: response.data }).then(function () {
          }).catch(function (error) {
            console.log(error)
          });
        }).catch(function (error) {
          console.log(error)
        });
        const idToken = await loginUser.getIdToken(/* forceRefresh */ true).catch(() => '');
        if (!idToken) return '';
        await signIn(idToken).then(async function(response) {
          const { token, user } = response.data
          if (token) await localStorage.setItem('token', token);
          if (user) await localStorage.setItem('user', JSON.stringify(user));
          const _path = user.sex === "store" ? "users" : (user.sex === "man" ? "woman" : "man")
          _this.props.history.push(`/${_path}`);
          window.location.reload();
        }).catch(async function (response) {
          alert(response)
          alert('このメールアドレスは見つかりません。再度メールアドレスをご確認の上ログインしてください。')
          await firebase.auth().signOut().then(async function() {
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            window.location.reload();
            _this.props.history.push("/");
          })
        })
      }
    })

    if(!is_check){
      _this.toggleSignInEmail(_this.state.mail_adress)
    }
  }

  async toggleSignInEmail(mail_adress){
    const _this = this
    _this.setState({ disabled: true })
    let checkUser  = false
    await checkUserByEmail({'email': mail_adress}).then(async function (response) {
      checkUser = true
    }).catch(function(error) {
    });
    if(_this.state.is_signup){
      checkUser = true
    }

    if(checkUser){
      if (firebase.auth().currentUser) {
        await firebase.auth().signOut().then(async function() {
          localStorage.setItem('token', '');
          localStorage.setItem('user', '');
          window.location.reload();
          _this.props.history.push("/");
        })
      }else{
        var actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        };
        firebase.auth().sendSignInLinkToEmail(mail_adress, actionCodeSettings).then(function() {
          _this.setState({ disabled: false })    
          _this.props.history.push({
            pathname: '/mailRegistrationDone',
            state: { mail_adress: _this.state.mail_adress }
          })   
        }).catch(function(error) {
          console.log(error);
        });
      }
    }else{
      alert('このメールアドレスは見つかりません')
      window.location.reload();
    }
  }

  render() {
    return (
    <div className="App">
      <LoginBackground>
      {/* loginButton.js <AlertError errors={this.state.errors_login}></AlertError>*/}
        <Title>北新地モバイル</Title>
        {/* loginForm.js */}
        <LoginFormGroup>
            <Text>メール認証</Text>
            <dd>
                <LoginForm>
                    <LoginFormTable>
                        <td><LoginFormInput type="text" name="メールアドレス" placeholder="メールアドレス" onKeyPress={this.keyPress} value={this.state.mail_adress} onChange={ (e) => this.setState({ mail_adress: e.target.value }) }></LoginFormInput></td>
                        <div id="recaptcha-container"></div>
                    </LoginFormTable>
                </LoginForm>
            </dd>
        </LoginFormGroup>
        {/* loginButton.js */}
        <LoginButtonCover>
          <ButtonStyle onClick={this.handleMailLogin} disabled={this.state.disabled}>認証</ButtonStyle>
        </LoginButtonCover>
          {/* 新規登録.js */}
          <OtherText to="/registration">新規会員登録はこちら</OtherText>
          <OtherText to="/login">電話番号でのログインはこちら</OtherText>
      </LoginBackground>
    </div>
    )
  }
};

export default mailRegistration;
