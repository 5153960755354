import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '88%',
    marginTop: '10px',
    margin: '0 auto',
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
      maxWidth: '345px',
    },
    '& .MuiOutlinedInput-adornedStart': {
        height: '48px',
        backgroundColor: '#fff',
    },
    '& .MuiInputAdornment-positionStart': {
        color: '#d7d7d7',
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#d7d7d7 !important",
  },
}));

const BGSearch = styled.div`
  width: 49px;
  height: 44px;
  margin-left: -12px;
  text-align: center;
  line-height: 58px;
  cursor: pointer;
`

export default function BasicTextFields(props) {
  const classes = useStyles();
  const [key, setKey] = useState('');

  const handleChange = event => setKey(event.target.value);

  const handleSearch = () => {
    props.groupFieldSearch({'search': key})
  };

  const keyPress = event => {
    if(event.key === 'Enter'){
      handleSearch(event) 
    }
  };

  return (
    <div className={classes.root} noValidate autoComplete="off">
      <TextField onChange={handleChange}  onKeyPress={keyPress} id="outlined-basic" label="" variant="outlined" InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BGSearch onClick={handleSearch}>
                <SearchRoundedIcon />
              </BGSearch>
            </InputAdornment>
          ),
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }} />
    </div>
  );
}