import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const CreditCard = styled.div`
  width: 345px;
  height: 97px;
  margin: 0 auto;
  padding: 15px;
  background: #fff;
  border: 2px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
	margin-top: 5px;
	cursor: pointer;
`

const CreditCardAdd = styled.div`
  width: 345px;
  height: 51px;
  margin: 0 auto;
  padding: 15px;
  background: #FFFFFF;
  border: 2px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #252525;
	margin-top: 20px;

  & > span{
    float: right;
    color: #bbbbbb;
    font-size: 21px;
    line-height: 15px;
  }
`
const CardTitle = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.04em;
  color: #252525;
`
const CardInfo = styled.dd`
  float: left;
  line-height: 19px;
  margin-top: 7px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #252525;
`

class ListCardPay extends Component {
  // Local State
  constructor(props) {
    super(props);
    this.state = {list_card: props.list_card };
    this.handleSelectCardPayment = this.handleSelectCardPayment.bind(this)
  }

  handleSelectCardPayment = (card_id, last4, brand) =>{
    this.props.history.push({
			pathname: '/pointBuyContents',
			state: { card_id: card_id, point: this.props.match.params.point, last4: last4, brand: brand }
    }) 
  }

  handleNextCreditCardAdd = () => {
		this.props.history.push({
			pathname: '/creditCardPayment',
			state: { point: this.props.match.params.point }
    })
  }
  render() {
    return (
      <div>
        {this.state.list_card.map((card) => {
            return(
              <CreditCard onClick={() => this.handleSelectCardPayment(card.id, card.last4, card.brand.toUpperCase())}>
                <CardTitle>{card.brand.toUpperCase()}****{card.last4}</CardTitle>
                <CardInfo>KITASINTI YOSHIO<br/>
                有効期限は{card.exp_month}/{card.exp_year}です</CardInfo>
              </CreditCard>
            )
          })
        }
        <CreditCardAdd onClick={this.handleNextCreditCardAdd}>お支払い方法を追加<span>></span></CreditCardAdd>
      </div>
    )
  }
};

export default withRouter(ListCardPay);