import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import BottomAppBarManReservation from '../organisms/bottomAppBarManReservation';
import WomanSlider from '../../presentational/molecules/womanSlider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import mediaquery from '../../../../assets/styles/variable';
import { getManReservated } from '../../../../infra/api';

const WomanDetailsBody = styled.div`
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  `}
`

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
  overflow-x: hidden;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 20px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `
const HeadBackground = styled.div`
  display: flex;
  margin-bottom: 20px;
`

class ManReservationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, reservation: {}, loading: true};
  }

  componentWillMount() {
    const _this = this
    getManReservated(this.props.location.state.id).then(async function (response) {
      await _this.setState({ user: response.data.man, reservation: response.data.reservation, loading: false})        
    }).catch(function (response){
      alert(response.message)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/man');
  };

  render() {
    if(this.state.loading){
      return(<div>Loading... </div>)
    }else{
      return (
        <div>
          <WomanHeader />
          <WomanDetailsBody>
            <TopBackground>
              <HeadBackground>
                <BackButtonCover>
                  <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
                </BackButtonCover>
                {/* <Title>女の子詳細</Title> */}
              </HeadBackground>
              <WomanSlider images={this.state.user.sorted_images}/>
            </TopBackground>
            <BottomAppBarManReservation reservation={this.state.reservation} user={this.state.user}/>
          </WomanDetailsBody>
        </div>
      )
    }
  }
}

export default ManReservationHistory;