import React, { Component } from 'react'
import { withRouter } from 'react-router';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
import { currentUser } from '../../../../infra/current_user';

const WomenOrganismsCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  ${mediaquery.desktop`
    max-width: 1280px;
    justify-content: unset;
  `}
`

const WomenIndivisual = styled.figure`
  width: 38%;
  height: 224px;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  margin: 7px;

  > img {
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    height: 186.67px;
    width: 100%;
    ${mediaquery.desktop`
      height: 320px;
    `}
  }

  > figcaption {
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #252525;
    margin-top: 15px;
    font-size: 15px;
    line-height: 22px;
    ${mediaquery.desktop`
      font-size: 20px;
      line-height: 30px;
    `}
  }

  ${mediaquery.desktop`
    width: 21.7%;
    height: 376px;
  `}
`

class StoreOrganisms extends Component {
  constructor(props) {
    super(props);
    this.handleImageClick = this.handleImageClick.bind(this)
  }

  handleImageClick(id){
    // 以下、「/woman/[props.woman.name]というように値を渡さなければいけない。
    if(this.props.type === 'store'){
      this.props.history.push({
        pathname: '/storeDetails',
        state: { id: id }
      }) 
    } else {
      if(currentUser.sex === 'man'){
        this.props.history.push({
          pathname: '/womanDetails',
          state: { id: id }
        })
      } else {
        this.props.history.push({
          pathname: '/manDetails',
          state: { id: id }
        })
      }
    }  
  }

  render() {
    return (
      <div className="App">
       {/* organisms（女性のfigure集） */}
        <WomenOrganismsCover>
          {this.props.userList.map((user) => {                    
            return (<WomenIndivisual onClick={() => this.handleImageClick(user.id)}>
              <img src={user.main_image} alt={user.nickname} />
              <figcaption>{user.nickname || user.name }</figcaption>
            </WomenIndivisual>) 
          })}
        </WomenOrganismsCover>
      </div>
    )
  }
};

export default withRouter(StoreOrganisms);