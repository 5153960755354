import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import firebase from '../../../../infra/my_firebase';
import { putUpdateProfileSetting } from '../../../../infra/api';
import { withRouter } from 'react-router-dom';
import { SimpleAlertSuccess } from '../.././presentational/atoms/simpleAlerts'

const RegistrationBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  margin: 30px 0;
`

const RegistrationFormGroup = styled.dl`
  > dt {
    font-size: 28px;
    font-weight: bold;
    margin: 12px 0;
  }

  > dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`
const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
`

const RegistrationForm = styled.form`
  width: 100%;
`

const RegistrationFormTable = styled.table`
  display: block;

  > td {
    display: block;

    &:not(:first-of-type) {
      margin-top: 26px;
    }
  }
`

const RegisrationFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`

const NextRegistrationButtonCover = styled.div`
  margin: 18px 0;
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const convertPhoneNumberDisplay = (phone_number) =>{
  if(`${phone_number[0]}${phone_number[1]}${phone_number[2]}` === '+81'){
    phone_number = phone_number.split(' ').join('').toString().replace(/^\+\d\d/, '0');
  }
  return phone_number;
}

class NextRegistration extends Component {

  constructor(props) {
    super(props);
    this.state = { code: '', showalert: false};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  keyPress = event => {
    if(event.key === 'Enter'){
      this.handleSubmit(event) 
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    const { code } = this.state;
    const {verificationId, email, phoneNumber} = this.props
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    firebase.auth().currentUser.updatePhoneNumber(phoneCredential).then(() => {
      putUpdateProfileSetting({email: email, tel: phoneNumber}).then(async (response) =>{
        const { token, user } = response.data
        if (token) await localStorage.setItem('token', token);
        if (user) await localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        this.props.handleShowAlert()
        this.props.handleReSetVerificationId()
      })
    })
    .catch((error) => {
      alert(error);
    });
  }

  render() {
    return (
      <div className="App">
        <RegistrationBackground>
            <Title>北新地モバイル</Title>
            {/* loginForm.js */}
            <RegistrationFormGroup>
            <Text>SNS認証</Text>
            <dd>
                <dl>
                  <Text style={{lineHeight: '24px', marginBottom: '19px'}}>電話番号宛に届いた６桁の数字を入力<br/>
                    {/* 下記propsは、前のページで登録した「電話番号」を表示させる。（どこに値を持たせる？？） */}
                    {convertPhoneNumberDisplay(this.props.phoneNumber)}に認証コードを送信しました。</Text>
                    <dd>
                        <RegistrationForm>
                          <RegistrationFormTable>
                            <td><RegisrationFormInput type="text" name="認証コード" placeholder="認証コード" onKeyPress={this.keyPress} onChange={ (e) => this.setState({ code: e.target.value }) }></RegisrationFormInput></td>
                          </RegistrationFormTable>
                        </RegistrationForm>
                    </dd>
                </dl>
            </dd>
            </RegistrationFormGroup>
            {/* nextRegistration.js */}
            <NextRegistrationButtonCover>
              <ButtonStyle onClick={this.handleSubmit}> 認証する </ButtonStyle>
            </NextRegistrationButtonCover>
        </RegistrationBackground>
        <SimpleAlertSuccess message={"出勤スケジュールを正常に登録しました！"} showalert={this.state.showalert}/>
      </div>
    )
  }
};

export default withRouter(NextRegistration);
