import React, { Component } from 'react';
import SearchInputMaterial from '../atoms/searchInputMaterial';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

const SearchFormCover = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: wrap-reverse;

  ${mediaquery.desktop`
    width: 50%;
    flex-flow: unset;
  `}
`

const SearchFormButtonCover = styled.div`
  text-align: right;
  display: flex;
  margin: 16px;
  margin-left: auto;
  margin-right: 0;
  padding-right: 14px;

  ${mediaquery.desktop`
    display: flex;
    margin: 0 auto;
    width: 100%;
  `}
`

const IconFilter = styled.svg`
  ${mediaquery.desktop`
    margin: auto;
    margin-right: 8px;
  `}
`

const Filter = styled.p`
  cursor: pointer;
  display: flex;
  margin: auto;

  &:nth-child(2) {
    margin-left: 24px;
  }

`

const SearchFormButton = styled.p`
  fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif'
  padding: 0;
  font-size: 16px;
  color: #ff444f;
  background-color: #f8f7f3;
  margin: auto;
`

class SearchForm extends Component {

  render() {
    return (
      <SearchFormCover>
        <SearchFormButtonCover>
          <Filter onClick = {this.props.setShowGroupSearch}>
            <IconFilter width="22" height="20" viewBox="0 0 22 20" fill="none">
              <path d="M13.4615 7.21818L21 1H1L8.53846 7.21818V19L13.4615 16.9545V7.21818Z" stroke="#FF444F" stroke-width="2" stroke-linejoin="round"/>
            </IconFilter>
            <SearchFormButton>条件を絞る</SearchFormButton>
          </Filter>
        </SearchFormButtonCover>
        <SearchInputMaterial groupFieldSearch = {this.props.groupFieldSearch}/>
      </SearchFormCover>
    )
  }
};

export default SearchForm;