import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import NextReserveButton from '../../presentational/atoms/nextReserveButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
  background-color: #e5e5e5;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  width: 288px;
  // 下記marginは、reset.cssでリセットする。
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const NextReserveButtonCover = styled.div`
  margin: 18px 0;
`

class ReservationContents extends Component {
  handleBackButtonClick = () => {
    this.props.history.goBack('/reservationDone');
  };

  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <BackButtonCover>
            <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
          </BackButtonCover>
          <Title>予約内容確認</Title>
          <ul>
              <li>React</li>
              <li>Rails</li>
              <li>AWS</li>
          </ul>
          <NextReserveButtonCover>
            <NextReserveButton text="予約する" />
          </NextReserveButtonCover>
        </TopBackground>
      </div>
    )
  }
}

export default ReservationContents;