import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1280px',
    margin: 'auto',
    marginTop: '35px',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function BasicPagination(props) {
  const classes = useStyles();

  const handleChangePage = (e, page) => {
    props.paginateFC({'page': page})
    props.setCurrentPage(page)
  }

  return (
    <div className={classes.root}>
      <Pagination page={props.currentPage} onChange={handleChangePage} count={props.total_pages} color="secondary" />
    </div>
  );
}
