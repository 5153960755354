import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from "react-router";

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mediaquery from '../../../../assets/styles/variable';
import { putCancelReservaions, putUpdateConfirmReservaions } from '../../../../infra/api';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import UserContentDetail from './userContentDetail';

const NextReservationLinkCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  > span {
    height: 23px;
  }
  ${mediaquery.desktop`
    height: 56px;
    border-radius: 30px;
    background-color: #ff444f;
    margin: 5px;
    font-size: 18px;
  `}
`

const NextMessageLinkCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  > span {
    height: 23px;
  }
  ${mediaquery.desktop`
    height: 56px;
    border-radius: 30px;
    margin: 5px;
    background: #FFFFFF;
    border: 2px solid #FF444F;
    box-sizing: border-box;
  `}
`

const NextReservationLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  ${mediaquery.desktop`
    width: 50%;
  `}
`

const NextMessageLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  ${mediaquery.desktop`
    width: 50%;
  `}
`

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif'
  },
  paper: {
    padding: '5px 15px 90px',
    '@media (min-width: 451px)': {
      padding: '5px 15px 50px'
    }
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    border: '1px solid #ff444f',
    boxSizing: 'border-box',
    width: '100%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0',
    '@media (min-width: 451px)': {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  root: {
    minHeight: 0,
    height: '56px',
    width: '100%',
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    backgroundColor: "#ff444f",
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
    height: '50px',
    margin: 'auto 0',
      border: `1px solid #ff878e`,
    },
  },
  bottomAppBarIcon: {
    '& svg': {
      margin: 0,
    },
    '@media (min-width: 451px)': {
      display: 'flex',
      flexFlow: 'row'
    }
  },
}));


const ReservationFee = styled.div`
  display: flex;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  color: #252525;
  > p {
    font-size: 36px;
  }
  > span {
    margin-left: 10px;
    font-style: normal;
    font-size: 20px;
    line-height: 55px;
  }
`
const MenuBTGroup = styled.div`
  display: none;
  margin-top: 40px;
  ${mediaquery.desktop`
    display: flex;
  `}
`
const Clear = styled.div`
  border-top: 1px solid #D7D7D7;
  height: 1px;
  width: 100%;
  margin: 10px;
`
const PTitle = styled.p`
  margin: 4px 0;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PTitleReservation = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #252525;
  margin: 20px 0 44px 0;
`
const BtnCancelReservation = styled.button`
  width: 303px;
  height: 44px;
  left: 32px;
  top: 1119px;
  background: #FF444F;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  border: none;
  display: block;
  margin: auto;
  margin-top: 60px;
  width: 90%;
`
const BtnConfirmReservation = styled.button`
  width: 21%;
  height: 29px;
  background: #FF444F;
  border-radius: 4px;
  border: none;
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin-left: 5px;
`

const PValue = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
  margin-top: 12px;
`
const ConfirmReservation = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 50px;
  > button {
    height: 40px;
  }
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    font-size: 19px;
    margin: 0 5px;
    margin-bottom: 8px;
  }
  > div{
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;
    }
    > input {
      height: 40px;
    }
  }
`

const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  z-index: 10000;
`

const PopUp = styled.div`
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 236px;
  padding: 20px 22px;
  background:#fff;
  border-radius: 8px;
  box-sizing:border-box;
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FF444F;
    margin-bottom: -29px;
    margin-top: 63px;
  }
`

const PopUpButtonCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const PopUpButtonYes = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  background-color: #ff444f;
  border: none;
  border-radius: 4px;
`

const PopUpButtonNo = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  background-color: #bbb;
  border: none;
  border-radius: 4px;
`

function add_one_minute(date){
  const date_temp = new Date(date)
  date_temp.setMinutes( date_temp.getMinutes() + 1 )
  return date_temp
}

export default function BottomAppBarWomanReservation(props) {
  const classes = useStyles()
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [confirm_start_section_man, setConfirm_start_section_man] = React.useState('')
  const [confirm_end_section_man, setConfirm_end_section_man] = React.useState('')
  const now = new Date()
  const start_section = new Date(props.reservation.start_section)
  const end_section = add_one_minute(new Date(props.reservation.end_section))
  const time = `${start_section.getFullYear()}年 ${start_section.getMonth() + 1}月${start_section.getDate()}日 ${start_section.getHours()}:${start_section.getMinutes() === 0 ? '00' : start_section.getMinutes()} - ${end_section.getHours()}:${end_section.getMinutes() === 0 ? '00' : end_section.getMinutes()}`

  const handleCancelReservation = () => {
    putCancelReservaions({reservation_id: props.reservation.id}).then(() =>{
      setIsOpen2(true)
    })
  }

  const handleUpdateConfirm = () => {
    putUpdateConfirmReservaions({confirm_start_section_man: confirm_start_section_man, confirm_end_section_man: confirm_end_section_man}, props.reservation.id)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          {props.user.nickname}
        </Typography>
        <ReservationFee>
          <p>{props.user.reservation_fee}</p>
          <span>P</span>
        </ReservationFee>
        <PTitleReservation>予約内容</PTitleReservation>
        <Clear/>
        <PTitle>日時</PTitle>
        <PValue>{time}</PValue>
        <Clear/>
        <PTitle>合計ポイント</PTitle>
        <PValue>{props.reservation.point} ポイント</PValue>
        <Clear/>
        {now < start_section && (<BtnCancelReservation onClick={() => {setIsOpen(true)}}>予約をキャンセルする</BtnCancelReservation>)}
        {now > start_section && (<ConfirmReservation>
          <div>
            <p>合流した時間</p>
            <input type="time" name="confirm_start_section" onChange={ (e) => {
              setConfirm_start_section_man(new Date(start_section.toDateString() + "  " + e.target.value))
            }}></input>
          </div>
          <p>〜</p>
          <div>
            <p>解散した時間</p>
            <input type="time" name="confirm_end_section" onChange={ (e) => {
              setConfirm_end_section_man(new Date(start_section.toDateString() + "  " + e.target.value))
            }}></input>
          </div>
          <BtnConfirmReservation onClick={handleUpdateConfirm}>確定</BtnConfirmReservation>
        </ConfirmReservation>)}
        <MenuBTGroup>
          <NextReservationLinkCover className={classes.bottomAppBarIcon}>
            <p><LocalBarOutlinedIcon /></p>
            <NextReservationLink to={{
              pathname: "/womanDetails/reservationDrink",
              state: {
                id: props.user.id
              }
            }}>女の子と飲む</NextReservationLink>
          </NextReservationLinkCover>
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <p><EmailOutlinedIcon /></p>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </MenuBTGroup>
        <UserContentDetail show_edit_profile={false} user={props.user}/>
      </Paper>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <NextReservationLinkCover className={classes.bottomAppBarIcon}>
            <span><LocalBarOutlinedIcon /></span>
            <NextReservationLink to={{
              pathname: "/womanDetails/reservationDrink",
              state: {
                id: props.user.id
              }
            }}>女の子と飲む</NextReservationLink>
          </NextReservationLinkCover>
          <Divider orientation="vertical" flexItem />
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <span><EmailOutlinedIcon /></span>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </Toolbar>
      </AppBar>
      {isOpen && (
        <PopUpBackground>
          <PopUp>
            <p>本当にキャンセルしてもよろしいでしょうか？</p>
            <PopUpButtonCover>
              <PopUpButtonYes onClick={handleCancelReservation}>はい</PopUpButtonYes>
              <PopUpButtonNo onClick={() => {setIsOpen(false)}}>いいえ</PopUpButtonNo>
            </PopUpButtonCover>
          </PopUp>
        </PopUpBackground>
      )}
      {isOpen2 && (
        <PopUpBackground>
          <PopUp>
            <p>ご予約をキャンセル致しました。</p>
            <PopUpButtonCover>
              <PopUpButtonNo onClick={() => {
                history.push({
                  pathname:  "/reservationHistory",
               })
              }}>戻る</PopUpButtonNo>
            </PopUpButtonCover>
          </PopUp>
        </PopUpBackground>
      )}
    </React.Fragment>
  );
}
