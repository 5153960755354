const getDayReservation = () => {
  const date = (new Date()).getDate()
  const month = (new Date()).getMonth()
  const year = (new Date()).getFullYear() 
  let all_days = [];
  var day = new Date(year, month, date);

  for (let i = 0; i < 7; i++) {
    all_days.push(new Date(day));
    day.setDate(day.getDate() + 1);
  }

  return all_days;
}

export default getDayReservation;