import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import NextPayButton from '../../presentational/atoms/nextPayButton';

const TopBackground = styled.div`
  height: 460px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 288px;
  // 下記marginは、reset.cssでリセットする。
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const NextPayButtonCover = styled.div`
  margin: 18px 0;
`

class ReservationEat extends Component {
  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <Title>時間を決める</Title>
          <NextPayButtonCover>
            <NextPayButton text="次へ" />
          </NextPayButtonCover>
        </TopBackground>
      </div>
    )
  }
}

export default ReservationEat;