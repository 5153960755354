import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import mediaquery from '../../../../assets/styles/variable';
import { putUpdateConfirmReservaions } from '../../../../infra/api';
import { currentUser } from '../../../../infra/current_user';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import UserContentDetail from './userContentDetail';

const NextMessageLinkCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;

  > span {
    height: 23px;
  }

  ${mediaquery.desktop`
    height: 56px;
    border-radius: 30px;
    margin: 5px;
    background: #FFFFFF;
    border: 2px solid #FF444F;
    box-sizing: border-box;
  `}
`

const NextMessageLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ff444f;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;

  ${mediaquery.desktop`
    width: 50%;
  `}
`

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif'
  },
  paper: {
    padding: '3px 15px 90px',
    '@media (min-width: 451px)': {
      padding: '5px 15px 50px'
    }
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    border: '1px solid #ff444f',
    boxSizing: 'border-box',
    width: '100%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0',
    '@media (min-width: 451px)': {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  root: {
    minHeight: 0,
    height: '56px',
    width: '100%',
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    backgroundColor: "#ff444f",
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
    height: '50px',
    margin: 'auto 0',
      border: `1px solid #ff878e`,
    },
  },
  bottomAppBarIcon: {
    '& svg': {
      margin: 0,
    },
    '@media (min-width: 451px)': {
      display: 'flex',
      flexFlow: 'row'
    }
  },
}));


const ReservationFee = styled.div`
  display: flex;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  color: #252525;
  > p {
    font-size: 36px;
  }
  > span {
    margin-left: 10px;
    font-style: normal;
    font-size: 20px;
    line-height: 55px;
  }
`
const MenuBTGroup = styled.div`
  display: none;
  margin-top: 40px;
  ${mediaquery.desktop`
    display: flex;
  `}
`
const Clear = styled.div`
  border-top: 1px solid #D7D7D7;
  height: 1px;
  width: 100%;
  margin: 10px;
`
const PTitle = styled.p`
  margin: 4px 0;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PTitleReservation = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #252525;
  margin: 20px 0 44px 0;
`
const BtnConfirmReservation = styled.button`
  width: 21%;
  height: 29px;
  background: #FF444F;
  border-radius: 4px;
  border: none;
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin-left: 5px;
`

const PValue = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
  margin-top: 12px;
`
const ConfirmReservation = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 50px;
  > button {
    height: 40px;
  }
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #000000;
    font-size: 19px;
    margin: 0 5px;
    margin-bottom: 8px;
  }
  > div{
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;
    }
    > input {
      height: 40px;
    }
  }
`

function add_one_minute(date){
  const date_temp = new Date(date)
  date_temp.setMinutes( date_temp.getMinutes() + 1 )
  return date_temp
}

function time_i(time){
  time = time.toString().length === 1 ? ("0" + time.toString()) : time
  return time
}

export default function BottomAppBarManReservation(props) {
  const classes = useStyles()
  const input_start_default = props.reservation.confirm_start_section_woman === null ? "" : new Date(props.reservation.confirm_start_section_woman)
  const input_end_default = props.reservation.confirm_end_section_woman === null ? "" : new Date(props.reservation.confirm_end_section_woman)
  const [confirm_start_section_woman, setconfirm_start_section_woman] = React.useState(input_start_default)
  const [confirm_end_section_woman, setconfirm_end_section_woman] = React.useState(input_end_default)
  const now = new Date()
  const start_section = new Date(props.reservation.start_section)
  const end_section = add_one_minute(new Date(props.reservation.end_section))
  const time = `${start_section.getFullYear()}年 ${start_section.getMonth() + 1}月${start_section.getDate()}日 ${start_section.getHours()}:${start_section.getMinutes() === 0 ? '00' : start_section.getMinutes()} - ${end_section.getHours()}:${end_section.getMinutes() === 0 ? '00' : end_section.getMinutes()}`

  const handleUpdateConfirm = () => {
    putUpdateConfirmReservaions({confirm_start_section_woman: confirm_start_section_woman, confirm_end_section_woman: confirm_end_section_woman}, props.reservation.id)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          {props.user.nickname}
        </Typography>
        <ReservationFee>
          <p>{props.user.reservation_fee}</p>
          <span>P</span>
        </ReservationFee>
        <PTitleReservation>予約内容</PTitleReservation>
        <Clear/>
        <PTitle>日時</PTitle>
        <PValue>{time}</PValue>
        <Clear/>
        <PTitle>合計ポイント</PTitle>
        <PValue>{props.reservation.point} ポイント</PValue>
        <Clear/>
        {currentUser.sex === "man" && now > start_section && (<ConfirmReservation>
          <div>
            <p>合流した時間</p>
            <input type="time" value={confirm_start_section_woman === "" ? "" : `${time_i(confirm_start_section_woman.getHours())}:${time_i(confirm_start_section_woman.getMinutes())}`} name="confirm_start_section" onChange={ (e) => {
              setconfirm_start_section_woman(new Date(start_section.toDateString() + "  " + e.target.value))
            }}></input>
          </div>
          <p>〜</p>
          <div>
            <p>解散した時間</p>
            <input type="time" value={confirm_end_section_woman === "" ? "" : `${time_i(confirm_end_section_woman.getHours())}:${time_i(confirm_end_section_woman.getMinutes())}`} name="confirm_end_section" onChange={ (e) => {
              setconfirm_end_section_woman(new Date(start_section.toDateString() + "  " + e.target.value))
            }}></input>
          </div>
          <BtnConfirmReservation onClick={handleUpdateConfirm}>確定</BtnConfirmReservation>
        </ConfirmReservation>)}
        <MenuBTGroup>
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <p><EmailOutlinedIcon /></p>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </MenuBTGroup>
        <UserContentDetail show_edit_profile={false} user={props.user}/>
      </Paper>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <Divider orientation="vertical" flexItem />
          <NextMessageLinkCover className={classes.bottomAppBarIcon}>
            <span><EmailOutlinedIcon /></span>
            <NextMessageLink to={{
              pathname: "/messageIndivisual",
              state: {
                user: props.user
              }
            }}>メッセージ</NextMessageLink>
          </NextMessageLinkCover>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}