import React, { Component } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LoginText = styled(Link)`
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 30px;
  text-decoration: underline;
`;

class DefaultLoginButton extends Component {
  render() {
    return (
      <div>
        <LoginText to="/login">電話番号でログイン</LoginText><br/>
        <LoginText to="/mailRegistration">メールでログイン</LoginText>
      </div>
    )
  }
}

export default DefaultLoginButton