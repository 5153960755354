import React, { Component } from 'react';
import styled from 'styled-components';

const LoginBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 460px;
  text-align: center;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  margin-bottom: 50px;
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const TxtStyle = styled.p`
  margin-bottom: 20px;
  width: 288px;
  font-size: 18px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const TxtAdressStyle = styled.a`
  margin-bottom: 20px;
  width: 288px;
  color: #ff444f;
  font-size: 18px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const LoginButtonCover = styled.div`
  margin: 18px 0;
`

class mailRegistrationDone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail_adress: ''
    };
  }

  componentDidMount() {
    const _this = this
    if(this.props.location.state != null){
      _this.setState({ mail_adress: this.props.location.state.mail_adress})
    }
  }

  render() {
    return (
    <div className="App">
      <LoginBackground>
      {/* loginButton.js <AlertError errors={this.state.errors_login}></AlertError>*/}
        <Title>北新地モバイル</Title>
        <LoginButtonCover>
          <TxtStyle>メールアドレスをご確認ください。</TxtStyle>
          <TxtStyle><TxtAdressStyle>{this.state.mail_adress}</TxtAdressStyle>に認証メールを送信しました。</TxtStyle>
        </LoginButtonCover>
      </LoginBackground>
    </div>
    )
  }
};

export default mailRegistrationDone;
