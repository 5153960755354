import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getFindNotification } from '../../../../infra/api';
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
height: 500px;
padding: 30px 15px;
background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   // 下記marginは、reset.cssでリセットする。
//   margin-top: 30px
//   transform: translate;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
//   text-align:center;
// `

const NoticInfo = styled.dl`
  > dd {
    max-width: 100%;
    height: 500px;
    margin-bottom: 1px;
    margin-top: 5px;
    line-height: 1.5;
    margin: 10px;
    background-color: #fff;
  }
`

const NotiTitle = styled.p`
  color: #252525;
  font-size: 18px;
  padding: 20px;
  display: block;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 25px;
  }
`

const NotiInfo = styled.p`
  color: #252525;
  font-size: 15px;
  margin-left: 20px;
  margin-right: 20px;
  display: block;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 25px;
  }
`
const ReservationInfo = styled.p`
  color: #FF444F;
  margin: 24px 0;
  display: block;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: underline;

  &:last-of-type {
    margin-bottom: 25px;
  }
`
class NotificationIndivisual extends Component {
  constructor(props) {
    super(props);
    this.state = { notification: [], reservation: []}
  }

  async componentWillMount() {
    const _this = this
    await getFindNotification(this.props.location.state.id, {id: this.props.location.state.id}).then(async function (response) {
      await _this.setState({ notification: response.data })        
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/notification');
  };

  handleImageClick = () => {
    if(currentUser.sex === 'woman'){
      this.props.history.push({
        pathname: '/manReservationHistory',
        state: { id: this.props.location.state.reservation_id }
        })
    }else if(currentUser.sex === 'man'){
      this.props.history.push({
        pathname: '/womanReservationHistory',
        state: { id: this.props.location.state.reservation_id }
        })
    }
  };

  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <BackButtonCover>
            <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
          </BackButtonCover>
          {/* <Title>お知らせ</Title> */}
          <NoticInfo>
              <dt></dt>
              <br/>
              <dd>
                <NotiTitle>{this.state.notification.title}</NotiTitle>
                <br/>
                <NotiInfo>{this.state.notification.content}</NotiInfo>
                {(() => {
                if (this.props.location.state.reservation_id != null) {
                  return <ReservationInfo onClick={this.handleImageClick}>予約の詳細はこちら</ReservationInfo>
                } 
              })()}
              </dd>
              <br/>
          </NoticInfo>
        </TopBackground>
      </div>
    )
  }
}

export default NotificationIndivisual;
