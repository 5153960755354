import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { postPayment } from '../../../../infra/api';

const NextPayButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  margin: auto;
  cursor: pointer;
`

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  width: 100%;
  // 下記marginは、reset.cssでリセットする。
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  text-align: center
`

const NextPayButtonCover = styled.div`
  margin: 60px 0 18px 0;
`

const Clear = styled.div`
  border-top: 1px solid #D7D7D7;
  height: 1px;
  margin: 10px;
`
const PTitle = styled.p`
  margin: 16px 0 6px 0;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PValue = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PContent= styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PointForm = styled.div`
  max-width: 400px;
  margin: auto;
`
const getYen = (point) => {
  switch(point) {
    case "5200":
      return "550,000 円(税込み)"
    case "1100":
      return "110,000 円(税込み)"
    case "500":
      return "55,000 円(税込み)"
    case "100":
      return "11,000 円(税込み)"
    default:
      // code block
  }
}

class PointBuyContents extends Component {
  constructor(props) {
    super(props);
    const {card_id, point, last4, brand, card_token} = props.location.state
    this.state = {
      card_id: card_id,
      point: point,
      last4: last4,
      brand: brand,
      card_token: card_token,
      yen: getYen(point)
    };
    this.paymentSubmit = this.paymentSubmit.bind(this)
  }

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  paymentSubmit = () => {
    const {point, card_id, card_token} = this.state
    postPayment({point: point, card_id: card_id, card_token: card_token}).then((response) => {
      this.props.history.push({
        pathname: '/pointBuyDone',
        state: { point: response.data.point }
      })
    }).catch((response) => {
      console.log(response.data)
    })
  }

  render() {
    const {point, last4, brand, yen} = this.state
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <BackButtonCover>
            <ArrowBackIosIcon onClick={this.handleBackButtonClick}/>
          </BackButtonCover>
          <Title>ポイント購入確認</Title>
          <PointForm>
            <PTitle>ポイント</PTitle>
            <PContent>{point}ポイント</PContent>
            <Clear/>
            <PTitle>金額</PTitle>
            <PContent>{yen}</PContent>
            <Clear/>
            <PTitle>お支払いするクレジットカード</PTitle>
            <PValue>{brand}****{last4}</PValue>
            <Clear/>
            <NextPayButtonCover>
              <NextPayButton onClick={this.paymentSubmit}>購入する</NextPayButton>
            </NextPayButtonCover>
          </PointForm>
        </TopBackground>
      </div>
    )
  }
}

export default PointBuyContents;