import React, { Component } from 'react';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  height: 300px;
  margin: 0 auto;
  padding: 40px 0;
  color: #fff;
  background: #ff444f;
  box-sizing: border-box;

  ${mediaquery.desktop`
    max-width: 1280px;
  `}
`

const FooterTitle = styled.h3`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
`

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  text-align:center;
  height: 100px;
  margin-top: 30px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: bold;
  font-size: 14px;

  ${mediaquery.desktop`
    width: 548px;
    height: 24px;
  `}

  > li {
    margin-bottom: 12px;
    margin-right: 21px;
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.04em;
  }
`

// const SnsIconCover = styled.ul`
//   display: flex;
//   justify-content: space-between;
//   width: 160px;
//   margin: 30px auto 40px;
// `

const CopyrightCover = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
`

class Footer extends Component {
  render() {
    return (
      <Background>
          <FooterTitle>モバイルクラブ</FooterTitle>
          <FooterMenu>
              <li><a href="#about">ABOUT</a></li>
              <li><a href="#current">CURRENT</a></li>
              <li><a href="#mission">MISSION</a></li>
              <li><a href="#promotion">PROMOTION</a></li>
              <li><a href="#promotion">お問い合わせ</a></li>
          </FooterMenu>

          {/* <SnsIconCover>
              <li>
                <a href="https://twitter.com/mobileclub_corp" target="_blank" rel="noopener noreferrer">
                  <img src={TwitterIcon} alt=""></img>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/mobileclub.corp" target="_blank" rel="noopener noreferrer">
                  <img src={FacebookIcon} alt=""></img>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/mobileclub_corp" target="_blank" rel="noopener noreferrer">
                  <img src={InstagramIcon} alt=""></img>
                </a>
              </li>
          </SnsIconCover> */}

          <CopyrightCover>© Copyright 2023. All rights reserved.</CopyrightCover>
      </Background>
    )
  }
}

export default Footer;