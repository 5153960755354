import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getFindNotifications, getNotiOpen } from '../../../../infra/api';
import { currentUser } from '../../../../infra/current_user';

const TopBackground = styled.div`
  height: 500px;
  padding: 30px 15px;
  background-color: #f8f7f3;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   text-align:center;
//   font-size: 30px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const NotificationList = styled.dl`
  > dd {
    max-width: 100%;
    height: 80px;
    margin-bottom: 1px;
    background-color: #fff;
  }
`

const NotiInfo = styled.p`
  color: #252525;
  font-size: 15px;
  line-height: 28px;
  margin-left: 20px;
  margin-right: 20px;
  display: block;
  cursor: pointer;
  font-weight: bold;

  &:last-of-type {
    margin-bottom: 25px;
  }
`

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = { notifications: []}
  }

  async componentWillMount() {
    const _this = this
    await getNotiOpen().then(async function (response) {
      console.log(response.data)
    })
    await getFindNotifications({useable_id: currentUser.id, useable_type: currentUser.sex}).then(async function (response) {
      await _this.setState({ notifications: response.data })        
    })
  };

  handleBackButtonClick = () => {
    this.props.history.goBack('/setting');
  };

  handleNotificationIndivisualClick = (notic) => {
    this.props.history.push({
      pathname: '/notificationIndivisual',
      state: { id: notic.id, reservation_id: notic.reservation_id }

    })
  };
  
  render() {
    return (
      <div>
        <WomanHeader />
        <TopBackground>
          <BackButtonCover>
            <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
          </BackButtonCover>
          {/* <Title>お知らせ</Title> */}
          <br/>
          <NotificationList>
            <dt></dt>
            {this.state.notifications.map((notic) => {
              return (<dd onClick={() => this.handleNotificationIndivisualClick(notic)}>
                <br/>
                <NotiInfo>{notic.title}</NotiInfo>
                <br/>
              </dd>) 
            })}
          </NotificationList>
        </TopBackground>
      </div>
    )
  }
}

export default Notification;