import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import BottomAppBarStore from '../organisms/bottomAppBarStore';
import WomanSlider from '../../presentational/molecules/womanSlider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import mediaquery from '../../../../assets/styles/variable';
import { getStoreDetail } from '../../../../infra/api';
import WomenOrganisms from '../organisms/womenOrganisms'

const StoreDetailsBody = styled.div`
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  `}
`

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 20px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const HeadBackground = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const TitleList = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: #252525;
  margin-bottom: 60px;
`

const WML = styled.div`
  padding: 16px;
`

const B0CenterMyPage = styled.div`
  display: unset;
  margin-top: 22px;
  ${mediaquery.desktop`
    display: flex;
  `}
`

const B1CenterMyPage = styled.div`
  display: flex;
  margin-right: 5%;
  height: 18px;
  padding: 13px 0 13px 13px;
  justify-content: space-between;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  border-bottom: 2px solid #BBBBBB;
  ${mediaquery.desktop`
    width: 50%;
  `}

  & > p:nth-child(1) {
    line-height: 25px;
  }

  & > p:nth-child(2) {
    font-weight: bold;
    font-size: 26px;
    color: #252525;
  }
`

const B2CenterMyPage = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #252525;
  text-align: center;
  margin-top: 10px;
`

const B3CenterMyPage = styled.div`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FF444F;
  width: 175px;
  height: 31px;
  background: #FFEAEB;
  border-radius: 8px;
  text-align: center;
  line-height: 31px;
  margin: auto;
  margin-bottom: 15px;
`

function CenterMyPage(props){
  let body = (
    <div>
      <B2CenterMyPage>{props.user.store_name}</B2CenterMyPage>
  <B3CenterMyPage>お店ID  A000{props.user.id}</B3CenterMyPage>
      <B0CenterMyPage>
        <B1CenterMyPage>
          <p>今月の売り上げ</p>
          <p>{props.user.income_in_year}P</p>
        </B1CenterMyPage>
        <B1CenterMyPage>
          <p>利益</p>
          <p>P</p>
        </B1CenterMyPage>
      </B0CenterMyPage>
    </div>
  )

  return body
}

class StoreDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { store: {}, women: [], loading: true };
  }

  componentWillMount() {
    const _this = this
    getStoreDetail(this.props.location.state.id).then(async function (response) {
      await _this.setState({ women: response.data.women, store: response.data.store, loading: false })        
    }).catch(function (response){
      alert(response.message)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/users');
  };

  render() {
    if(this.state.loading){
      return(<div>Loading... </div>)
    }else{
      return (
        <div>
          <WomanHeader />
          <StoreDetailsBody>
            <TopBackground>
              <HeadBackground>
                <BackButtonCover>
                  <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
                </BackButtonCover>
                {/* <Title>女の子詳細</Title> */}
              </HeadBackground>
              <WomanSlider images={this.state.store.sorted_images}/>
              <CenterMyPage user={this.state.store}/>
              <BottomAppBarStore user={this.state.store}/>
            </TopBackground>
            <WML>
              <TitleList>従業員一覧</TitleList>
              <WomenOrganisms userList = {this.state.women}/>
            </WML>
          </StoreDetailsBody>
        </div>
      )
    }
  }
}

export default StoreDetails;