import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';
 
const WomanSlider = (props) =>  {
  const settings = {
    accessibility: false,
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    dotsClass: "button_bar_page_detail",
    centerPadding: "100px",
    responsive: [
      {
        breakpoint: 450,
        settings: {
          centerPadding: "40px",
        }
      },
    ]
  };
  
  const ImageSlide = styled.img`
    display: block;
    width: 93%;
    height: 346px;
    margin: auto;
    color: #fff;
    background: #d3d3d3;
    border-radius: 8px;
  ${mediaquery.desktop`
    height: 480px;
  `}
`

  return (
    <Slider {...settings}>
      {props.images.map((image) =>{
        return (<div>
          <ImageSlide src={image.url} alt={image.order}/>
        </div>)
      })} 
    </Slider>
  );
}
 
  export default WomanSlider;