import React, { Component } from 'react';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getAttendances, getWomen, getCurrentUser, postReservaions } from '../../../../infra/api';
import getDayReservation from '../../presentational/atoms/getDayReservation';
import ReservationTimeList from '../.././presentational/molecules/reservationTimeList'

const NextPayButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  margin: auto;
  cursor: pointer;
`

const TopBackground = styled.div`
  padding: 30px 15px;
  background-color: #f8f7f3;
  max-width: 605px;
  margin: auto;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  width: 100%;
  // 下記marginは、reset.cssでリセットする。
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  text-align: center
`

const ReservationTable = styled.table`
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  scroll-x: auto;

  > thead {
    > tr {
      display: flex;

      > th {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 47px;
        height: 40px;
        font-size: 15px;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        &:nth-child(1) {
          position: absolute;
          z-index: 10;
          background: #f8f7f3;
        }
        &:nth-child(2){
          margin-left: 47px;
        }
        &:not(:first-of-type) {
          width: 80px;
          height: 40px;
        }
      }
    }
  }

  > tbody {
    > tr {
      display: flex;

      > td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 47px;
        height: 40px;
        font-size: 13px;
        background: #FFFFFF;
        border: 1px solid #d7d7d7;
        box-sizing: border-box;
        &:nth-child(2){
          margin-left: 47px;
        }
        &:not(:first-of-type) {
          width: 80px;
          height: 40px;
        }
      }
    }
  }
`

const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
`

const PopUp = styled.div`
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 236px;
  padding: 20px 22px;
  background:#fff;
  border-radius: 8px;
  box-sizing:border-box;
`

const PopUpButtonCover = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62%;
`

const PopUpTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FF444F;
`

const PopUpButton = styled.button`
  background-color: #ff444f;
  border: none;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  padding: 4px 12px;
  margin: auto;
  margin-top: 16px;
  width: 100%;
`

const NextPayButtonCover = styled.div`
  margin: 60px 0 18px 0;
`

const WithGirl = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  color: #252525;
  margin: 6px 0 26px 0;
  > span {
    color: #FF444F;
  }
`

const TableParent = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 25px;
`
const Clear = styled.div`
  border-top: 1px solid #D7D7D7;
  height: 1px;
  width: 100%;
  margin: 10px;
`
const PTitle = styled.p`
  margin: 4px 0;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
`
const PValue = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #252525;
  padding-left: 8px;
  margin-top: 12px;
`
const ImageWoman = styled.div`
  background-image: url(${props => props.url || ""});
  background-size: cover;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  margin: auto;
`

const reservationDayList = getDayReservation().map((day, index) => {
  return(
    <React.Fragment key={index}>
      <th>{`${day.getMonth() + 1}/${day.getDate()}(月)`}</th>
    </React.Fragment>
  )
});

class ReservationDrink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_attendances: [],
      loading: true,
      sections: [],
      woman: {},
      current_user: {},
      continue_pay: false,
      isOpen: false,
    };
    this.handleSetSections = this.handleSetSections.bind(this)
    this.handleNextPay = this.handleNextPay.bind(this)
    this.handleBackReservation =this.handleBackReservation.bind(this)
    this.createReservation = this.createReservation.bind(this)
    this.handleClosePopUp = this.handleClosePopUp.bind(this)
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this)
    this.toggleContainer = React.createRef();
  }

  handleSetSections = (sections) =>{
    this.setState({sections: sections})
  }

  createReservation = () => {
    const _this = this

    if(this.state.current_user.point < this.state.woman.reservation_fee){
      this.setState({isOpen: true})
      return;
    }
    const params_reservations = {point: this.state.woman.reservation_fee || 0, woman_id: this.props.location.state.id, param_reservation: this.state.sections} 

    postReservaions(params_reservations).then(function (response) {
      _this.props.history.push({
        pathname: '/reservationDone',
        state: { woman_id: _this.state.woman.id, reservation_code: response.data.code }
      })   
    }).catch(function (response){
      console.log(response.data)
    })
  }

  onClickOutsideHandler(event) {
    if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }
  
  handleClosePopUp = () => {
    this.setState({ isOpen: false });
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  async componentWillMount() {
    const _this = this

    await getWomen(this.props.location.state.id).then(async function (response) {
      await _this.setState({ woman: response.data })        
    }).catch(function (response){
      console.log(response.data)
    })

    await getCurrentUser().then(async function (response) {
      await _this.setState({ current_user: response.data, loading: false })        
    }).catch(function (response){
      console.log(response.data)
    })

    let time_response = []
    await getAttendances(this.props.location.state.id).then(async function (response) {
      time_response = await response.data.map(attendance => ({'reservations': attendance.reservations, 'id': attendance.id,'day': (new Date(attendance.start_section)).toDateString(),'start_section': attendance.start_section, 'end_section': attendance.end_section}))     
      _this.setState({ time_attendances: time_response, loading: false })
    }).catch(function (response){
      console.log(response.data)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/womanDetails');
  };

  handleNextPay = () => {
    if(this.state.sections.length === 0) return;
    this.setState({continue_pay: true})
  }

  handleBackReservation = () => {
    this.setState({continue_pay: false})
  }

  render() {
    if(this.state.loading){
      return (<div></div>)
    } else {
      if(!this.state.continue_pay){
        return (
          <div>
            <WomanHeader />
            <TopBackground>  
              <ImageWoman url={this.state.woman.main_image} />
              <WithGirl><span>{this.state.woman.nickname}</span>さんと飲む</WithGirl>
              <BackButtonCover>
                <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
              </BackButtonCover>
              <Title>時間を決める</Title>
                <TableParent>
                  <ReservationTable>
                    <thead>
                      <tr>
                        <th></th>
                        {reservationDayList}
                      </tr>
                    </thead>
                    <tbody>
                      <ReservationTimeList sections={this.state.sections} handleSetSections={this.handleSetSections} time_attendances={this.state.time_attendances}/>
                    </tbody>
                  </ReservationTable>
                </TableParent>
              <NextPayButtonCover>
                <NextPayButton onClick={this.handleNextPay}>次へ</NextPayButton>
              </NextPayButtonCover>
            </TopBackground>
          </div>
        )
      } else {
        const date = this.state.sections[0]
        const time = `${date.start_section.getFullYear()}年 ${date.start_section.getMonth() + 1}月${date.start_section.getDate()}日 ${date.start_section.getHours()}:${date.start_section.getMinutes() === 0 ? '00' : date.start_section.getMinutes()} - ${date.end_section.getHours()}:${date.end_section.getMinutes() === 0 ? '00' : date.end_section.getMinutes()}`
        return (
          <div ref={this.toggleContainer}>
            <WomanHeader />
            <TopBackground>
              <WithGirl><span>{this.state.woman.nickname}</span>さんと飲む</WithGirl>
              <BackButtonCover>
                <ArrowBackIosIcon onClick={this.handleBackReservation} />
              </BackButtonCover>
              <Title>予約内容確認</Title>
              <Clear/>
              <PTitle>日時</PTitle>
              <PValue>{time}</PValue>
              <Clear/>
              <PTitle>合計ポイント</PTitle>
              <PValue>{this.state.woman.reservation_fee} ポイント</PValue>
              <NextPayButtonCover>
                <NextPayButton onClick={this.createReservation}>予約する</NextPayButton>
              </NextPayButtonCover>
            </TopBackground>
            {this.state.isOpen && (
              <PopUpBackground>
                <PopUp>
                  <PopUpTitle>ポイント不足です。購入をお願い致します。</PopUpTitle>
                  <PopUpButtonCover>
                    <PopUpButton onClick={() => {this.props.history.push('/pointBuy')}}>ポイント購入画面へ</PopUpButton>
                    <PopUpButton onClick={this.handleClosePopUp}>戻る</PopUpButton>
                  </PopUpButtonCover>
                </PopUp>
              </PopUpBackground>
            )}
          </div>
        )
      }
    }
  }
}

export default ReservationDrink;