import axios from 'axios';
import { currentUser } from './current_user';
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
const token_auth = localStorage.getItem('token')
axios.defaults.headers.common['Authorization'] = token_auth;
let paramSex = currentUser.sex === 'store' ? 'stores' : (currentUser.sex === 'woman' ? 'women' : 'men')


export const signIn = (idToken) => axios({
	method: 'post',
	url: `/api/v1/users/sign_in`,
	headers: {
		idToken: idToken
	}
});

export const signUp = (sex, params) => axios.post(`/api/v1/${sex}`, params );
export const checkValidSignup = (sex, params) => axios.post(`/api/v1/${sex}_valid`, params );

export const signUpStore = (token, params) => axios.post(`/api/v1/stores/${token}`, params);

export const checkUserByPhone = (params) => axios({
  method: 'get',
  url: `/api/v1/users/check_user_by_phone`,
  params: params,
});

export const checkUserByEmail = (params) => axios({
  method: 'get',
  url: `/api/v1/users/check_user_by_email`,
  params: params,
});

// get list woman
export const getWomens = (params) => axios({
  method: 'get',
  url: `/api/v1/women`,
  params: params,
  
});
// create card
export const postSaveCard = (params) => axios({
  method: 'post',
  url: `/api/v1/men/${currentUser.id}/save_card`,
  params: params,
  
});
// get woman detail
export const getWomen = (params) => axios({
  method: 'get',
  url: `/api/v1/women/${params}`,
  
});

// get man detail
export const getMen = (params) => axios({
  method: 'get',
  url: `/api/v1/men/${params}`,
  
});

// get list card stripe
export const getListCard = (params) => axios({
  method: 'get',
  url: `/api/v1/men/${currentUser.id}/list_card`,
  params: params,
  
});

// get list man
export const getMens = (params) => axios({
  method: 'get',
  url: `/api/v1/men`,
  params: params,
  
});

// delete card stripe
export const deleteDeleteCard = (params) => axios({
  method: 'delete',
  url: `/api/v1/men/${currentUser.id}/delete_card`,
  params: params,
  
});

// get CurrentUser
export const getCurrentUser = () => axios({
  method: 'get',
  url: `/api/v1/${paramSex}/${currentUser.id}`,
  params: {'current_user': true},
  
});

// update CurrentUser(myPage)
export const putUpdateCurrentUser= (params) => axios({
  method: 'put',
  url: `/api/v1/${paramSex}/${currentUser.id}`,
  data: params,
  
});

// update CurrentUser woman(comment)
export const putUpdateCommentWoman= (params) => axios({
  method: 'put',
  url: `/api/v1/women/update_comment`,
  data: params,
});

// update CurrentUser woman(comment)
export const getCommentWoman= (params) => axios({
  method: 'get',
  url: `/api/v1/women/get_comment`,
  params: params,
});

// update CurrentUser woman(bank info)
export const putUpdateBankInfo= (params) => axios({
  method: 'put',
  url: `/api/v1/women/update_bank_info`,
  data: params,
});

// buyPoints
export const postPayment = (params) => axios({
  method: 'post',
  url: `/api/v1/men/${currentUser.id}/buyPoints`,
  params: params,
  
});

// get list Attendances
export const getAttendances = (id) => axios({
  method: 'get',
  url: `/api/v1/women/${id || currentUser.id}/attendances`,
  
});

// create Attendance
export const postAttendances = (params) => axios({
  method: 'post',
  url: `/api/v1/women/${currentUser.id}/attendances`,
  data: params,
  
});

// create Reservaion
export const postReservaions = (params) => axios({
  method: 'post',
  url: `/api/v1/men/${currentUser.id}/reservations`,
  data: params,
  
});

// cancel Reservaion
export const putCancelReservaions = (params) => axios({
  method: 'put',
  url: `/api/v1/reservations/cancel`,
  data: params,
  
});

// update confirm Reservaion
export const putUpdateConfirmReservaions = (params, id) => axios({
  method: 'put',
  url: `/api/v1/reservations/${id}/confirm`,
  data: params,
  
});

// get Women reservated for current man
export const getWomenReservated = (params) => axios({
  method: 'get',
  url: `/api/v1/reservations/women_reservated`,
  params: params,
  
});

// get Men reservated for current woman
export const getMenReservated = (params) => axios({
  method: 'get',
  url: `/api/v1/reservations/men_reservated`,
  params: params,
  
});

// get Men reservated for current store 
export const getMenReservatedForStore = (params) => axios({
  method: 'get',
  url: `/api/v1/reservations/men_reservated_for_store`,
  params: params,
  
});

// get detail Man reservated
export const getManReservated = (reservation_id) => axios({
  method: 'get',
  url: `/api/v1/reservations/man_reservated/${reservation_id}`,
  
});

// get detail Woman reservated
export const getWomanReservated= (reservation_id) => axios({
  method: 'get',
  url: `/api/v1/reservations/woman_reservated/${reservation_id}`,
  
});

// add user images
export const postAddImageSlider= (params) => axios({
  method: 'post',
  url: `/api/v1/${paramSex}/${currentUser.id}/add_user_image`,
  data: params,
  
});

// delete user image
export const deleteDeleteImageSlider= (params) => axios({
  method: 'delete',
  url: `/api/v1/${paramSex}/${currentUser.id}/delete_user_image`,
  data: params,
  
});

// create favorites
export const postFavorites= (params) => axios({
  method: 'post',
  url: `/api/v1/favorites`,
  data: params,
  
});

// get list favorites
export const getFavorites= (params) => axios({
  method: 'get',
  url: `/api/v1/favorites`,
  params: params,
  
});

// unfavorites
export const deleteFavorite= (params) => axios({
  method: 'delete',
  url: `/api/v1/favorites`,
  data: params,
  
});

// get list store
export const getStores= (params) => axios({
  method: 'get',
  url: `/api/v1/stores`,
  params: params
});

// get store detail
export const getStoreDetail= (params) => axios({
  method: 'get',
  url: `/api/v1/stores/${params}`

});

// get list woman for store
export const getWomenStore = (id, params) => axios({
  method: 'get',
  url: `/api/v1/stores/${id}/women`,
  params: params,
  
});

// check profile user before update in setting screen
export const getCheckValidUserSetting= (params) => axios({
  method: 'get',
  url: `/api/v1/check_valid_user_setting`,
  params: params

});

// check profile user before update in setting screen
export const getCheckValidByEmail= (params) => axios({
  method: 'get',
  url: `/api/v1/check_valid_by_email`,
  params: params

});

// check profile user before update in setting screen
export const getUserSettingByEmail= (params) => axios({
  method: 'get',
  url: `/api/v1/setting_by_email`,
  params: params

});

// check profile user before update in setting screen
export const getCheckEmailOrTel= (params) => axios({
  method: 'get',
  url: `/api/v1/check_email_or_tel`,
  params: params

});

// update profile user in setting screen
export const putUpdateProfileSetting = (params) => axios({
  method: 'put',
  url: `/api/v1/update_profile_setting`,
  data: params

});

// update message point reduction
export const postMessagePointReduction = () => axios({
  method: 'post',
  url: `/api/v1/men/message_point_reduction`,

});

export const getNotiOpen = (params) => axios({
  method: 'get',
  url: `/api/v1/${paramSex}/${currentUser.id}/notifications`,
  params: params,
});

export const getFindNotification = (id, params) => axios({
  method: 'get',
  url: `/api/v1/${paramSex}/${currentUser.id}/notifications/find_notification/${id}`,
  params: params,
});

export const getFindNotifications = (params) => axios({
  method: 'get',
  url: `/api/v1/${paramSex}/${currentUser.id}/notifications/find_notifications`,
  params: params,
});

export const getFindNotificationsCheck = (params) => axios({
  method: 'get',
  url: `/api/v1/${paramSex}/${currentUser.id}/notifications/notifications_check`,
  params: params,
});

export const setTemporarilies = (params) => axios({
  method: 'post',
  url: `/api/v1/temporarilies`,
  params: params,
});

export const getTemporarilies = (params) => axios({
  method: 'get',
  url: `/api/v1/temporarilies`,
  params: params,
});