import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import InputSlider from '../../../../assets/images/towardsMan/sp/inputslider.png';
import DeleteImageUser from '../../../../assets/images/towardsMan/sp/delete_image_user.png';
import mediaquery from '../../../../assets/styles/variable';
import { postAddImageSlider, deleteDeleteImageSlider } from '../../../../infra/api';
 
const MyPageSliderSetting = (props) =>  {

  const settings = {
    accessibility: false,
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    dotsClass: "button_bar_page_detail",
    centerPadding: "100px",
    responsive: [
      {
        breakpoint: 450,
        settings: {
          centerPadding: "40px",
        }
      },
    ]
  };
  
  const ImageSlide = styled.div`
    display: block;
    width: 93%;
    height: 346px;
    margin: auto;
    color: #fff;
    background: #d3d3d3;
    border-radius: 8px;
    background-size: cover;
    position: relative;
    ${mediaquery.desktop`
        height: 480px;
    `}
  `
  const InputSlideCss = styled.div`
    width: 90%!important;
    height: 346px;
    margin: auto;
    color: #fff;
    background: #d3d3d3;
    display: block!important;
    border-radius: 8px;
    background-size: contain;
    background-repeat-y: no-repeat;
    ${mediaquery.desktop`
        height: 480px;
    `}
  `
  const DeleteImageUserIcon = styled.div`
    background: #ffffff;
    display: block!important;
    border-radius: 10px;
    background-size: contain;
    background-repeat-y: no-repeat;
    position: absolute;
    width: 35px;
    height: 35px;
    right: 3px;
    top: 3px;
  `

  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  const handleFile = (e) => {
    toDataURL(URL.createObjectURL(e.target.files[0]), function(dataUrl) {
      postAddImageSlider({'image_b64': dataUrl}).then((response) => {
        props.handleChangeUser(response.data)
        props.handleShowAlert("写真をアップロードしました。")
      }).catch((error) => {
        alert(error)
      })
    })
  };

  const handleClickInput = () => {
    document.getElementById("data_input_image_slider").click()
  }

  const handleShowDelete = (image_id) => {
    const confirm_delete = window.confirm("この写真を本当に削除しますか？");
    if(confirm_delete){
      deleteDeleteImageSlider({'user_image_id': image_id}).then((response) => {
        props.handleChangeUser(response.data)
        props.handleShowAlert("削除に成功しました。")
      }).catch((error) => {
        alert(error)
      })
    }
  }
  return (
    <Slider {...settings}>
      {props.images.map((image) =>{
        return (<div>
          <ImageSlide style={{backgroundImage: `url(${image.url})`}} >
            <DeleteImageUserIcon onClick={() => handleShowDelete(image.id)} style={{backgroundImage: `url(${DeleteImageUser})`}}></DeleteImageUserIcon>
          </ImageSlide>
        </div>)
      })}
      <div>
        <InputSlideCss onClick={handleClickInput} style={{backgroundImage: `url(${InputSlider})`}}> </InputSlideCss>
        <input type="file"
          name="data_image"
          accept="image/x-png,image/gif,image/jpeg"
          onChange={handleFile} alt="input" id="data_input_image_slider" style={{display: 'none'}}/>
      </div>
    </Slider>
  );
}
 
  export default MyPageSliderSetting;