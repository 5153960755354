import React, { Component } from 'react';
import styled from 'styled-components';
import {myFirestore, myStorage} from '../../../../infra/my_firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { postMessagePointReduction } from '../../../../infra/api';
import WomanHeader from '../organisms/womanHeader';
import BottomMessageBar from '../organisms/bottomMessageBar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { currentUser } from '../../../../infra/current_user';

// 文字入力の上がり幅が1列につき18pxのため、50px（bottomBar） + 18px（1列の上がり幅） = 68ox。
var variableMessageListPaddingBottom = '68px';

const TopBackground = styled.div`
  // bottomBarに高さを合わせるため。
  position: relative;
  padding: 30px 15px ${variableMessageListPaddingBottom};
  background-color: #f8f7f3;
  overflow-x: hidden;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

const Title = styled.h1`
  width: 288px;
  // 下記marginは、reset.cssでリセットする。
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`
const LoadImage = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
`

const FixedTopHead = styled.div`
  position: sticky;
  top: 0;
  z-index: 5000;

`

const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
`

const PopUp = styled.div`
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 236px;
  padding: 20px 22px;
  background:#fff;
  border-radius: 8px;
  box-sizing:border-box;
`

const PopUpButtonCover = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62%;
`

const PopUpTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FF444F;
`

const PopUpButton = styled.button`
  background-color: #ff444f;
  border: none;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  padding: 4px 12px;
  margin: auto;
  margin-top: 16px;
  width: 100%;
`

const hashString = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
      hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
      hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

class MessageIndivisual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_message: [],
      message: '',
      user_partner: this.props.location.state.user,
      groupChatId: '',
      isLoading: true,
      loadImage: false,
      isOpen: false,
    };
    this.getGroupChatId = this.getGroupChatId.bind(this)
    this.getListHistory = this.getListHistory.bind(this)
    this.onSendMessage = this.onSendMessage.bind(this)
    this.onChoosePhoto = this.onChoosePhoto.bind(this)
    this.uploadPhoto = this.uploadPhoto.bind(this)
    this.loadImage = this.loadImage.bind(this)
    this.updateReadedLastMess = this.updateReadedLastMess.bind(this)
    this.handleClosePopUp = this.handleClosePopUp.bind(this)
    this.refListChatCurrent = myFirestore.collection("users").doc(currentUser.firebase_id).collection("list_chat").doc(this.state.user_partner.firebase_id)
    this.refListChatPartner = myFirestore.collection("users").doc(this.state.user_partner.firebase_id).collection("list_chat").doc(currentUser.firebase_id)
  }

  handleClosePopUp = () => {
    this.setState({ isOpen: false });
  }

  getGroupChatId = () => {
    if (hashString(this.state.user_partner.firebase_id) <= hashString(currentUser.firebase_id)){
      this.setState({groupChatId: `${this.state.user_partner.firebase_id}-${currentUser.firebase_id}`})
    } else {
      this.setState({groupChatId: `${currentUser.firebase_id}-${this.state.user_partner.firebase_id}`})
    }
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/message');
  };

  async componentWillMount() {
    await this.getGroupChatId()
  }

  componentDidMount() {
    this.getListHistory()
    this.updateReadedLastMess()
  }

  // 描画されてから処理を実行。
  componentDidUpdate() {
    window.scrollTo(0,document.body.scrollHeight);
  }

  updateReadedLastMess(){
    this.refListChatCurrent.get().then((thisDoc) => {
      if (thisDoc.exists) {
        this.refListChatCurrent.update({read: 1})
      }
    })
  }

  getListHistory = () => {
    if (this.removeListener) {
        this.removeListener()
    }
    this.setState({isLoading: true})
    // Get history and listen new data added
    this.removeListener = myFirestore
      .collection("groupchats")
      .doc(this.state.groupChatId)
      .collection('message')
      .onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              this.setState({list_message: [...this.state.list_message, change.doc.data()]})
            }
          })
          this.setState({isLoading: false})
        },
        err => {
          alert(err)
        }
      )
}


  // input無いのvalueに入力した値を反映させる
  handleMessageChange = (e) => {
    e.preventDefault();
    this.setState({ 
        message: e.target.value,
    });

    const bottomBarHeight = 50;
    const textAreaHeight = document.getElementById('bottomBarVariableHeight') === null ? 50 : document.getElementById('bottomBarVariableHeight').clientHeight;
    const variableMessageListPaddingBottom = textAreaHeight - bottomBarHeight;
    document.getElementById('messageListCover').style.paddingBottom = variableMessageListPaddingBottom + "px";
  };

  // textメッセージにmessageの値を挿入し、messageの値を空に上書きしinputを空にする
  onSendMessage = async (content, type) => {
    if (content.trim() === '') {
        return
    }
    const timestamp = new Date().getTime().toString()
    const itemMessage = {
        sender_id: currentUser.firebase_id,
        created_at: timestamp,
        content: content.trim(),
        type: type
    }

    if(currentUser.sex === "man"){
      postMessagePointReduction().then(() => {
        myFirestore
          .collection("groupchats")
          .doc(this.state.groupChatId)
          .collection("message")
          .doc(timestamp)
          .set(itemMessage)
          .then(async () => {
            this.setState({message: ''})
          })
          .catch(err => {
            alert(err)
          })
      }).catch((error) => {
        if(error.response.status === 403){
          this.setState({ isOpen: true });
        }
      })
    } else {
      myFirestore
        .collection("groupchats")
        .doc(this.state.groupChatId)
        .collection("message")
        .doc(timestamp)
        .set(itemMessage)
        .then(async () => {
          this.setState({message: ''})
        })
        .catch(err => {
          alert(err)
        })
    }


    document.getElementById('messageListCover').style.paddingBottom =  "0";

    const itemlistChat = {
      main_image: this.state.user_partner.main_image,
      updated_at: timestamp,
      tel: this.state.user_partner.tel,
      chat_id: this.state.groupChatId,
      nickname: this.state.user_partner.nickname,
      firebase_id: this.state.user_partner.firebase_id,
      last_message: itemMessage,
      read: 1
    }

    this.refListChatCurrent.get().then((thisDoc) => {
      postMessagePointReduction().then(() => {
        if (!thisDoc.exists) {
          this.refListChatCurrent.set(itemlistChat)
          .then(() => {
          })
          .catch(err => {
 
          })
        } else {
          this.refListChatCurrent.update(itemlistChat)
        }
      })
      .catch(err => {
      })
    })

    const itemlistChatPartner = {
      main_image: currentUser.main_image,
      updated_at: timestamp,
      tel: currentUser.tel,
      chat_id: this.state.groupChatId,
      nickname: currentUser.nickname,
      firebase_id: currentUser.firebase_id,
      last_message: itemMessage,
      read: 0
    }

    this.refListChatPartner.get().then((thisDoc) => {
        if (!thisDoc.exists) {
          this.refListChatPartner.set(itemlistChatPartner)
          .then(() => {
          })
          .catch(err => {

          })
        } else {
          this.refListChatPartner.update(itemlistChatPartner)
        }
    })
    this.setState({loadImage: false})
  }

  onChoosePhoto = event => {
    this.setState({loadImage: true})
    if (event.target.files && event.target.files[0]) {
      this.currentPhotoFile = event.target.files[0]
      // Check this file is an image?
      const prefixFiletype = event.target.files[0].type.toString()
      if (prefixFiletype.indexOf("image/") === 0) {
          this.uploadPhoto()
      } else {
          alert('This file is not an image')
      }
    }
  }

  uploadPhoto = () => {
    if (this.currentPhotoFile) {
      const timestamp = new Date().getTime().toString()
      const idStorage = `${currentUser.firebase_id}-${timestamp}` 

      const uploadTask = myStorage
        .ref()
        .child(idStorage)
        .put(this.currentPhotoFile)

      uploadTask.on("state_changed",
        null,
        err => {
          alert(err)
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              this.onSendMessage(downloadURL, 1)
          })
        }
      )
    } else {
        this.setState({isLoading: false})
        alert('File is null')
    }
  }

  loadImage = () => {
    if(this.state.loadImage)return (<LoadImage><CircularProgress color="secondary" /></LoadImage>)
  }

  render() {
    if(this.state.isLoading){
      return (<div>loading...</div>)
    } else {
      return (
        <div>
          <FixedTopHead> 
            <WomanHeader />
            <TopBackground>
              <BackButtonCover>
                  <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
                </BackButtonCover>
                <Title>{this.state.user_partner.nickname}</Title>
              </TopBackground>
          </FixedTopHead>
          <TopBackground>
            {this.loadImage()}
                    {/* 下記で、text,message等のstateをpropsとして子コンポーネントに渡さなければ、input内を空にしたりできない。 */}
            <BottomMessageBar user_parter={this.state.user_partner} onChoosePhoto={this.onChoosePhoto} list_message={this.state.list_message} message={this.state.message} messageChangeMethod={this.handleMessageChange} messageSendMethod={this.onSendMessage} />
          </TopBackground>
          {this.state.isOpen && (
              <PopUpBackground>
                <PopUp>
                  <PopUpTitle>ポイント不足です。購入をお願い致します。</PopUpTitle>
                  <PopUpButtonCover>
                    <PopUpButton onClick={() => {this.props.history.push('/pointBuy')}}>ポイント購入画面へ</PopUpButton>
                    <PopUpButton onClick={this.handleClosePopUp}>戻る</PopUpButton>
                  </PopUpButtonCover>
                </PopUp>
              </PopUpBackground>
            )}
        </div>
      )
    }
  }
}

export default MessageIndivisual;