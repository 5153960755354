import React, { Component } from 'react'
import { withRouter } from 'react-router';
import styled from 'styled-components';
import mediaquery from '../../../../assets/styles/variable';

const WomenOrganismsCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;

  ${mediaquery.desktop`
    max-width: 1280px;
    justify-content: unset;
  `}
`

const WomenIndivisual = styled.figure`
  width: 38%;
  height: 224px;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  margin: 7px;

  > img {
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    height: 156px;
    width: 90%;
    ${mediaquery.desktop`
      height: 290px;
    `}
  }

  > figcaption {
    font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #252525;
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;
    ${mediaquery.desktop`
      font-size: 20px;
      line-height: 30px;
    `}
    > div {
      font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.04em;

      color: #FF444F;

      > p {
        margin-top: 5px;
      }
    }
  }

  ${mediaquery.desktop`
    width: 21.7%;
    height: 376px;
  `}
`
function add_one_minute(date){
  const date_temp = new Date(date)
  date_temp.setMinutes( date_temp.getMinutes() + 1 )
  return date_temp
}

class MenHistoryOrganisms extends Component {
  constructor(props) {
    super(props);
    this.handleImageClick = this.handleImageClick.bind(this)
  }

  handleImageClick(user){
    this.props.history.push({
      pathname: '/manReservationHistory',
      state: { id: user.reservation_id }
    })
  }

  render() {
    return (
      <div className="App">
       {/* organisms（女性のfigure集） */}
        <WomenOrganismsCover>
          {this.props.userList.map((user) => {  
            const start_section = new Date(user.start_section)
            const end_section = add_one_minute(new Date(user.end_section))
            const time = `${start_section.getFullYear()}年 ${start_section.getMonth() + 1}月${start_section.getDate()}日`
            const minute = `${start_section.getHours()}:${start_section.getMinutes() === 0 ? '00' : start_section.getMinutes()} - ${end_section.getHours()}:${end_section.getMinutes() === 0 ? '00' : end_section.getMinutes()}`                
            return (<WomenIndivisual onClick={() => this.handleImageClick(user)}>
              <img src={user.main_image} alt={user.nickname} />
              <figcaption>
                {user.nickname} <br/>
                <div><p>{time}</p></div>
                <div>{minute}</div>
              </figcaption>
            </WomenIndivisual>) 
          })}
        </WomenOrganismsCover>
      </div>
    )
  }
};

export default withRouter(MenHistoryOrganisms);