import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import firebase from '../../../../infra/my_firebase';
import styled from 'styled-components';
import { checkUserByPhone } from '../../../../infra/api';
import NextRegistration from './nextRegistration'
import { Alert } from '@material-ui/lab';

const LoginBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  text-align: center;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  margin-bottom: 20px;
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const LoginFormGroup = styled.dl`
  > dt {
    font-size: 28px;
    font-weight: bold;
  }

  > dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`

const LoginForm = styled.form`
  width: 100%;
`

const LoginFormTable = styled.table`
  display: block;

  > td {
    display: block;

    &:last-of-type {
      margin-top: 26px;
    }
  }
`

const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  cursor: pointer;
`

const LoginFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`

const LoginButtonCover = styled.div`
  margin: 18px 0;
`

const OtherText = styled(Link)`
  color: #ff444f;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;

  &:last-of-type {
    margin-top: 12px;
  }
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

function AlertError(props) {
  let result = (<div></div>)
  if (props.errors !== '') {
    result = (<Alert severity="error"> {props.errors} </Alert>)
  }
  return result
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number_phone: '',
      confirmationResult: {},
      errors_login: ''
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.convertPhoneNumber = this.convertPhoneNumber.bind(this);
  }

  handleLogin(e) {
    e.preventDefault();
    const { number_phone } = this.state;
    try {
      this.signInPhoneNumber(number_phone)
    } catch (error) {
      alert(error);
    }
  }

  keyPress = event => {
    if(event.key === 'Enter'){
      this.handleLogin(event) 
    }
  };

  async signInPhoneNumber(number_phone){
    const _this = this
    await checkUserByPhone({'tel': _this.state.number_phone}).then(async function (response) {
      if (document.getElementById('recaptcha-container') !== null){
        window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible'
        });
      }
      firebase.auth().signInWithPhoneNumber(number_phone, window.recaptchaVerifier).then(function (confirmationResult) {
        _this.setState({ confirmationResult: confirmationResult })
      }).catch(function (error) {
        alert(error)
        window.location.reload();
      });
    }).catch(function (response) {
      _this.setState({ errors_login: response.response.data.error })
    })
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  convertPhoneNumber(phone_number) {
    phone_number = phone_number.split(' ').join('').toString().replace(/^0/, '+81');
    return phone_number;
  }

  render() {
    if(this.isEmpty(this.state.confirmationResult)){
      return (
        <div className="App">
          <LoginBackground>
              <AlertError errors={this.state.errors_login}></AlertError>
              <Title>北新地モバイル</Title>
              {/* loginForm.js */}
              <LoginFormGroup>
                  <Text>ログイン</Text>
                  <dd>
                      <LoginForm>
                          <LoginFormTable>
                              <td><LoginFormInput type="text" name="電話番号" placeholder="電話番号" onKeyPress={this.keyPress} onChange={ (e) => this.setState({ number_phone: this.convertPhoneNumber(e.target.value) }) }></LoginFormInput></td>
                              <div id="recaptcha-container"></div>
                          </LoginFormTable>
                      </LoginForm>
                  </dd>
              </LoginFormGroup>
              {/* loginButton.js */}
              <LoginButtonCover>
                <ButtonStyle onClick={this.handleLogin}>ログイン</ButtonStyle>
              </LoginButtonCover>
              {/* 新規登録.js */}
              <OtherText to="/registration">新規会員登録はこちら</OtherText>
              <OtherText to="/mailRegistration">メールアドレスでのログインはこちら</OtherText>
          </LoginBackground>
        </div>
      )
    }else{
      return (
        <NextRegistration phoneNumber={this.state.number_phone} confirmationResult={this.state.confirmationResult} />
      )
    }
  }
};

export default Login;
