import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import WomanHeader from '../organisms/womanHeader';
import BottomAppBarWoman from '../organisms/bottomAppBarWoman';
import WomanSlider from '../../presentational/molecules/womanSlider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import mediaquery from '../../../../assets/styles/variable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { withStyles } from '@material-ui/core/styles';
import { postFavorites, deleteFavorite, getWomen } from '../../../../infra/api';
import { currentUser } from '../../../../infra/current_user';

const WomanDetailsBody = styled.div`
  ${mediaquery.desktop`
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  `}
`
const StyledFormControlLabel= withStyles({
  label: {
    fontFamily: 'Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: '0.04em',
    color: '#FF444F'
  },
})(FormControlLabel);

const TopBackground = styled.div`
  min-height: 460px;
  padding: 30px 15px;
`

const BackButtonCover = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
`

// const Title = styled.h1`
//   width: 288px;
//   // 下記marginは、reset.cssでリセットする。
//   margin: 0;
//   font-size: 20px;
//   font-weight: bold;
//   font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
// `

const FavoriteBT = styled.div`
  margin-left: auto;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  width: 58%;
  justify-content: flex-end;
  color: red;

  & > p {
    margin-left: 3px;
  }
`
const HeadBackground = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const LikePointDisplayCover = styled.div`
  margin-bottom: 36px;
`
const AttendanceButtonDisplay = styled.dl`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 14px 10px;
  background: #FFEAEB;
  border-radius: 6px;
  box-sizing: border-box;
  color: #FF444F;
  justify-content: center;

  > svg {
    margin: 0 10px 2px 0;
  }
`

class WomanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {}, loading: true , isFavorite: false, reservations: {} };
    this.handleFavorites = this.handleFavorites.bind(this)
    this.displayReservation = this.displayReservation.bind(this)
  }

  componentWillMount() {
    const _this = this
    getWomen(this.props.location.state.id).then(async function (response) {
      const woman = response.data
      await _this.setState({ user: woman, loading: false, isFavorite: woman.isFavorite, reservations: response.data.reservations })        
    }).catch(function (response){
      alert(response.message)
    })
  }

  handleBackButtonClick = () => {
    this.props.history.goBack('/woman');
  };

  handleFavorites = (e) => {
    if(e.target.checked === true){
      postFavorites({reciever_id: this.state.user.id}).then(() => {
        this.setState({isFavorite: true})
      })
    } else {
      deleteFavorite({reciever_id: this.state.user.id}).then(() => {
        this.setState({isFavorite: false})
      })
    }
  };

  favoriteDisplay = () =>{
    let body = (<FavoriteBT>
      <StyledFormControlLabel
        control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />}
        label="お気に入り"
        onClick={this.handleFavorites}
        checked={this.state.isFavorite ? true : false}
      />
    </FavoriteBT>)
    if(currentUser.sex === "store"){
      body = ""
    }

    return body
  }

  displayReservation = () => {
    let head = ""
    if(currentUser.sex === "store"){
      head = (
        <LikePointDisplayCover>
          <Link to={{
            pathname: "/womanDetails/reservationDrinkForStore",
            state: {
              id: this.state.user.id
            }
          }}>
            <AttendanceButtonDisplay>
              <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1667 3H2.83333C1.82081 3 1 3.78736 1 4.75862V18.2414C1 19.2126 1.82081 20 2.83333 20H21.1667C22.1792 20 23 19.2126 23 18.2414V4.75862C23 3.78736 22.1792 3 21.1667 3Z" stroke="#FF444F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <line x1="1" y1="9" x2="23" y2="9" stroke="#FF444F" stroke-width="2"/>
                <line x1="17" y1="5" x2="17" y2="1" stroke="#FF444F" stroke-width="2" stroke-linecap="round"/>
                <line x1="7" y1="5" x2="7" y2="1" stroke="#FF444F" stroke-width="2" stroke-linecap="round"/>
              </svg>
              {"シフト"}
            </AttendanceButtonDisplay>
          </Link>
        </LikePointDisplayCover>
      )
    }
    return head
  }

  render() {
    if(this.state.loading){
      return(<div>Loading... </div>)
    }else{
      return (
        <div>
          <WomanHeader />
          <WomanDetailsBody>
            <TopBackground>
              <HeadBackground>
                <BackButtonCover>
                  <ArrowBackIosIcon onClick={this.handleBackButtonClick} />
                </BackButtonCover>
                {/* <Title>女の子詳細</Title> */}
                {this.favoriteDisplay()}
              </HeadBackground>
              {this.displayReservation()}
              <WomanSlider images={this.state.user.sorted_images}/>
            </TopBackground>
            <BottomAppBarWoman user={this.state.user} reservations={this.state.reservations}/>
          </WomanDetailsBody>
        </div>
      )
    }
  }
}

export default WomanDetails;