import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { checkValidSignup, getStores} from '../../../../infra/api';
import firebase from '../../../../infra/my_firebase';
import NextRegistration from './nextRegistration'
import Checkbox from '@material-ui/core/Checkbox';

const RegistrationBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f7f3;
`

const Title = styled.h1`
  width: 288px;
  color: #ff444f;
  font-size: 36px;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  margin: 30px 0;
`

const RegistrationFormGroup = styled.dl`
  > dt {
    font-size: 28px;
    font-weight: bold;
    margin: 12px 0;
  }

  > dd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }
`
const GroupCheckBoxBody = styled.div`
  display: flex;
  > p {
    line-height: 42px;
  }
`
const GroupCheckBox = styled.div`
  display: flex;
  justify-content: space-around;
  display: flex;
  margin-top: 25px;
`

const RegistrationForm = styled.form`
  width: 100%;
`

const RegistrationFormTable = styled.table`
  display: block;

  > td {
    display: block;

    &:not(:first-of-type) {
      margin-top: 26px;
    }
  }
  
  > p {
    text-align: left;
    margin-top: 33px;
    margin-bottom: -16px;
  }
`

const RegisrationFormInput = styled.input`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;

  &::placeholder {
    color: #bbb;
  }
`

const RegisrationFormSelect = styled.select`
  width: 345px;
  height: 48px;
  padding: 12px 18px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  background: #fff;
  &::placeholder {
    color: #bbb;
  }
`

const RegistrationFormGroupNote = styled.p`
  color: #ff444f;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  max-width: 345px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 12px;
  text-align: left;
`

const RegisrationSexCheckList = styled.ul`
  display: flex;
  // 下記padding:0;を無くしたい。（ページの大枠にpaddingをつける。）
  padding: 0;
  list-style: none;
  margin: 15px 0;

  > li:last-of-type {
    margin-left: 13px;
  }
`

const RegisrationSexCheckListInput = styled.input`
  display: none;
  opacity: 0;

  + label {
    display: inline-block;
    width: 166px;
    height: 44px;
    padding: 10px 20px;
    color: #ff444f;
    font-size: 17px;
    font-weight: bold;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ff444f;
    border-radius: 8px;
  }

  &:checked + label {
    color: #fff;
    background-color: #ff444f;
    box-sizing: border-box;
    border: 1px solid #ff444f;
  }
`

const NextRegistrationButtonCover = styled.div`
  margin: 18px 0;
`

const OtherText = styled(Link)`
  color: #ff444f;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  
  &:last-of-type {
    margin-top: 12px;
  }
`
const ButtonStyle = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  background-color: #ff444f;
  cursor: pointer;
`

const ErrorValid = styled.p`
  margin: auto;
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
  text-align: left;
  width: 345px;
  color: red;
  font-size: 10px;
`

const Text = styled.dt`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const Label = styled.label`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const Bank = styled.p`
  font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
`

const SelectLive = () =>{
  const prefectures =  ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"]
  return (
    <React.Fragment>
      <option value="" disabled selected>お住まい</option>
      {prefectures.map((p) =>
        <option value={p}>{p}</option>
      )}
    </React.Fragment>
  )
}

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberPhoneInput: '',
      nameInput: '',
      nicknameInput: '',
      companyInput: '',
      positionInput: '',
      liveInput: '',
      storeIdInput: '',
      card_companyInPut: '',
      card_typeInPut: 'ordinary',
      card_numberInPut: '',
      card_nameInPut: '',
      mailInput: '',
      card_branchInPut: '',
      sex: 'men',
      tel: '',
      name: '',
      nickname: '',
      company: '',
      position: '',
      live: '',
      mail: '',
      branch: '',
      confirmationResult: {},
      user: {},
      stores: [],
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.convertPhoneNumber = this.convertPhoneNumber.bind(this);
    this.handleChangeCardType = this.handleChangeCardType.bind(this)
  }

  componentDidMount() {
    const _this = this
    getStores().then(response => {
      _this.setState({stores: response.data.stores})
    })
  }

  handleSignup(e) {
    const _this = this
    e.preventDefault();
    const { numberPhoneInput, nameInput, nicknameInput, companyInput, positionInput, liveInput, sex, card_companyInPut, card_typeInPut, card_numberInPut, card_nameInPut, storeIdInput, mailInput, card_branchInPut } = this.state;
    let user = {}
    if(_this.state.sex === "men"){
      user = { 'tel': numberPhoneInput, 'name': nameInput, 'nickname': nicknameInput, 'company': companyInput, 'position': positionInput, 'live': liveInput, 'email': mailInput }
    } else {
      user = { 'tel': numberPhoneInput, 'name': nameInput, 'nickname': nicknameInput, 'live': liveInput, 'card_company': card_companyInPut, 'card_branch': card_branchInPut, 'card_type': card_typeInPut, 'card_number': card_numberInPut, 'card_name': card_nameInPut, 'store_id': storeIdInput, 'email': mailInput }
    }
    this.setState({user: user})
    checkValidSignup(sex, { user: user }).then(async function (response) {
      _this.handleLogin()
    }).catch(function (error) {
      let { tel, name, nickname, company, position, live, mail } = error.response.data.error
      tel = tel === undefined ? '' : tel.join(',')
      name = name === undefined ? '' : name.join(',')
      nickname = nickname === undefined ? '' : nickname.join(',')
      company = company === undefined ? '' : company.join(',')
      position = position === undefined ? '' : position.join(',')
      live = live === undefined ? '' : live.join(',')
      mail = mail === undefined ? '' : mail.join(',')

      _this.setState({ tel: tel, name: name, nickname: nickname, company: company, position: position, live: live, mail: mail })
    });
  }

  handleLogin() {
    const { numberPhoneInput } = this.state;
    try {
      this.signInPhoneNumber(numberPhoneInput)
    } catch (error) {
      alert(error);
    }
  }

  async signInPhoneNumber(numberPhoneInput){
    const _this = this
    if (document.getElementById('recaptcha-container') !== null){
      window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible'
      });
    }
    firebase.auth().signInWithPhoneNumber(numberPhoneInput, window.recaptchaVerifier).then(function (confirmationResult) {
      _this.setState({ confirmationResult: confirmationResult })
    }).catch(function (error) {
      alert(error)
      window.location.reload();
    });
  }

  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  handleChangeCardType(type){
    this.setState({card_typeInPut: type})
  }

  convertPhoneNumber(phone_number) {
    phone_number = phone_number.split(' ').join('').toString().replace(/^0/, '+81');
    return phone_number;
  }

  render() {
    if(this.isEmpty(this.state.confirmationResult)){
      if(this.state.sex === 'men'){
        return (
          <div className="App">
            <RegistrationBackground>
                <Title>北新地モバイル</Title>
                {/* loginForm.js */}
                <RegistrationFormGroup>
                <Text>基本情報入力</Text>
                <dd>
                  <div>
                    <form>
                        <RegisrationSexCheckList>
                            <li><RegisrationSexCheckListInput type="radio" name="radio1" id="man" defaultChecked="checked" onClick={ (e) => this.setState({ sex: 'men', tel: '', name: '', nickname: '', live: '' }) }/><Label htmlFor="man">男性</Label></li>
                            <li><RegisrationSexCheckListInput type="radio" name="radio1" id="woman" onClick={ (e) => this.setState({ sex: 'women', tel: '', name: '', nickname: '', live: '' }) }/><label htmlFor="woman">女性</label></li>
                        </RegisrationSexCheckList>
                    </form>
                  </div>
                  <RegistrationForm>
                      <RegistrationFormTable>
                        <td><RegisrationFormInput name="nickname" type="text" placeholder="ニックネーム" onChange={ (e) => this.setState({ nicknameInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.nickname}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="name" type="text" placeholder="お名前" onChange={ (e) => this.setState({ nameInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.name}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="company" type="text" placeholder="会社名" onChange={ (e) => this.setState({ companyInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.company}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="position" type="text" placeholder="役職" onChange={ (e) => this.setState({ positionInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.position}</ErrorValid>
                        </td>
                        <td>
                          <RegisrationFormSelect onChange={ (e) => this.setState({ liveInput: e.target.value }) }>
                            <SelectLive/>
                          </RegisrationFormSelect>
                          <ErrorValid>{this.state.live}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="tel" type="text" placeholder="電話番号" onChange={ (e) => this.setState({ numberPhoneInput: this.convertPhoneNumber(e.target.value) }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.tel}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="mail" type="text" placeholder="メールアドレス" value={this.state.mailInput} onChange={ (e) => this.setState({ mailInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.mail}</ErrorValid>
                        </td>
                      </RegistrationFormTable>
                      <div id="recaptcha-container"></div>
                  </RegistrationForm>
                  <div><RegistrationFormGroupNote>※お名前、会社名、役職は運営側にしか表示されませんので、ご安心下さい。</RegistrationFormGroupNote></div>
                </dd>
                </RegistrationFormGroup>
                {/* 男性、女性チェックリストコンポーネント.js */}
                {/* nextRegistration.js */}
                <NextRegistrationButtonCover>
                  <ButtonStyle onClick={this.handleSignup} > 次へ </ButtonStyle>
                </NextRegistrationButtonCover>
                {/* login.js */}
                <NextRegistrationButtonCover>
                  <OtherText to="/login">アカウントをお持ちの方はこちらからログイン</OtherText>
                </NextRegistrationButtonCover>
            </RegistrationBackground>
          </div>
        )
      } else {
        return (
          <div className="App">
            <RegistrationBackground>
                <Title>北新地モバイル</Title>
                {/* loginForm.js */}
                <RegistrationFormGroup>
                <Text>基本情報入力</Text>
                <dd>
                  <div>
                    <form>
                        <RegisrationSexCheckList>
                            <li><RegisrationSexCheckListInput type="radio" name="radio1" id="man" defaultChecked="checked" onClick={ (e) => this.setState({ sex: 'men' }) }/><label htmlFor="man">男性</label></li>
                            <li><RegisrationSexCheckListInput type="radio" name="radio1" id="woman" onClick={ (e) => this.setState({ sex: 'women' }) }/><label htmlFor="woman">女性</label></li>
                        </RegisrationSexCheckList>
                    </form>
                  </div>
                  <RegistrationForm>
                      <RegistrationFormTable>
                        <td><RegisrationFormInput name="nickname" type="text" placeholder="ニックネーム" onChange={ (e) => this.setState({ nicknameInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.nickname}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="name" type="text" placeholder="お名前" onChange={ (e) => this.setState({ nameInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.name}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="tel" type="text" placeholder="電話番号" onChange={ (e) => this.setState({ numberPhoneInput: this.convertPhoneNumber(e.target.value) }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.tel}</ErrorValid>
                        </td>
                        <td><RegisrationFormInput name="mail" type="text" placeholder="メールアドレス" value={this.state.mailInput} onChange={ (e) => this.setState({ mailInput: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.mail}</ErrorValid>
                        </td>
                        <td>
                          <RegisrationFormSelect onChange={ (e) => this.setState({ liveInput: e.target.value }) }>
                            <SelectLive/>
                          </RegisrationFormSelect>
                          <ErrorValid>{this.state.live}</ErrorValid>
                        </td>
                        <td>
                          <RegisrationFormSelect onChange={ (e) => this.setState({ storeIdInput: e.target.value }) }>
                            {(
                              <React.Fragment>
                                <option value="" disabled selected>店舗</option>
                                {this.state.stores.map((s) =>
                                  <option value={s.id}>{s.store_name}</option>
                                )}
                              </React.Fragment>
                            )}
                          </RegisrationFormSelect>
                        </td>
                        <Bank>口座番号入力</Bank>
                        <td><RegisrationFormInput name="card_company" type="text" placeholder="金融機関名" onChange={ (e) => this.setState({ card_companyInPut: e.target.value }) }></RegisrationFormInput>
                        </td>
                        <td><RegisrationFormInput name="card_branch" type="text" placeholder="支店名" onChange={ (e) => this.setState({ card_branchInPut: e.target.value }) }></RegisrationFormInput>
                          <ErrorValid>{this.state.branch}</ErrorValid>
                        </td>
                        <GroupCheckBox>
                          <GroupCheckBoxBody>
                            <Checkbox
                              checked={this.state.card_typeInPut === "ordinary" ? true : false}
                              onChange={ () => this.handleChangeCardType("ordinary")}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Bank>普通</Bank>
                          </GroupCheckBoxBody>
                          <GroupCheckBoxBody>
                            <Checkbox
                              checked={this.state.card_typeInPut === "current" ? true : false}
                              onChange={ () => this.handleChangeCardType("current")}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Bank>当座</Bank>
                          </GroupCheckBoxBody>
                        </GroupCheckBox>
                        <td><RegisrationFormInput name="card_number" type="text" placeholder="口座番号" onChange={ (e) => this.setState({ card_numberInPut: e.target.value }) }></RegisrationFormInput>
                        </td>
                        <td><RegisrationFormInput name="card_name" type="text" placeholder="口座名義人（カナ）" onChange={ (e) => this.setState({ card_nameInPut: e.target.value }) }></RegisrationFormInput>
                        </td>
                      </RegistrationFormTable>
                      <div id="recaptcha-container"></div>
                  </RegistrationForm>
                  <div><RegistrationFormGroupNote>※お名前、ジョブ、範囲は運営側にしか表示されませんので、ご安心下さい。</RegistrationFormGroupNote></div>
                </dd>
                </RegistrationFormGroup>
                {/* 男性、女性チェックリストコンポーネント.js */}
                {/* nextRegistration.js */}
                <NextRegistrationButtonCover>
                  <ButtonStyle onClick={this.handleSignup} > 次へ </ButtonStyle>
                </NextRegistrationButtonCover>
                {/* login.js */}
                <NextRegistrationButtonCover>
                  <OtherText to="/login">アカウントをお持ちの方はこちらからログイン</OtherText>
                </NextRegistrationButtonCover>
            </RegistrationBackground>
          </div>
        )
      }
    }else {
      return (
        <NextRegistration sex={this.state.sex} user={this.state.user} phoneNumber={this.state.numberPhoneInput} confirmationResult={this.state.confirmationResult}/>
      )
    }
  }
};
